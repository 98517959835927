import * as React from "react";
import { Design } from "../../../application/data/design/design";
import { withStyles, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { MenuButton } from "./menu-button";
import { App } from "../../../application/App";
import { spacing } from "../../theme/config";
import { observer } from "mobx-react";
import { route } from "../../../config/routes";

interface OwnProps extends WithStyles<"root" | "button" | "buttonLabel"> {
  design: Design;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    position: "fixed",
    left: theme.spacing(4),
    transition: "left 0.3s",
    top: theme.spacing(4),
    bottom: 0,
    width: theme.spacing(12),
    background: "#fff",
    padding: theme.spacing(1)
  },
  button: {},
  buttonLabel: {
    color: "red"
  }
});

export const MenuDesignPages = withStyles(styles)(
  observer(({ classes, design }: OwnProps) => {
    const activeTool = App.business.interface.activeToolConfig;

    return (
      <div className={classes.root} style={{ left: spacing(4) + activeTool.width }}>
        <MenuButton disabled to={route("app.design.details", { design: design.id })} title='Details' icon='file-edit' />
        <MenuButton to={route("app.design.inputs", { design: design.id })} title='Inputs' icon='sliders-h' />
        <MenuButton to={route("app.design.results", { design: design.id })} title='Results' icon='clipboard' />
        <MenuButton
          disabled
          to={route("app.design.notifications", { design: design.id })}
          title='Notifications'
          icon='bell'
        />
        <MenuButton
          disabled
          to={route("app.design.warranties", { design: design.id })}
          title='Warrenties'
          icon='medal'
        />
        <MenuButton disabled to={route("app.design.quotations", { design: design.id })} title='Quotations' icon='tag' />
      </div>
    );
  })
);
