import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";
import { App } from "../../App";

export interface Notification extends GraphQLModel<NotificationData> {}

export interface NotificationData {
  id: string;
}

export class NotificationModel extends GraphQLBase<NotificationData> implements GraphQLModel<NotificationData> {
  public typename: string = "Notification";
  public get provider() {
    return App.business.notification.provider;
  }
}
