import { server } from "../application/network/__mocks__/api";

class APIClass {
  public async graphql({ query, variables }: any) {
    const result = await server.query(query, variables);

    return {
      data: result.data,
      subscribe: () => {}
    };
  }
}

export function graphqlOperation(query: string, variables: any) {
  return {
    query: query,
    variables: variables
  };
}

export const API = new APIClass();
