import { observable } from "mobx";

import { BaseModule } from "../../module";
import { Design, DesignData } from "./design";
import DefaultDesignProvider, { DesignProvider } from "./design-provider";
import { App } from "../../App";

export interface DesignInteractor {
  created: Design | undefined;
  selected: Design | undefined;
  select(id: string): Design | undefined;
  create: (data: Partial<DesignData>, persist?: boolean) => Design;
  store(design: Design): Promise<void>;
  find(id: string): Design | undefined;
  provider: DesignProvider;
  all: Design[];
  load(): Promise<void>;
}

export default class DefaultDesignInteractor extends BaseModule<any, any, any> implements DesignInteractor {
  private _loaded: boolean = false;

  @observable public created: Design | undefined = undefined;
  @observable public selected: Design | undefined = undefined;

  public all: Design[] = [];

  public load = async () => {
    if (!this._loaded) {
      console.log("load");
      this.all = [
        this.provider.find("aquaflex"),
        this.provider.find("aquaflex-hs"),
        this.provider.find("xiga"),
        this.provider.find("xline"),
        this.provider.find("airlift-skds"),
        this.provider.find("airlift-mgb"),
        // this.provider.find("anmbr"),
        this.provider.find("crossflow")
        // this.provider.find("crossflow-uf"),
      ];

      this._loaded = true;
      console.log("FETCH DESIGNS");
      this.all.forEach(async design => {
        design.fetch();
      });
    }
  };

  public get user() {
    return App.business.user.currentUser!;
  }

  public find = this.provider.find;
  public store = this.provider.store;
  public create = this.provider.create;

  public select(id: string): Design | undefined {
    return (this.selected = this.provider.find(id));
  }

  public get provider(): DesignProvider {
    return this.loadProvider("provider", DefaultDesignProvider);
  }
}
