import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { PagePresenter } from "./_base-page-presenter";
import { computed } from "mobx";

export class AuthPagePresenter extends PagePresenter {
  constructor(protected _userInteractor: UserInteractor, protected _router: RouteComponentProps) {
    super(_router);
  }

  @computed public get user() {
    return this._userInteractor.currentUser;
  }

  @computed public get isAdmin() {
    return this._userInteractor.isAdmin;
  }

  public mount = () => {};
  public unmount = () => {};
}
