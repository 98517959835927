import Auth from "@aws-amplify/auth";

const generateHeader = async () => {
  try {
    const token = ((await Auth.currentSession()) as any).idToken.jwtToken;
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};

export const amplify = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT,
    region: process.env.REACT_APP_AUTH_USER_POOL_REGION,
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  },

  Interactions: {
    bots: {
      [process.env.REACT_APP_LEX_BOT_NAME || "bot_name_unknown"]: {
        name: process.env.REACT_APP_LEX_BOT_NAME,
        alias: process.env.REACT_APP_LEX_BOT_ALIAS,
        region: process.env.REACT_APP_AUTH_USER_POOL_REGION
      }
    }
  },

  Storage: {
    bucket: process.env.REACT_APP_STORAGE_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
    region: process.env.REACT_APP_STORAGE_BUCKET_REGION //OPTIONAL -  Amazon service region
  },

  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE,

  graphql_headers: generateHeader
};
