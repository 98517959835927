import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { PentairBrightBlue } from "../../theme/colors/_all";
import { typographyVariants } from "../../theme/config";
import { makeStyles } from "@material-ui/core/styles";

interface OwnProps {
  title?: string;
  subtitle?: string;
  prefix?: string;
  classes?: Partial<Record<"root" | "prefix" | "title" | "subtitle", any>>;
}

const useStyles = (props: OwnProps) =>
  makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      alignItems: "baseline",
      flexWrap: "wrap",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(-2)
    },
    prefix: { marginRight: "0.2em" },
    title: { marginRight: "0.2em", fontWeight: props.prefix ? 400 : 700 },
    subtitle: { color: PentairBrightBlue[500] }
  }))(props);

export const PageTitles = (props: OwnProps) => {
  const { title, prefix, subtitle } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {prefix && (
        <Typography variant={typographyVariants.h1} className={classes.prefix} color={"primary"}>
          {prefix}
        </Typography>
      )}
      {title && (
        <Typography variant={typographyVariants.h1} className={classes.title} color={"primary"}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography align='left' variant={typographyVariants.h4} className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};
