import { observer } from "mobx-react";
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

import { Design } from "../../../application/data/design/design";
import { mapEvent } from "../../../helpers/formatters";
import { IconButton } from "../mui-extended/mui-icon-button";
import { typographyVariants } from "../../theme/config";

interface OwnProps {
  designs: Design[];
  onRowSelect(id: string): void;
  onEdit(id: string): void;
  onDelete(id: string): void;
  onCreate(): void;
  onDownload(id: string): void;
  onApprove(id: string): void;
}

export const TableDesigns = observer(function({ designs, onEdit, onDelete, onDownload, onApprove }: OwnProps) {
  return (
    <section>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            <TableCell padding={"checkbox"} children={""} />
            <TableCell children={"Technology"} />
            <TableCell children={"Account name"} />
            <TableCell children={"Opportunity #"} />
            <TableCell children={"Project name"} />
            <TableCell children={"Project destination"} />
            <TableCell children={"Designed by"} />
            <TableCell children={"Approved by"} />
            <TableCell children={"Version"} />
            <TableCell children={"Status"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {designs.map(design => {
            return (
              <TableRow key={design.id}>
                <TableCell
                  padding={"checkbox"}
                  children={<IconButton onClick={mapEvent(onEdit, design.id)} icon="pencil-alt" />}
                />
                <TableCell
                  padding={"checkbox"}
                  children={<IconButton disabled onClick={mapEvent(onEdit, design.id)} icon="trash-alt" />}
                />
                <TableCell
                  children={
                    <Typography variant={typographyVariants.h5}>
                      {design.technology && design.technology.name}
                    </Typography>
                  }
                />
                <TableCell>{design.valueMap.account_name && design.valueMap.account_name.values![0]}</TableCell>
                <TableCell>
                  {design.valueMap.opportunity_number && design.valueMap.opportunity_number.values![0]}
                </TableCell>
                <TableCell>{design.valueMap.project_name && design.valueMap.project_name.values![0]}</TableCell>
                <TableCell>
                  {design.valueMap.project_destination && design.valueMap.project_destination.values![0]}
                </TableCell>
                <TableCell>{design.valueMap.designer && design.valueMap.designer.values![0]}</TableCell>
                <TableCell>{design.valueMap.approver && design.valueMap.approver.values![0]}</TableCell>
                <TableCell children={design.version} />
                <TableCell children={design.status} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
});
