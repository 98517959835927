import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/styles";
import { Design } from "../../../application/data/design/design";
import { StyleRules, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { Typography, Button, Divider } from "@material-ui/core";
import { typographyVariants } from "../../theme/config";
import { mapEvent } from "../../../helpers/formatters";
import { DesignValuesSection } from "./design-values-section";
import { CalculationValue } from "../../../application/data/technology/technology";

interface OwnProps extends WithStyles<"root" | "image" | "header" | "footer"> {
  design: Design;
  onSelectItem?(design: Design): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    background: theme.palette.white.main,
    maxWidth: theme.spacing(32),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },
  image: {
    flexGrow: 0,
    marginBottom: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(3),
    height: theme.spacing(38)
  },
  footer: {
    textAlign: "center",
    marginTop: "auto",
    paddingTop: theme.spacing(3)
  }
});

const emptyValue = (): CalculationValue => {
  return {
    id: "empty",
    name: "-",
    values: ["-"],
    unit: "",
    recommendedValues: ["-"]
  };
};

const designSetA = ["aquaflex", "xiga", "xline"];
const designSetB = ["airlift-skds", "airlift-mgb", "crossflow"];

export const ComparisonItem = withStyles(
  styles,
  {}
)(
  observer(({ classes, design, onSelectItem }: OwnProps) => {
    if (!design.technology) {
      return <div>No Technology set</div>;
    }

    return (
      <div className={classes.root}>
        <header className={classes.header}>
          <div className={classes.image}>
            <img src={design.technology.image} width={"100%"} />
          </div>
          <Typography variant={typographyVariants.h4} align={"center"}>
            {design.technology.name}
          </Typography>
          <Typography paragraph variant={typographyVariants.body1} align={"center"}>
            {design.technology.oneliner}
          </Typography>
          <Typography paragraph variant={typographyVariants.body2}>
            {design.technology.description}
          </Typography>
        </header>

        {designSetA.includes(design.id) && (
          <React.Fragment>
            <Typography gutterBottom variant={typographyVariants.h4} color={"primary"}>
              Information
            </Typography>
            <Divider />
            <DesignValuesSection
              title={"Plant design"}
              values={[
                design.valueMap["water_source"],
                design.valueMap["capacity_base"],
                design.valueMap["capacity"],
                design.valueMap["temperature"]
              ]}
            />
            <DesignValuesSection
              title={"Configuration"}
              values={[
                design.valueMap["total_units"],
                (design.id === "aquaflex" && emptyValue()) || undefined,
                design.valueMap["xlr_rows"],
                design.valueMap["shorter_rows"],
                design.valueMap["membrane_housings"],
                (design.id === "aquaflex" && emptyValue()) || undefined,
                (design.id === "xiga" && emptyValue()) || undefined,
                design.valueMap["membrane_elements"],
                design.valueMap["spare_membrane_housings"],
                design.valueMap["spare_membrane_elements"],
                design.valueMap["total_membrane_elements"],
                design.valueMap["membrane_element"],
                design.valueMap["total_mebrane_area"],
                design.valueMap["backwash_trains"]
              ]}
            />
            <DesignValuesSection
              title={"Settings"}
              values={[
                design.valueMap["gross_filtration_flux"],
                design.valueMap["filtration_time"],
                design.valueMap["net_filtration_flux"],
                design.valueMap["avg_ceb_1_counter"],
                design.valueMap["ceb_1_counter"],
                design.valueMap["recovery"]
              ]}
            />
            <DesignValuesSection
              title={"Mass balance"}
              values={[
                design.valueMap["avg_feed_flow"],
                design.valueMap["net_permeate_flow"],
                design.valueMap["total_concentrate_flow"],
                (design.id === "xiga" && emptyValue()) || undefined,
                design.valueMap["avg_backwash_flow"],
                design.valueMap["avg_forward_flush_flow"],
                design.valueMap["avg_ceb_1_flow"]
              ]}
            />
            <DesignValuesSection
              title={"Equipment"}
              values={[
                design.valueMap["uf_feed_pump"],
                (design.id === "xiga" && emptyValue()) || undefined,
                (design.id === "xline" && emptyValue()) || undefined,
                design.valueMap["circulation_pump"],
                design.valueMap["fecl3_coagulant_pump"],
                (design.id === "xiga" && emptyValue()) || undefined,
                (design.id === "xline" && emptyValue()) || undefined,
                design.valueMap["airflush_flow"],
                design.valueMap["backwash_pumps"],
                design.valueMap["naocl_dosing_pump"],
                design.valueMap["naoh_dosing_pump"],
                design.valueMap["h2so4_dosing_pump"]
              ]}
            />
          </React.Fragment>
        )}

        {designSetB.includes(design.id) && (
          <React.Fragment>
            <Typography gutterBottom variant={typographyVariants.h4} color={"primary"}>
              Information
            </Typography>
            <Divider />
            <DesignValuesSection
              title={"Plant design"}
              values={[design.valueMap["water_source"], design.valueMap["capacity"]]}
            />
            <DesignValuesSection
              title={"Configuration"}
              values={[
                design.valueMap["total_units"],
                design.valueMap["megablock_type"],
                design.valueMap["unit_type"],
                design.valueMap["membrane_element"],
                design.valueMap["membrane_elements_bw_segement"],
                design.valueMap["membrane_elements_per_unit"],
                design.valueMap["membrane_elements"],
                (design.id !== "airlift-mgb" && design.valueMap["spare_membrane_elements"]) || undefined,
                design.valueMap["total_membrane_elements"],
                design.valueMap["total_mebrane_area"],
                design.valueMap["backwash_trains"],
                (design.id === "crossflow" && emptyValue()) || undefined
              ]}
            />
            <DesignValuesSection
              title={"Settings"}
              values={[
                design.valueMap["gross_filtration_flux"],
                design.valueMap["filtration_time"],
                design.valueMap["ceb_frequency"],
                design.valueMap["drain_frequency"],
                design.valueMap["recovery"],
                design.valueMap["minimum_design_flux"],
                design.valueMap["design_flux"],
                design.valueMap["maximum_design_flux"],
                (design.id === "crossflow" && emptyValue()) || undefined,
                (design.id === "crossflow" && emptyValue()) || undefined
              ]}
            />
            <Typography style={{ marginTop: 36 }} gutterBottom variant={typographyVariants.h4} color={"primary"}>
              Equipment
            </Typography>
            <Divider />
            <DesignValuesSection
              title={"Feed"}
              values={[
                design.valueMap["nr_of_skids_per_feed_pump"],
                design.valueMap["nr_of_feed_pumps"],
                design.valueMap["feed_flow"],
                design.valueMap["nr_of_airlift_blowers"],
                design.valueMap["airlift_blower"],
                design.valueMap["nr_of_circulation_pumps"],
                design.valueMap["circulation_pump_flow"],
                design.valueMap["nr_of_crossflow_pumps"],
                design.valueMap["flow_rate"],
                design.valueMap["naoh_dosing_pump"],
                design.valueMap["h2so4_dosing_pump"],
                (design.id === "crossflow" && emptyValue()) || undefined,
                (design.id === "airlift-skds" && emptyValue()) || undefined,
                (design.id === "airlift-skds" && emptyValue()) || undefined
              ]}
            />
            <DesignValuesSection
              title={"Permeate/Backwash"}
              values={[
                design.valueMap["nr_of_permeate_pumps"],
                design.valueMap["permeate_pump_flow"],
                design.valueMap["nr_of_backwash_pumps"],
                design.valueMap["backwash_pump_flow"],
                (design.id === "crossflow" && emptyValue()) || undefined,
                (design.id === "crossflow" && emptyValue()) || undefined,
                (design.id === "crossflow" && emptyValue()) || undefined,
                (design.id === "crossflow" && emptyValue()) || undefined
              ]}
            />
            <DesignValuesSection
              title={"CIP"}
              values={[
                design.valueMap["nr_of_naocl_dosing"],
                design.valueMap["naocl_dosing_flow"],
                design.valueMap["nr_of_citric_acid_dosing"],
                design.valueMap["citric_acid_dosing_flow"],
                design.valueMap["nr_of_cip_pumps"],
                design.valueMap["cip_flow_rate"],
                design.valueMap["nr_of_cip_tanks"],
                design.valueMap["cip_tank_volume"]
              ]}
            />
          </React.Fragment>
        )}

        <footer className={classes.footer}>
          <Button fullWidth onClick={mapEvent(onSelectItem, design)}>
            use {design.technology.name}
          </Button>
        </footer>
      </div>
    );
  })
);
