import { observer } from "mobx-react";
import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { mapEvent } from "../../../helpers/formatters";
import { User, UserData } from "../../../application/data/user/user";

import { IconButton } from "../mui-extended/mui-icon-button";

interface OwnProps {
  users: User[];
  onRowSelect(id: string): void;
  onEdit(id: string): void;
  onDelete(id: string): void;
  onCreate(data: UserData): void;
}

export const TableUsers = observer(function({
  users,
  onEdit,
  onDelete,
  onCreate
}: OwnProps) {
  return (
    <section>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding={"checkbox"} children={""} />
            <TableCell padding={"checkbox"} children={""} />
            <TableCell children={"Name"} />
            <TableCell children={"Last name"} />
            <TableCell children={"Username"} />
            <TableCell children={"Status"} />
            <TableCell children={"Login count"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => {
            return (
              <TableRow key={user.id}>
                <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton
                      onClick={mapEvent(onEdit, user.id)}
                      icon="pencil-alt"
                    />
                  }
                />
                <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton
                      onClick={mapEvent(user.delete)}
                      icon="trash-alt"
                    />
                  }
                />
                <TableCell children={user.attributes.name} />
                <TableCell children={user.attributes.family_name} />
                <TableCell children={user.username} />
                <TableCell children={user.status} />
                <TableCell children={user.login_history.length} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
});
