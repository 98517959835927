import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import { Grid, Theme, WithStyles, Button } from "@material-ui/core";

import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";

import { MenuDesignPages } from "../content/designs/menu";
import { StyleRules, withStyles } from "@material-ui/styles";
import PageHeader from "../layout/partials/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DesignInputsCapacityWidget } from "../content/designs/widgets/inputs-capacity";
import { DesignInputPresenter } from "./design-inputs-presenter";
import { route } from "../../config/routes";
import Widget from "../content/components/widget/widget";
import { ValuesTableForm } from "../content/components/design-values-table-form";
import { spacing } from "../theme/config";
import { Error404 } from "./error-404";

interface OwnProps extends RouteComponentProps, WithStyles<"layout"> {
  //
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  layout: {
    paddingLeft: theme.spacing(12)
  }
});

@observer
class Component extends React.Component<OwnProps & PresenterProps<DesignInputPresenter>> {
  public render() {
    const { classes, presenter } = this.props;
    const { compareAfter, loading, comparisonDesign } = presenter;
    const design = comparisonDesign || this.props.presenter.design;

    if (loading) {
      return "loading";
    }

    if (!design) {
      return <Error404 {...this.props} />;
    }

    const title = compareAfter
      ? "Comparison"
      : design.valueMap.project_name && design.valueMap.project_name.values![0].toString();

    return (
      <Layout
        classes={(!compareAfter && { content: classes.layout }) || undefined}
        titlePrefix={compareAfter ? "Technology" : design.technology && design.technology.name}
        title={title}
        subTitle='inputs'
      >
        {!compareAfter && <MenuDesignPages design={design} />}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageHeader>
              {compareAfter ? (
                <Button
                  component={Link}
                  variant='contained'
                  color='primary'
                  to={route("app.compare.technologies")}
                  endIcon={<FontAwesomeIcon icon={["fal", "check-circle"]} />}
                >
                  Compare technologies
                </Button>
              ) : (
                <Button
                  component={Link}
                  variant='contained'
                  color='primary'
                  to={route("app.design.results", { design: design.id })}
                  endIcon={<FontAwesomeIcon icon={["fal", "check-circle"]} />}
                >
                  Show design results
                </Button>
              )}
            </PageHeader>
          </Grid>

          <Grid item style={{ maxWidth: spacing(35) }}>
            <DesignInputsCapacityWidget design={design} />
          </Grid>
          {design.categorizedValues["input.water.main"] && design.categorizedValues["input.water.main"].length && (
            <Grid item>
              <Widget title='Main feed water parameters'>
                <ValuesTableForm
                  values={design.categorizedValues["input.water.main"]}
                  onValueChange={design.updateValue}
                />
              </Widget>
            </Grid>
          )}
          {design.categorizedValues["input.feed.detailed"] && design.categorizedValues["input.feed.detailed"].length && (
            <Grid item>
              <Widget title='Detailed feed parameters'>
                <ValuesTableForm
                  values={design.categorizedValues["input.feed.detailed"]}
                  onValueChange={design.updateValue}
                />
              </Widget>
            </Grid>
          )}
        </Grid>
      </Layout>
    );
  }
}

export const PageDesignInputs = withRouter(
  withStyles(styles, {})(
    withPresenter<DesignInputPresenter, OwnProps>(
      (props, { business }) => new DesignInputPresenter(business.design, business.technology, business.user, props),
      Component
    )
  )
);
