import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";
import { StorageRecord } from "../../storage/store";
import { App } from "../../App";
import { data } from "../../network/decorators/graphql-data";

export interface UserAttributes {
  email?: string;
  username?: string;
  phone_number?: string;
  address?: string;
  birthdate?: string;
  name?: string;
  middle_name?: string;
  family_name?: string;
  gender?: string;
  given_name?: string;
  locale?: string;
  groups?: string[];
  nickname?: string;
  picture?: string;
  preferred_username?: string;
  profile?: string;
  timezone?: string;
  updated_at?: string;
  website?: string;
}
export interface UserData extends StorageRecord {
  username: string;
  attributes: UserAttributes;
  login_history: string[];
  created_at: string;
  updated_at: string;
  status: string;
}

export interface User extends GraphQLModel<UserData>, UserData {
  // addDesign(data: Design): Design;
  // removeDesign(id: string): void;
}

export class UserModel extends GraphQLBase<UserData>
  implements GraphQLModel<UserData> {
  public typename: string = "User";

  @data public username = "Unknown";
  @data public created_at = "Unknown";
  @data public updated_at = "Unknown";
  @data public status = "Unknown";
  @data public attributes = {};
  @data public login_history = [];

  public serialize = (data?: UserData): UserData => {
    data = data || this.provider.serialize(this);
    // delete data.designs;
    return data;
  };

  public get provider() {
    return App.business.user.provider;
  }
}
