import { GraphQLResolveInfo } from "graphql";
import airliftMGB from "../../../data/technology/__mock__/airlift-mgb";
import aquaflexHS from "../../../data/technology/__mock__/aquaflex-hs";
import crossflowUF from "../../../data/technology/__mock__/crossflow-uf";
import crossflow from "../../../data/technology/__mock__/crossflow";
import xiga from "../../../data/technology/__mock__/xiga";
import xline from "../../../data/technology/__mock__/xline";
import airliftMBR from "../../../data/technology/__mock__/airlift-mbr";
import aquaflex from "../../../data/technology/__mock__/aquaflex";
import AnMBR from "../../../data/technology/__mock__/anmbr";

export const mockTechnologies = () => {
  return [aquaflex, aquaflexHS, xiga, xline, airliftMBR, airliftMGB, crossflow, crossflowUF, AnMBR];
};

export const technologyResolver = () => {
  return mockTechnologies();
};

export const QueryTechnologyResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  console.log("resolve ik een technology?");
  const result = mockTechnologies().filter((technology: any) => {
    return technology.id === args.id;
  });

  const technology = result[0];

  return technology || null;
};
