import * as React from "react";
import {
  AppBar,
  Theme,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Button
} from "@material-ui/core";
import {
  PentairBlue,
  PentairBrightBlue,
  PentairDarkGray,
  PentairLightGray
} from "../../theme/colors/_all";
import { User } from "../../../application/data/user/user";
import { observer } from "mobx-react";
import { IconButton } from "../../content/mui-extended/mui-icon-button";
import { Link } from "react-router-dom";
import { route } from "../../../config/routes";

const styles = createStyles((theme: Theme) => ({
  root: {
    // borderBottom: "2px solid " + PentairLightGray[500],
    flex: 1
  },
  username: {
    padding: "0 " + theme.spacing(2) + "px"
  },
  shadowAppbar: {
    height: 120
  },
  quicknav: {
    display: "flex",
    justifyContent: "space-between",
    ...theme.typography.button,
    fontSize: "1rem",
    fontWeight: "normal",
    fontFamily: theme.typography.h1.fontFamily,
    color: PentairBlue[500],
    padding: 0
  },
  quicknavLink: {},
  mainnav: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  logoLink: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 24,
    textDecoration: "none",
    position: "relative",
    paddingRight: 48
  },
  itemArrow: {
    "&::before": {
      zIndex: 3,
      content: "''",
      position: "absolute",
      top: -10,
      right: 0,
      width: 0,
      height: 0,
      borderTop: "24px solid transparent",
      borderLeft: "24px solid #fff",
      borderBottom: "24px solid transparent"
    },
    "&::after": {
      zIndex: 2,
      content: "''",
      position: "absolute",
      top: -10,
      right: -2, // extra space on top to create border
      width: 0,
      height: 0,
      borderTop: "24px solid transparent",
      borderLeft: "24px solid " + PentairLightGray[500],
      borderBottom: "24px solid transparent"
    }
  },
  mainnavLink: {
    ...theme.typography.button,
    fontFamily: theme.typography.h1.fontFamily,
    color: theme.palette.text.primary,
    padding: "0.8rem 0.75rem",
    "&.active": {
      color: PentairBrightBlue[500]
    }
  },
  menu: { marginRight: -theme.spacing(1) }
}));

interface OwnProps extends WithStyles<any> {
  user: User | undefined;
  onLogout(e: any): void;
}

export default withStyles(
  styles,
  {}
)(
  observer(function MainMenu({ user, classes, onLogout }: OwnProps) {
    return (
      <AppBar
        className={classes.root}
        elevation={0}
        position="static"
        color="inherit"
      >
        <Toolbar className={classes.quicknav} variant="dense">
          <Link
            to={route("app.user.dashboard")}
            className={`${classes.logoLink}`}
          >
            {/* ${classes.itemArrow} */}
            <img src="/images/logo.svg" height="28" />
            <Typography
              variant="h5"
              style={{
                marginLeft: 8,
                marginTop: -1,
                fontSize: "16px",
                fontWeight: 500,
                color: PentairDarkGray[500]
              }}
            >
              X-FLOW
            </Typography>
          </Link>

          <Typography variant={"h4"} style={{ marginTop: -3 }}>
            Xpert
          </Typography>

          <span>
            <Button variant="text">
              <Typography variant="h5">
                <span style={{ color: PentairBrightBlue[500] }}>
                  IMPROVING WATER. FOR LIFE.
                </span>
              </Typography>
            </Button>
            <Button variant="text">
              {(user && user.attributes.name) || (user && user.username)}
            </Button>

            <IconButton icon="sign-out" onClick={onLogout} />
          </span>
        </Toolbar>
      </AppBar>
    );
  })
);
