import * as React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";

import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { DesignPresenter } from "./design-presenter";
import { observer } from "mobx-react";
import { typographyVariants } from "../theme/config";
import { Typography, Grid, withStyles, Theme, Button } from "@material-ui/core";

import { MenuDesignPages } from "../content/designs/menu";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import Widget from "../content/components/widget/widget";
import PageHeader from "../layout/partials/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InteractiveImage from "../content/components/interactive-image";

import { Error404 } from "./error-404";
import { ValuesTable } from "../content/components/table-values";
import InfoMarker from "../content/components/info-marker";
import { route } from "../../config/routes";
import { CalculationValueOptions } from "../../application/data/__mock__/calculation-values";
import { CalculationValue } from "../../application/data/technology/technology";

type OwnProps = RouteComponentProps & WithStyles<"root" | "inner" | "layout">;

const styles = (theme: Theme): StyleRules => ({
  layout: {
    paddingLeft: theme.spacing(12)
  }
});

@observer
export class Component extends React.Component<OwnProps & PresenterProps<DesignPresenter>> {
  public render() {
    const { classes } = this.props;
    const { design, loading } = this.props.presenter;

    if (loading) {
      return "loading";
    }

    if (!design) {
      return <Error404 {...this.props} />;
    }

    return (
      <Layout
        classes={{ content: classes.layout }}
        titlePrefix={design.technology && design.technology.name}
        title={design.valueMap.project_name && design.valueMap.project_name.values![0].toString()}
        subTitle={"RESULTS"}
      >
        <MenuDesignPages design={design} />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageHeader>
              <Button
                component={Link}
                variant='contained'
                color='primary'
                to={route("app.user.dashboard")}
                endIcon={<FontAwesomeIcon icon={["fal", "check-circle"]} />}
              >
                Submit for approval
              </Button>
            </PageHeader>
          </Grid>
          <Grid item xs={12}>
            <Widget noHeader title='design overview'>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography gutterBottom variant={typographyVariants.condensed.h2} style={{ marginBottom: 24 }}>
                    Overview
                  </Typography>
                  <ValuesTable
                    hideRecommendedValue={true}
                    variant={"list"}
                    title='Design details'
                    values={design.categorizedValues["design.details"]}
                  />
                  <ValuesTable
                    hideRecommendedValue={true}
                    variant={"list"}
                    title='Design inputs'
                    values={[
                      design.valueMap["water_source"],
                      design.valueMap["capacity"],
                      design.valueMap["capacity_base"],
                      design.valueMap["design_turbidity"],
                      design.valueMap["temperature"]
                    ]}
                  />
                  {design.categorizedValues["results"] && design.categorizedValues["results"].length && (
                    <ValuesTable
                      hideRecommendedValue={true}
                      variant={"list"}
                      title='Main settings &amp; calculations'
                      values={design.categorizedValues["results"]}
                    />
                  )}

                  {design.value("comment") && design.value("comment").id && (
                    <>
                      <Typography gutterBottom variant={"h4"}>
                        Comments
                      </Typography>
                      <Typography variant={"body2"} style={{ maxWidth: "70%" }}>
                        Feed to bioreactor; FOG {"<"} 50 mg/l. / Cl = {"<"}150 mg/l / SS = {"<"}250 mg/l.
                        <br />
                        <br />
                        Minimal bioreactor performance: <br />
                        <ul>
                          <li>BOD removal > 98% // COD removal</li>
                          <li>TKN removal > 95% // NH4-removal > 98%</li>
                        </ul>
                        No peak flow to the UF.
                      </Typography>
                    </>
                  )}
                </Grid>

                <Grid item xs={9}>
                  <div style={{ marginLeft: -96, marginRight: -24, marginBottom: -48, marginTop: 24 }}>
                    {console.log(design && design.technology)}
                    {design.technology && (
                      <InteractiveImage img={design.technology.techImage}>
                        {() => (
                          <React.Fragment>
                            {design &&
                              design.technology &&
                              design.technology.techImageMap.map((markerConfig, index) => {
                                const calculationValues = markerConfig.values.map((id: CalculationValueOptions) =>
                                  design.value(id)
                                );

                                return (
                                  <InfoMarker
                                    title={markerConfig.title || calculationValues[0].name}
                                    icon='info-circle'
                                    location={markerConfig.location}
                                    rotation={markerConfig.rotation}
                                    align={markerConfig.align}
                                    line={markerConfig.line}
                                    info={markerConfig.info}
                                    width={markerConfig.width}
                                    animationDelay={300 * index}
                                    titleVariant={markerConfig.titleVariant}
                                  >
                                    <React.Fragment>
                                      {(calculationValues || []).map((cval: CalculationValue) => (
                                        <Typography
                                          variant='body2'
                                          style={{ fontSize: 13, lineHeight: 1, marginTop: 4 }}
                                        >
                                          {calculationValues.length === 1
                                            ? [cval.values.join(" - "), cval.unit].join(" ")
                                            : [cval.name + ":", cval.values.join(" - "), cval.unit].join(" ")}
                                        </Typography>
                                      ))}
                                    </React.Fragment>
                                  </InfoMarker>
                                );
                              })}
                          </React.Fragment>
                        )}
                      </InteractiveImage>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

export const PageResults = withRouter(
  withStyles(styles, {})(
    withPresenter<DesignPresenter, OwnProps>(
      (props, { business }) => new DesignPresenter(business.design, business.user, props),
      Component
    )
  )
);
