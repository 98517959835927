import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { DesignInteractor } from "../../application/data/design/design-interactor";
import { TechnologyInteractor } from "../../application/data/technology/technology-interactor";
import { DesignPresenter } from "./design-presenter";
import { observable } from "mobx";

export class DesignInputPresenter extends DesignPresenter implements IPresenter {
  @observable public compare: boolean = true;
  @observable public selection: string[] = [];

  constructor(
    protected _designInteractor: DesignInteractor,
    protected _technologyInteractor: TechnologyInteractor,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps
  ) {
    super(_designInteractor, _userInteractor, _router);
  }

  public get comparisonDesign() {
    const selection = this._technologyInteractor.comparisonSelection;
    return selection && selection.length > 0 && this._designInteractor.find(selection[0]);
  }

  public get compareAfter(): boolean {
    const selection = this._technologyInteractor.comparisonSelection;
    return (selection && selection.length > 0) || false;
  }

  public mount = () => {};
  public unmount = () => {};
}
