import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { withStyles, Theme, Typography } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { typographyVariants } from "../../theme/config";
import classNames from "classnames";

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.silverGray.main,
    textDecoration: "none",
    borderBottom: "1px solid #DEE2EC",
    "&:last-child": {
      borderBottom: "none"
    },
    "&:hover:not($disabled), &.active:not($disabled) ": {
      color: theme.palette.brightBlue.main,
      "& > $icon": {
        display: "none"
      },
      "& > $iconHover": {
        display: "block"
      }
    }
  },
  icon: {
    fontSize: "1.125rem",
    color: theme.palette.silverGray.main,
    marginBottom: theme.spacing(0.5)
  },
  iconHover: {
    display: "none",
    color: theme.palette.brightBlue.main
  },
  label: {},
  disabled: {
    opacity: 0.5
  }
});

interface OwnProps extends WithStyles<"root" | "icon" | "iconHover" | "label" | "disabled"> {
  title: string;
  icon: IconName;
  to: string;
  disabled?: boolean;
}

export const MenuButton = withStyles(styles)(({ title, icon, to, classes, disabled }: OwnProps) => {
  const navLinkProps: NavLinkProps = {
    to,
    className: classNames(classes.root, disabled && classes.disabled)
  };

  if (disabled) {
    navLinkProps.onClick = e => e.preventDefault();
  }

  return (
    <NavLink {...navLinkProps}>
      <FontAwesomeIcon className={classes.icon} icon={["fal", icon]} />
      <FontAwesomeIcon className={classNames(classes.icon, classes.iconHover)} icon={icon} />
      <Typography className={classes.label} variant={typographyVariants.h6} color={"inherit"}>
        {title}
      </Typography>
    </NavLink>
  );
});
