import React from "react";

import { observer } from "mobx-react";
import { mapEvent, nodeValue } from "../../../helpers/formatters";
import { UserData } from "../../../application/data/user/user";
import { TextField, Button } from "@material-ui/core";
import { updateValue } from "../../../application/helpers";

interface OwnProps {
  user: UserData;
  isNew: boolean;
  onCreate(user: Partial<UserData>): void;
  onUpdate(user: Partial<UserData>): void;
  onCancel(): void;
}

export const FormCreateUser = observer(function({
  user,
  onCreate,
  onUpdate,
  onCancel,
  isNew
}: OwnProps) {
  return (
    <section>
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder="E-mail"
        id="username"
        label="Username"
        onChange={mapEvent(updateValue, user, "username", nodeValue)}
        value={user.username}
      />
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder=""
        id="first-name"
        label="Name"
        onChange={mapEvent(updateValue, user.attributes, "name", nodeValue)}
        value={(user.attributes && user.attributes.name) || ""}
      />
      <TextField
        required
        fullWidth
        margin="normal"
        placeholder=""
        id="first-nsame"
        label="Last name"
        onChange={mapEvent(
          updateValue,
          user.attributes,
          "family_name",
          nodeValue
        )}
        value={(user.attributes && user.attributes.family_name) || ""}
      />
      <div style={{ marginTop: 12 }}>
        {isNew ? (
          <Button onClick={mapEvent(onCreate, user)}>Create User</Button>
        ) : (
          <>
            <Button onClick={mapEvent(onUpdate, user)}>Update User</Button>
            <Button
              style={{ marginLeft: 12 }}
              color={"secondary"}
              onClick={mapEvent(onCancel)}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </section>
  );
});
