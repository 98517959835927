import { InterfaceTools } from "../../../view/layout/partials/default-interface-presenter";
import { ToolConfig, toolConfig } from "../../../config/interface-tools";
import { computed, observable } from "mobx";

export interface InterfaceInteractor {
  activeTool: InterfaceTools;
  config: Record<InterfaceTools, ToolConfig>;
  activeToolConfig: ToolConfig;
}

export class DefaultInterfaceInteractor implements InterfaceInteractor {
  public config = toolConfig;

  @observable public activeTool: InterfaceTools = InterfaceTools.None;

  @computed
  public get activeToolConfig() {
    return this.config[this.activeTool];
  }
}
