import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, makeStyles, Theme, Typography, Link } from "@material-ui/core";

interface OwnProps {
  icon: IconName;
  title: string;
  to: string;
  linkText: string;
  subtitle: string;
  description: string;
  color: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "auto",
    color: "#fff",

    "&:before": {
      top: "75%"
    }
  },
  inner: {
    height: "100%",
    flexDirection: "column",
    textTransform: "initial"
  },
  text: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    height: "100%",
    marginBottom: theme.spacing(1)
  },
  footer: { textAlign: "right", justifySelf: "flex-end", alignSelf: "flex-end" },
  link: { color: "#fff" },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: { display: "flex", alignItems: "center", fontFamily: "Barlow Condensed", marginBottom: theme.spacing(1) },
  description: {}
}));

export default function BigButton(props: OwnProps) {
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      color={props.color}
      fullWidth
      className={classes.root}
      classes={{ label: classes.inner }}
      component={RouterLink}
      to={props.to}
    >
      <div className={classes.text}>
        <div className={classes.title}>
          <FontAwesomeIcon icon={["fal", props.icon]} size={"lg"} className={classes.icon} />
          <Typography
            variant='h5'
            color={"inherit"}
            style={{ fontFamily: "Barlow Condensed", fontWeight: 300, fontSize: "1.8em" }}
          >
            {props.title}
          </Typography>
        </div>

        <div className={classes.description}>
          <Typography variant='body2' color={"inherit"}>
            {props.description}
          </Typography>
        </div>
      </div>

      <div className={classes.footer}>
        <Link className={classes.link}>
          <small>{props.linkText}</small>
        </Link>
      </div>
    </Button>
  );
}
