import technology from "../../technology/__mock__/airlift-mgb";

export default {
  id: technology.id,
  version: "0.1",
  status: "Concept",
  company: "Code.Rehab",
  code: "AB-000-XS",
  country: "Netherlands",
  project: "Project name",
  proposalNr: "123-456-7890",
  values: technology.values,
  techImageMap: [
    {
      title: "Plant configuration",
      title_variant: "big",
      location: [10, 5],
      rotation: 0,
      values: [
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "spare_membrane_elements",
        "membrane_element",
        "total_mebrane_area",
        "backwash_trains"
      ]
    },
    {
      values: ["airflush_flow"],
      title_variant: "small",
      location: [10, 5],
      rotation: -18
    },
    {
      values: ["avg_feed_flow"],
      title_variant: "small",
      location: [48, 87],
      rotation: -19
    },
    {
      values: ["circulation_pump"],
      title_variant: "small",
      location: [48, 76],
      rotation: 0
    },
    {
      values: ["fecl3_dosing_pump"],
      title_variant: "small",
      location: [62.5, 77],
      rotation: 0
    },
    {
      values: ["feed_pumps"],
      title_variant: "small",
      location: [75, 70],
      rotation: 0
    },
    {
      values: ["backwash_pumps"],
      title_variant: "small",
      location: [75, 36],
      rotation: 0
    },
    {
      values: ["net_permeate_flow"],
      title_variant: "small",
      location: [95, 10],
      rotation: 10
    },
    {
      title: "Concentrate",
      values: [
        "total_concentrate_flow",
        "avg_forward_flush_flow",
        "avg_backwash_flow",
        "avg_ceb_1_flow",
        "avg_ceb_2_flow"
      ],
      title_variant: "small",
      location: [0, 10],
      rotation: 0
    }
  ],
  technology
};
