import { CalculationValue } from "../technology/technology";

export function createValuesFor(category: string, values: Array<Partial<CalculationValue>>) {
  return values.map(value => ({ ...value, category }));
}

export const values = {
  account_name: {
    name: "Account name",
    recommendedValues: ["Partners"]
  },
  opportunity_number: {
    name: "Opportunity number",
    recommendedValues: ["123-456-7890"]
  },
  project_name: {
    name: "Project name",
    recommendedValues: ["Red Sea Desalination"]
  },
  project_destination: {
    name: "Project destination",
    recommendedValues: ["Middle East"]
  },
  designer: {
    name: "Designed by",
    recommendedValues: ["K. Pitchaikani"]
  },
  approver: {
    name: "Approved by",
    recommendedValues: ["-"]
  },
  design_turbidity: {
    name: "Design turbidity",
    recommendedValues: ["Aquaflex 64"]
  },
  temperature: {
    name: "Temperature",
    recommendedValues: [20],
    unit: "°C",
    unitOptions: ["°C", "°F"],
    decimals: 1
  },
  turbidity: {
    name: "Turbidity",
    recommendedValues: [5],
    unit: "NTU",
    decimals: 1,
    error: "Some error message"
  },
  tss: {
    name: "TSS",
    recommendedValues: [10],
    unit: "mg/l",
    decimals: 1
  },
  toc: {
    name: "TOC",
    recommendedValues: [5],
    unit: "mg/l as C",
    decimals: 1
  },
  cod: {
    name: "COD",
    recommendedValues: [10],
    unit: "mg/l as O2",
    decimals: 1,
    error: "Some error message"
  },
  color: {
    name: "Color",
    recommendedValues: [5],
    unit: "Pt-Co",
    decimals: 1
  },
  alkanity: {
    name: "Alkanity",
    recommendedValues: [20],
    unit: "mg/l as CaCO3",
    decimals: 1
  },
  ph: {
    name: "PH",
    recommendedValues: [7],
    decimals: 1
  },
  tds: {
    name: "TDS",
    recommendedValues: [200],
    unit: "mg/l",
    decimals: 1
  },
  hardness: {
    name: "Hardness",
    recommendedValues: [7.2],
    unit: "mg/l as CaCO3",
    decimals: 1
  },
  temperature_range: {
    name: "Temperature range",
    recommendedValues: [5, 20],
    unit: "°C",
    unitOptions: ["°C", "°F"],
    decimals: 1
  },
  ph_range: {
    name: "pH Range",
    recommendedValues: [4.2, 7.0],

    decimals: 1
  },
  calcium: {
    name: "Calcium",
    recommendedValues: [14],
    unit: "mg/l",
    decimals: 1,
    error: "Some error message"
  },
  ammonia: {
    name: "Ammonia",
    recommendedValues: [1792],
    unit: "mg/l as NH4",
    decimals: 1
  },
  phosphorus: {
    name: "Phosphorus",
    recommendedValues: [28],
    unit: "mg/l as P",
    decimals: 1
  },
  water_source: {
    name: "Water Source",
    recommendedValues: ["Surface water"],
    decimals: 1
  },
  capacity: {
    name: "Capacity",
    recommendedValues: ["1000"],
    decimals: 1,
    unit: "m³/h"
  },
  capacity_base: {
    name: "Capacity based on",
    recommendedValues: ["Permeate production"],
    decimals: 1
  },

  membrane: {
    name: "Membrane",
    recommendedValues: ["Permeate production"],
    decimals: 1,
    unit: "m³/h"
  },
  gross_filtration_flux: {
    name: "Gross filtration flux",
    recommendedValues: [84],
    decimals: 1,
    unit: "l/m²/h"
  },
  filtration_time: {
    name: "Filtration time",
    recommendedValues: [45.0],
    decimals: 1,
    unit: "min"
  },
  net_filtration_flux: {
    name: "Net filtration flux",
    recommendedValues: [78.1],
    decimals: 1,
    unit: "l/m²/h"
  },
  recovery: {
    name: "Recovery",
    recommendedValues: [95.4],
    decimals: 1,
    unit: "%"
  },
  avg_ceb_1_counter: {
    name: "Avg. CEB 1 interval",
    recommendedValues: [24.3],
    decimals: 1,
    unit: "hrs once"
  },
  avg_ceb_2_counter: {
    name: "Avg. CEB 2 interval",
    recommendedValues: [0],
    decimals: 1,
    unit: "hrs once"
  },
  ceb_1_counter: {
    name: "CEB 1 Counter",
    recommendedValues: [31]
  },
  ceb_2_counter: {
    name: "CEB 2 Counter",
    recommendedValues: [0]
  },
  total_units: {
    name: "Total units",
    recommendedValues: [4]
  },
  membrane_elements: {
    name: "Membrane elements",
    recommendedValues: [50],
    unit: "per unit"
  },
  total_membrane_elements: {
    name: "Total membrane elements",
    recommendedValues: [200]
  },
  spare_membrane_elements: {
    name: "Spare membrane elements",
    recommendedValues: [0]
  },
  membrane_element: {
    name: "Membrane element",
    recommendedValues: ["Aquaflex 64"]
  },
  total_mebrane_area: {
    name: "Total membrane area",
    recommendedValues: [12800],
    unit: "m²"
  },
  backwash_trains: {
    name: "Backwash trains",
    recommendedValues: [1]
  },
  airflush_flow: {
    name: "Airflush flow",
    recommendedValues: [500],
    unit: "Nm³/h per unit"
  },
  avg_feed_flow: {
    name: "Avg feed flow",
    recommendedValues: [1048.6],
    decimals: 1,
    unit: "m³/h"
  },
  circulation_pump: {
    name: "Circulation pump",
    recommendedValues: [48],
    unit: "m³/h per unit"
  },
  fecl3_dosing_pump: {
    name: "FeCl₃ dosing pump",
    recommendedValues: [5.5],
    decimals: 1,
    unit: "lph"
  },
  feed_pumps: {
    name: "Feed pump(s)",
    recommendedValues: [1075.2],
    decimals: 1,
    unit: "m³/h"
  },
  backwash_pumps: {
    name: "Backwash pump(s)",
    recommendedValues: [768],
    decimals: 1,
    unit: "m³/h"
  },
  net_permeate_flow: {
    name: "Net permeate flow",
    recommendedValues: [1000],
    decimals: 1,
    unit: "m³/h"
  },

  total_concentrate_flow: {
    name: "Total concentrate flow",
    recommendedValues: [48.6],
    decimals: 1,

    unit: "m³/h"
  },
  avg_forward_flush_flow: {
    name: "Avg. forward flush flow",
    recommendedValues: [16.3],
    decimals: 1,

    unit: "m³/h"
  },
  avg_backwash_flow: {
    name: "Avg. backwash flow",
    recommendedValues: [25],
    unit: "m³/h"
  },
  avg_ceb_1_flow: {
    name: "Avg. CEB 1 flow",
    recommendedValues: [7.2],
    decimals: 1,
    unit: "m³/h"
  },
  avg_ceb_2_flow: {
    name: "Avg. CEB 2 flow",
    recommendedValues: [0],
    unit: "m³/h"
  },
  coagulant_chemical: {
    name: "Coagulant",
    recommendedValues: [0]
  },
  coagulant_dosing_pump: {
    name: "Dosing pump",
    recommendedValues: [0],
    unit: "lph"
  },
  coagulant_consumption: {
    name: "Consumption",
    recommendedValues: [0],
    unit: "lpd"
  },
  naocl_chemical: {
    name: "NaOCl",
    recommendedValues: [0]
  },
  naocl_dosing_pump: {
    name: "NaOCl pump",
    recommendedValues: [0],
    unit: "lph"
  },
  naocl_consumption: {
    name: "Consumption",
    recommendedValues: [0],
    unit: "lpd"
  },
  naoh_chemical: {
    name: "NaOCl",
    recommendedValues: [0]
  },
  naoh_dosing_pump: {
    name: "NaOH pump",
    recommendedValues: [0],
    unit: "lph"
  },
  naoh_consumption: {
    name: "Consumption",
    recommendedValues: [0],
    unit: "lpd"
  },
  h2so4_dosing_pump: {
    name: "H2SO4 pump",
    recommendedValues: [269.1],
    decimals: 1,
    unit: "lph"
  },
  h2so4_consumption: {
    name: "Consumption",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "lpd"
  },
  membrane_housings: {
    name: "Membrane housings",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "per unit"
  },
  spare_membrane_housings: {
    name: "Spare Membrane housings",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "per unit"
  },
  xiga_membrane_elements: {
    name: "Membrane elements",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "per housing"
  },
  xlr_rows: {
    name: "XLR Rows",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "per unit"
  },
  shorter_rows: {
    name: "Shorter Rows",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "per unit"
  },
  duration: {
    name: "Duration",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "hrs per day"
  },
  comment: {
    name: "General comments",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "hrs per day"
  },
  ceb_frequency: {
    name: "CEB Frequency",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "days/CEB"
  },
  drain_frequency: {
    name: "Drain Frequency",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "drains/day"
  },
  uf_type: {
    name: "UF Type",
    recommendedValues: [9.1],
    decimals: 1,
    unit: ""
  },
  // new
  uf_feed_pump: {
    name: "UF feed pump",
    recommendedValues: [1000],
    decimals: 1,
    unit: "m³/h"
  },
  fecl3_coagulant_pump: {
    name: "FeCL3 coagulant pump",
    recommendedValues: [5.5],
    decimals: 1,
    unit: "lph"
  },
  // end new
  feed_flow: {
    name: "Feed Flow",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h/unit"
  },
  airlift_flow: {
    name: "Airlift Flow",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "Nm³/h/unit"
  },
  gross_permeate_flow: {
    name: "Gross permeate flow",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h/unit"
  },
  citric_acid: {
    name: "Citric acid",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h/unit"
  },
  drain_volume: {
    name: "Drain volume",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/unit"
  },
  permeate_pump: {
    name: "Permeate pump",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h/unit"
  },
  permeate_tank: {
    name: "Permeate tank",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³"
  },
  drain_tank: {
    name: "Drain tank",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³"
  },
  drain_pump: {
    name: "Drain pump",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h"
  },
  concentrate_flow: {
    name: "Concentrate flow",
    recommendedValues: [9.1],
    decimals: 1,
    unit: "m³/h/unit"
  },
  membrane_elements_bw_segement: {
    name: "Membrane elements",
    recommendedValues: [9.1],
    unit: "per BW segment"
  },
  membrane_elements_per_unit: {
    name: "Membrane elements",
    recommendedValues: [9.1],
    unit: "per MGB unit"
  },
  megablock_type: {
    name: "Megablock type",
    recommendedValues: [9.1]
  },
  total_mgb_units: {
    name: "Total number of mgb units",
    recommendedValues: [9.1]
  },
  minimum_design_flux: {
    name: "Minimum design flux",
    recommendedValues: [9.1],
    unit: "lmh"
  },
  design_flux: {
    name: "Design flux",
    recommendedValues: [9.1],
    unit: "lmh"
  },
  maximum_design_flux: {
    name: "Maximum design flux",
    recommendedValues: [9.1],
    unit: "lmh"
  },
  skid_type: {
    name: "Skid type",
    recommendedValues: [9.1],
    unit: "lmh"
  },
  nr_of_skids_per_feed_pump: {
    name: "Number of skids per feed pump",
    recommendedValues: [2],
    unit: ""
  },
  nr_of_feed_pumps: {
    name: "Number of feed pumps",
    recommendedValues: [8],
    unit: ""
  },
  nr_of_crossflow_pumps: {
    name: "Number of pumps",
    recommendedValues: [8],
    unit: ""
  },
  flow_rate: {
    name: "Flow rate",
    recommendedValues: [8],
    unit: "m³/h"
  },
  nr_of_cip_pumps: {
    name: "Number of CIP Flow pumps",
    recommendedValues: [8],
    unit: ""
  },
  cip_flow_rate: {
    name: "CIP Flow rate",
    recommendedValues: [8],
    unit: "m³/h"
  },
  nr_of_cip_tanks: {
    name: "nr of CIP tanks",
    recommendedValues: [8],
    unit: ""
  },
  cip_tank_volume: {
    name: "CIP tank volume",
    recommendedValues: [8],
    unit: "m³"
  },
  nr_of_airlift_blowers: {
    name: "Number of Airlift blowers",
    recommendedValues: [1]
  },
  airlift_blower: {
    name: "Airlift blower",
    recommendedValues: [4320],
    unit: "Nm³/h"
  },
  nr_of_circulation_pumps: {
    name: "Number of circulation pumps",
    recommendedValues: [2]
  },
  circulation_pump_flow: {
    name: "Circulation pump flow",
    recommendedValues: [965],
    unit: "m³/h"
  },
  nr_of_permeate_pumps: {
    name: "Number of permeate pumps",
    recommendedValues: [2]
  },
  permeate_pump_flow: {
    name: "Permeate pump flow",
    recommendedValues: [318],
    unit: "m³/h"
  },
  nr_of_backwash_pumps: {
    name: "Number of backwash pumps",
    recommendedValues: [1]
  },
  backwash_pump_flow: {
    name: "Backwash pump flow",
    recommendedValues: [346],
    unit: "m³/h"
  },
  nr_of_naocl_dosing: {
    name: "Number of NaOCl dosing",
    recommendedValues: [1]
  },
  naocl_dosing_flow: {
    name: "NaOCl dosing flow",
    recommendedValues: [1500],
    unit: "lph"
  },
  nr_of_citric_acid_dosing: {
    name: "Number of Citric acid dosing",
    recommendedValues: [1]
  },
  citric_acid_dosing_flow: {
    name: "Citric acid dosing flow",
    recommendedValues: [7000],
    unit: "lph"
  },
  unit_type: {
    name: "Unit type",
    recommendedValues: ["AL30"]
  }
};

export type CalculationValueOptions = keyof (typeof values);

export const setValue = (
  key: CalculationValueOptions,
  value?: Array<string | number | boolean>,
  recommended?: Array<string | number | boolean>,
  unit?: string
) => {
  const config: Partial<CalculationValue> = values[key];
  return {
    id: key,
    category: "uncategorized",
    name: undefined,
    decimals: 0,
    disabled: false,
    error: "",
    unitOptions: [],
    valueOptions: [],
    ...config,
    values: value || config.values || recommended || config.recommendedValues || [],
    recommendedValues: recommended || config.recommendedValues || [],
    unit: unit || config.unit || ""
  };
};

// ...createValuesFor("Design Inputs", [
//   ["Water source", "Sea Water", ""],
//   ["Capacity", "1000", "m²/h"],
//   ["Capacity based on", "Permeate production", ""],
//   ["Design turbility", "10", "NTU"],
//   ["Temperature", "20", "°C"],
//   ["Water source", "Sea Water", ""]
// ]),
// ...createValuesFor("Main Settings & Calculations", [
//   ["Gross filtration flux", "84,0", "l/m²/h"],
//   ["Filtration time", "45,0", "min"],
//   ["Net filtration flux", "78.1", "l/m²/h"],
//   ["Recovery", "95,4", "%"],
//   ["Avg. CEB 1 interval", "24,3", "h"],
//   ["Avg. CEB 2 interval", "0,0", "h"],
//   ["CEB 1 counter", "31", ""],
//   ["CEB 2 counter", "0", ""]
// ]),
// ...createValuesFor("Plant configuration", [
//   ["Total units", "4", ""],
//   ["Membrane elements", "50", "per unit"],
//   ["Total membrane elements", "200", ""],
//   ["Spare membrane elements", "0", ""],
//   ["Membrane element", "Aquaflex 64", ""],
//   ["Total membrane area", "12800", "m²"],
//   ["Backwash trains", "1", ""]
// ])
