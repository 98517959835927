import {
    ModalPresenter,
} from '../../../../application/business/interactor/modal-interactor';

export default class ConfirmationModalPresenter implements ModalPresenter {
  public active: boolean = false;
  public open = () => {
    this.active = true;
  };

  public close = () => {
    this.active = false;
  };
}
