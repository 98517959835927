import { TechnologyFragment } from "../../technology/graphql/fragments";

export const DesignFragment = `
  id
  project
  values {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions
    disabled
    error
    category
    decimals
  }
  technology {
    ${TechnologyFragment}
  }
`;
