import * as React from "react";
import { InterfaceTools, InterfaceData, InterfaceActions } from "../view/layout/partials/default-interface-presenter";
import { DrawerMenu } from "../view/layout/drawers/menu";
import { DrawerNotifications } from "../view/layout/drawers/notifications";
import { DrawerContent } from "../view/layout/drawers/content";
import { Redirect } from "react-router";
import { route } from "./routes";

export interface ToolConfig {
  title: string;
  width: number;
  active: boolean;
  render(data: InterfaceData, actions: InterfaceActions): React.ReactNode;
}

export interface ToolRenderProps {
  data: InterfaceData;
  actions: InterfaceActions;
}

export const toolConfig: Record<InterfaceTools, ToolConfig> = {
  menu: {
    title: "Menu",
    width: 204,
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerMenu, { pages: data.pages });
    }
  },
  homepage: {
    title: "Homepage",
    width: 0,
    active: false,
    render: (data, actions) => {
      return React.createElement(Redirect, { to: route("app.user.dashboard") });
    }
  },
  notifications: {
    title: "Notifications",
    width: 312,
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerNotifications);
    }
  },
  "page-info": {
    title: "Information",
    width: 312,
    active: false,
    render: () => {
      return React.createElement(DrawerContent);
    }
  },
  support: {
    title: "Pentair Support",
    width: 312,
    active: false,
    render: () => {
      return React.createElement(DrawerContent);
    }
  },
  "not-set": {
    title: "",
    width: 0,
    active: false,
    render: () => {
      return undefined;
    }
  }
};
