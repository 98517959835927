import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Typography, Theme } from "@material-ui/core";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { route } from "../../config/routes";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { AuthPagePresenter } from "./_base-auth-page-presenter";
import { StyleRules, withStyles, WithStyles } from "@material-ui/core/styles";
import { typographyVariants } from "../theme/config";
import { PentairBlue } from "../theme/colors/_all";
import { CanView } from "../content/components/can-view/can-view";

type OwnProps = RouteComponentProps &
  WithStyles<
    | "root"
    | "layout"
    | "content"
    | "intro"
    | "button"
    | "buttonLabel"
    | "buttonIcon"
  >;

interface ButtonOptions {
  label: string;
  icon: IconName;
  href: string;
  disabled?: boolean;
  roles: string[];
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  layout: {
    backgroundColor: theme.palette.white.main
  },

  content: {
    textAlign: "center",
    maxWidth: 900
  },

  intro: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },

  button: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    fontSize: "1rem",
    "&:before": {
      top: "85%"
    }
  },

  buttonLabel: {
    flexDirection: "column"
  },

  buttonIcon: {
    margin: 0,
    marginBottom: theme.spacing(1)
  }
});

const HomeComponent = withStyles(styles)(
  observer(
    ({ classes, presenter }: OwnProps & PresenterProps<AuthPagePresenter>) => {
      const options: ButtonOptions[] = [
        {
          label: "Select Technology",
          icon: "plus",
          href: route("app.design.assistant"),
          roles: ["admin", "public"]
        },
        {
          label: "Manage designs",
          icon: "pencil-alt",
          href: route("app.design.overview"),
          disabled: !presenter.isAdmin,
          roles: ["admin"]
        },
        {
          label: "Manage users",
          icon: "plus",
          href: route("app.user.overview"),
          disabled: !presenter.isAdmin,
          roles: ["admin"]
        },
        {
          label: "Manage warranties",
          icon: "plus",
          href: route("app.design.assistant"),
          disabled: true,
          roles: ["admin"]
        },
        {
          label: "Manage quotations",
          icon: "plus",
          href: route("app.design.assistant"),
          disabled: true,
          roles: ["admin"]
        }
      ];

      const { user } = presenter;

      if (!user) {
        return <Typography>loading user</Typography>;
      }

      return (
        <Layout noHeader classes={{ root: classes.layout }}>
          <div className={classes.root}>
            <div className={classes.content}>
              <FontAwesomeIcon
                icon={["fal", "user-headset"]}
                size={"4x"}
                color={PentairBlue[500]}
              />

              <div className={classes.intro}>
                <Typography
                  gutterBottom
                  variant={typographyVariants.condensed.h1}
                  color={"primary"}
                >
                  Hi {user.attributes.name}, Welcome to Pentair Xpert
                </Typography>
                <CanView>
                  <Typography
                    paragraph
                    variant={typographyVariants.body1}
                    gutterBottom
                  >
                    I am Xpert Assistant, the world’s first conversational
                    interface in the membrane filtration industry. <br />I am
                    here to assist you in any way I can.
                  </Typography>
                </CanView>
              </div>

              <Typography paragraph variant={typographyVariants.h3}>
                What would you like to do?{" "}
              </Typography>

              <Grid
                container
                spacing={1}
                direction={"row"}
                justify={"center"}
                alignItems="center"
              >
                {options.map((cfg, index) => (
                  <CanView roles={cfg.roles}>
                    <Grid
                      key={index}
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      style={{ maxWidth: 170 }}
                    >
                      <Button
                        fullWidth
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel,
                          startIcon: classes.buttonIcon
                        }}
                        variant={"outlined"}
                        color={"secondary"}
                        component={Link}
                        to={cfg.href}
                        disabled={cfg.disabled}
                      >
                        {cfg.label}
                      </Button>
                    </Grid>
                  </CanView>
                ))}
              </Grid>
            </div>
          </div>
        </Layout>
      );
    }
  )
);

export const PageHome = withRouter(
  withPresenter<AuthPagePresenter, RouteComponentProps>(
    (props, { business }) => new AuthPagePresenter(business.user, props),
    HomeComponent
  )
);
