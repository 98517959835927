import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { observer } from "mobx-react";
import { TableDesigns } from "../content/components/table-designs";
import { withRouter, RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import Widget from "../content/components/widget/widget";
import PageHeader from "../layout/partials/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DesignPresenter } from "./design-presenter";
import { route } from "../../config/routes";

type OwnProps = RouteComponentProps;

@observer
class DesignOverviewComponent extends React.Component<OwnProps & PresenterProps<DesignPresenter>> {
  public render() {
    const { allDesigns, user } = this.props.presenter;

    if (!user) {
      return "loading user";
    }

    return (
      <Layout titlePrefix={"Xpert"} title={"Design overview"} subTitle={""}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageHeader>
              <Button
                component={Link}
                variant='contained'
                color='primary'
                to={route("app.design.assistant")}
                endIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
              >
                Create design
              </Button>
            </PageHeader>
          </Grid>

          <Grid item xs={12}>
            <Widget title='design overview'>
              <TableDesigns
                onCreate={() => {}}
                onDownload={() => {}}
                onApprove={() => {}}
                onRowSelect={() => {}}
                onEdit={design => {
                  this.props.history.push(route("app.design.results", { design }));
                }}
                onDelete={() => {}}
                designs={allDesigns}
              />
            </Widget>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

export const PageDesignOverview = withRouter(
  withPresenter<DesignPresenter, RouteComponentProps>(
    (props, { business }) => new DesignPresenter(business.design, business.user, props),
    DesignOverviewComponent
  )
);
