import { DefaultStore, Store, StorageRecord } from "../storage/store";
import { GraphQLModel } from "./graphql-model";
import { GraphQLOptions } from "@aws-amplify/api/lib/types";
import { API } from "aws-amplify";
import { toJS } from "mobx";

export interface GraphQLProviderProps<
  T extends GraphQLModel<TData>,
  TData extends StorageRecord
> extends Store<TData> {
  createOperation(instance: TData): GraphQLOptions | undefined;
  fetchOperation(instance: TData): GraphQLOptions | undefined;
  updateOperation(instance: TData): GraphQLOptions | undefined;
  deleteOperation(instance: TData): GraphQLOptions | undefined;
  deleteOperation(instance: TData): GraphQLOptions | undefined;
  find(id: string): T;
  store(instance: T): Promise<void>;
  create: (data: Partial<TData>, persist?: boolean) => T;
  model:
    | { new (...args: any[]): GraphQLModel<TData>; get: (id: string) => T }
    | undefined;
  serialize(instance: T): TData;
  subscribe(key: string, handler: (model: T) => void): void;
}

export default class GraphQLProvider<
  T extends GraphQLModel<TData>,
  TData extends StorageRecord
> extends DefaultStore<TData> {
  public subscribers: Record<string, Array<(model: T) => void>> = {};

  constructor() {
    super();
    if ((this as any)._subscriptions) {
      (this as any)._subscriptions.map((subscribe: any) => {
        subscribe(this);
      });
    }
  }

  public store = async (instance: T) => {
    const operationFunc = (this as any).createOperation;
    const operation = operationFunc(this.serialize(instance));
    this.setRecord(instance.id, this.serialize(instance));

    if (operation) {
      await API.graphql(operation);
    }
  };

  public find = (id: string) => {
    return this.createInstance({ id } as Partial<TData>);
  };

  public create = (data?: Partial<TData>, persist: boolean = false) => {
    const instance: T = this.createInstance(data);
    this.setRecord(instance.id, data as any);

    if (persist) {
      this.store(instance).then(response => {});
    }

    return this.createInstance({ id: instance.id } as any);
  };

  public serialize(instance: T): TData {
    const data = toJS(this.getRecord(instance.id));
    const updates = toJS(instance.updated);
    return instance.serialize({ id: instance.id, ...data, ...updates });
  }

  public createInstance(data: Partial<TData> = {}): T {
    return new (this as any).model(data.id, this);
  }

  public subscribe(key: string, handler: (model: T) => void) {
    this.subscribers[key] = this.subscribers[key] || [];
    this.subscribers[key].push(handler);
  }
}
