import * as React from "react";
import { Typography } from "@material-ui/core";

export const DrawerContent = () => {
  return (
    <div>
      <Typography variant="body2">
        This is the beta version of Pentair Xpert to test Xpert Assistant - the
        Chatbot to select X-Flow technologies.
        <br />
        <br />
        Chatbot version: PXA0.1
      </Typography>
    </div>
  );
};
