import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { Fade } from "@material-ui/core";

interface OwnProps {
  img: Array<string>;
  children: (loaded: boolean) => React.ReactNode;
}

@observer
export default class InteractiveImage extends React.Component<OwnProps> {
  @observable private _loaded = false;

  private _imageLoaded = (e: any) => {
    this._loaded = true;
  };

  public render() {
    const { img, children } = this.props;

    return (
      <Fade in={this._loaded}>
        <div style={{ position: "relative" }}>
          {img.map((image: string, index) =>
            index == 0 ? (
              <img width="100%" key={index} src={image} onLoad={this._imageLoaded} />
            ) : (
              <img
                width="100%"
                key={index}
                src={image}
                style={{ position: "absolute", top: 0, left: 0 }}
                onLoad={this._imageLoaded}
              />
            )
          )}
          {this._loaded && children(this._loaded)}
        </div>
      </Fade>
    );
  }
}
