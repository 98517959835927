// import { DesignFragment } from "./fragments";

export const CreateDesign = `
mutation CreateDesign($input:inputDesign!) {
createDesign(input:$input){
  id

}
`;

export const UpdateDesign = `
mutation updateDesign($input:inputDesign!) {
  updateDesign(input:$input) {
    id
  }
}
`;

export const DeleteDesign = `
mutation DeleteDesign($id:ID!) {
  deleteDesign(id:$id){
    id
  }
}
`;

export const DesignAddUser = `
mutation DesignAddUser($id:ID!, $userID:ID!) {
  designAddUser(id:$id, userID: $userID){
    id
  }
}
`;

export const DesignRequestApproval = `
mutation DesignRequestApproval($id:ID!) {
  designRequestApproval(id:$id){
    id
  }
}
`;
