import { ListClassKey } from "@material-ui/core/List";
import { ListItemClassKey } from "@material-ui/core/ListItem";
import { MenuItemClassKey } from "@material-ui/core/MenuItem";
import { CSSProperties } from "@material-ui/styles";

import { PentairBrightBlue, PentairDarkGray, PentairLightGray } from "../colors/_all";
import { spacing, typography } from "../config";

export const MuiList: Partial<Record<ListClassKey, CSSProperties>> = {
  root: {},
  padding: {
    paddingTop: 0,
    paddingBottom: 0
  }
};

export const MuiListItem: Partial<Record<ListItemClassKey, CSSProperties>> = {
  root: {
    fontSize: typography("body1").fontSize,
    paddingTop: spacing(0),
    paddingBottom: spacing(0)
  }
};

export const MuiMenuItem: Partial<Record<MenuItemClassKey, CSSProperties>> = {
  root: {
    fontSize: typography("body2").fontSize,
    fontWeight: 300,
    color: PentairDarkGray[300],
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    "&$selected": {
      backgroundColor: PentairLightGray[500],
      color: PentairBrightBlue[500]
    },

    "&:hover": {
      backgroundColor: PentairLightGray[500],
      color: PentairBrightBlue[500]
    },

    "&$selected:hover": {
      backgroundColor: PentairLightGray[900],
      color: PentairBrightBlue[500]
    }
  }
};
