import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Theme, WithStyles } from "@material-ui/core";

import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { StyleRules, withStyles } from "@material-ui/styles";

import { CompareTechnologyPresenter } from "./compare-technologies-presenter";
import { ComparisonItem } from "../content/components/comparison-item";
import { spacing } from "../theme/config";
import { route } from "../../config/routes";
import { Redirect } from "react-router";

interface OwnProps extends RouteComponentProps, WithStyles<"layout" | "wrapper" | "comparisonItem"> {
  onCompare(selection: string[]): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1
  },

  layout: {},
  comparisonItem: {
    marginRight: theme.spacing(1)
  }
});

@observer
class Component extends React.Component<OwnProps & PresenterProps<CompareTechnologyPresenter>> {
  public render() {
    const { classes } = this.props;
    const { designs, selectTechnology } = this.props.presenter;

    if (!designs.length) {
      return <Redirect to={route("app.user.dashboard")} />;
    }

    return (
      <Layout
        classes={{ content: classes.layout }}
        title={"Compare technologies"}
        style={{ minWidth: "100%", width: spacing(31 * designs.length) }}
      >
        {/* <MenuDesignPages design={design} /> */}
        <div style={{ display: "flex" }}>
          {designs.map(design => (
            <ComparisonItem
              design={design}
              classes={{ root: classes.comparisonItem }}
              onSelectItem={selectTechnology}
            />
          ))}
        </div>
      </Layout>
    );
  }
}

export const PageCompareTechnologies = withRouter(
  withStyles(styles, {})(
    withPresenter<CompareTechnologyPresenter, OwnProps>(
      (props, { business }) =>
        new CompareTechnologyPresenter(business.design, business.technology, business.user, props),
      Component
    )
  )
);
