import { IPresenter } from "../../../helpers/with-presenter";
import { observable, computed } from "mobx";
import { DesignInteractor } from "../../../application/data/design/design-interactor";
import { TechnologyInteractor } from "../../../application/data/technology/technology-interactor";
import { RouteComponentProps } from "react-router";
import { route } from "../../../config/routes";
import { Technology } from "../../../application/data/technology/technology";
import { UserInteractor } from "../../../application/data/user/user-interactor";

export class TechnologySelectorPresenter implements IPresenter {
  @observable public compare: boolean = false;
  @observable public selection: string[] = [];
  @observable public include: string[] = [];

  constructor(
    protected _designInteractor: DesignInteractor,
    protected _technologyInteractor: TechnologyInteractor,
    protected _userInteractor: UserInteractor,
    protected props: RouteComponentProps & { include?: string[] | undefined; status?: string }
  ) {}

  public mount = () => {
    this._technologyInteractor.comparisonSelection = [];
  };
  public unmount = () => {};

  @computed public get isAdmin() {
    return this._userInteractor.isAdmin;
  }

  @computed public get technologies(): Technology[] {
    const select = this.include;
    if (select && select.length > 0) {
      const includedTech = this._technologyInteractor.all.filter(tech => {
        return select.includes(tech.id) && (!this.compare || tech.comparableTechnologies.length);
      });

      return includedTech;
    }

    return this._technologyInteractor.all;
  }

  public isComparable = (tech: Technology) => {
    return (
      !this.compare ||
      !this.selection.length ||
      this.selection.includes(tech.id) ||
      (this.selection.length &&
        tech.comparableTechnologies.reduce((include: boolean, id) => {
          return include || this.selection.includes(id);
        }, false))
    );
  };

  public selectTechnology = (technologyID: string) => {
    if (technologyID === "crossflow-uf") {
      this.props.history.push(route("app.technology.crossflow-uf"));
      return;
    }

    if (technologyID === "anmbr") {
      this.props.history.push(route("app.technology.anmbr"));
      return;
    }

    if (this.compare) {
      if (this.selection.includes(technologyID)) {
        this.selection.splice(this.selection.indexOf(technologyID), 1);
      } else {
        this.selection.push(technologyID);
      }
      return;
    }

    this.props.history.push(route("app.design.inputs", { design: technologyID }));
  };

  public toggleComparison = () => {
    this.selection = [];
    this.compare = !this.compare;
  };

  public startComparison = () => {
    this._technologyInteractor.comparisonSelection = this.selection;
    const design = this._designInteractor.create({ id: "comparison" });
    this.props.history.push(route("app.design.inputs", { design: design.id }));
  };
}
