import { observer } from "mobx-react";
import * as React from "react";

import Footer from "./layout-footer";
import Header from "./layout-header-default";
import { DefaultInterface } from "./partials/default-interface";
import { LayoutDefaultPresenter } from "./layout-default-presenter";
import { withRouter, RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { spacing } from "../theme/config";
import { StyleRules, withStyles, WithStyles, CSSProperties } from "@material-ui/styles";
import { Theme, Fade } from "@material-ui/core";

interface OwnProps extends WithStyles<"root" | "content" | "header" | "footer"> {
  title?: string;
  subTitle?: string;
  noHeader?: boolean;
  header?: React.ReactElement;
  withContact?: boolean;
  children: React.ReactNode | React.ReactNode[];
  backgroundImage?: string;
  backgroundColor?: string;
  style?: CSSProperties;
  titlePrefix?: string;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(4),
    transition: "padding-left 0.3s ",
    background: "#f2f2f2",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  header: {},
  content: {
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    marginTop: "auto"
  }
});

@observer
class Component extends React.Component<RouteComponentProps & OwnProps & PresenterProps<LayoutDefaultPresenter>> {
  public render() {
    const { title, subTitle, presenter, children, classes, noHeader, style, titlePrefix, backgroundColor } = this.props;

    const inlineStyle: CSSProperties = {
      paddingLeft: spacing(6) + presenter.interface.activeToolConfig.width
    };

    if (backgroundColor) {
      inlineStyle.backgroundColor = backgroundColor;
    }

    return (
      <div className={classes.root} style={inlineStyle}>
        <Fade in timeout={700}>
          <main className={classes.content} style={style}>
            {!noHeader && <Header titlePrefix={titlePrefix} title={title} subTitle={subTitle} user={presenter.user} />}
            {children}
            <Footer classes={{ root: classes.footer }} />
          </main>
        </Fade>
        <DefaultInterface />
      </div>
    );
  }
}

export const DefaultLayout = withStyles(styles, {})(
  withRouter(
    withPresenter<LayoutDefaultPresenter, OwnProps & RouteComponentProps>(
      ({ history, match }, { business }) => new LayoutDefaultPresenter(business.user, business.interface),
      Component
    )
  )
);
