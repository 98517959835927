import { BaseModule } from "../module";
import { DefaultModalInteractor, ModalInteractor } from "./interactor/modal-interactor";
import DefaultDesignInteractor, { DesignInteractor } from "../data/design/design-interactor";
import DefaultUserInteractor, { UserInteractor } from "../data/user/user-interactor";
import DefaultNotificationInteractor, { NotificationInteractor } from "../data/notification/notification-interactor";
import { InterfaceInteractor, DefaultInterfaceInteractor } from "./interactor/interface-interactor";
import DefaultTechnologyInteractor, { TechnologyInteractor } from "../data/technology/technology-interactor";

export interface BusinessInteractors {
  user: UserInteractor;
  modal: ModalInteractor;
  design: DesignInteractor;
  technology: TechnologyInteractor;
  notification: NotificationInteractor;
  interface: InterfaceInteractor;
}

export interface BusinessModule extends BusinessInteractors {}

export class DefaultBusinessModule extends BaseModule<{}, BusinessInteractors, {}> implements BusinessModule {
  public get design(): DesignInteractor {
    return this.loadInteractor("design", DefaultDesignInteractor);
  }

  public get technology(): TechnologyInteractor {
    return this.loadInteractor("technology", DefaultTechnologyInteractor);
  }

  public get user(): UserInteractor {
    return this.loadInteractor("user", DefaultUserInteractor);
  }

  public get notification(): NotificationInteractor {
    return this.loadInteractor("notification", DefaultNotificationInteractor);
  }

  public get modal(): ModalInteractor {
    return this.loadInteractor("modal", DefaultModalInteractor);
  }

  public get interface(): InterfaceInteractor {
    return this.loadInteractor("interface", DefaultInterfaceInteractor);
  }
}
