import { AWSLexBot, MessageProps as AWSLexMessageProps } from "../../../../helpers/lex";
import { IPresenter } from "../../../../helpers/with-presenter";
import { observable } from "mobx";
import uuid from "uuid";
import { Message } from "./chatbot-item";

export class ChatbotPresenter implements IPresenter {
  private _bot: AWSLexBot;
  private _timeout: any = undefined;
  private _sessionTimeout: number = 5 * 60 * 1000;

  @observable
  public items: any = [];

  @observable
  public input = "";

  @observable
  public currentIndex: number = 0;

  @observable
  public activeTechnologies: any = [];

  @observable
  public status: string = "ready";

  @observable
  public errorMessage: string | undefined = undefined;

  constructor(private _onTimeout: () => void = () => {}) {
    this._bot = new AWSLexBot({
      name: <string>process.env.REACT_APP_LEX_BOT_NAME,
      alias: <string>process.env.REACT_APP_LEX_BOT_ALIAS,
      region: <string>process.env.REACT_APP_AUTH_USER_POOL_REGION
    });
  }

  public mount = () => {
    this.submitMessage({
      inputText: "Assist",
      sessionAttributes: {}
    });
  };

  public unmount = () => {
    clearTimeout(this._timeout);
  };

  public getMessages() {
    return this.items;
  }

  public getMessage(index: number) {
    const messages = this.getMessages();
    return messages[index];
  }

  public getMessageText(message: any) {
    return JSON.parse(message).messageText;
  }

  public getMessageEgu(message: any) {
    return JSON.parse(message).egu ? JSON.parse(message).egu : false;
  }

  public getMessageSkipValue(message: any) {
    return JSON.parse(message).skipValue ? JSON.parse(message).skipValue : false;
  }

  public getMessageInputFormat(message: any) {
    return JSON.parse(message).inputFormat ? JSON.parse(message).inputFormat : false;
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  public setMessages(message: any) {
    this.items = [...this.items, message];
  }

  public getInputvalues() {
    return this.input;
  }

  public setInputvalues(input: any) {
    this.input = input;
  }

  public getCurrentItem() {
    return this.items[this.currentIndex - 1];
  }

  public setAnwser() {
    this.items[this.currentIndex - 1].answer = this.input;
  }

  public onHandleKeypress = (e: any) => {
    if (e.key === "Enter") this.submitMessage();
  };

  public onHandleChange = (e: any) => {
    this.setInputvalues(e.target.value);
  };

  // used by buttons in response cards
  public onSubmit = (val: any, value: any, inputFormat: any) => {
    this.submitMessage({
      inputText: val
    });
    this.setInputvalues(val);
  };

  // create message func
  public createItem(response: any) {
    const item: Message = {
      id: uuid(),
      question: this.getMessageText(response.message),
      anwser: "",
      egu: this.getMessageEgu(response.message),
      skipValue: this.getMessageSkipValue(response.message),
      inputFormat: this.getMessageInputFormat(response.message)
    };

    if (response.responseCard) item.responseCard = response.responseCard;
    return item;
  }

  public validateValue(value: any) {
    const format = this.getCurrentItem().inputFormat;

    if (format == "decimal") {
      if (value.match(/^(\d+\.?\d*|\.\d+)$/)) {
        this.errorMessage = "";
        return true;
      } else {
        this.errorMessage = "Please use only numerics";
        return false;
      }
    }
    return true;
  }

  public async submitMessage(msgProps?: AWSLexMessageProps) {
    // VALIDATION
    let validator = true;
    if (this.input) {
      validator = this.validateValue(this.input);
    }

    if (validator) {
      if (msgProps && msgProps.inputText !== "") this.input = msgProps.inputText;

      const response: any = await this._bot.sendMessage({
        ...msgProps,
        inputText: this.input
      });

      if (response) {
        console.log("MESSAGE", response);
        this.setSessionTimeout();

        if (response.dialogState == "Failed") {
          this.status = "failed";
          switch (response.messageFormat) {
            case "CustomPayload":
              this.errorMessage = JSON.parse(response.message).messageText;
              break;
            default:
              this.errorMessage = response.message;
              break;
          }
          return;
        }

        this.setActiveTechnologies(response);
        this.NextQuestion(response);

        // DONE!! CHATBOT IS FINISHED
        if (response.dialogState === "Fulfilled") {
          this.status = "done";
          this.finalise(response);
          return;
        }
      }
    }
  }

  public setActiveTechnologies(response: any) {
    if (response.sessionAttributes.Technologies) {
      this.activeTechnologies = JSON.parse(response.sessionAttributes.Technologies).map((technology: string) => {
        return this.slugify(technology);
      });
    } else {
      this.activeTechnologies = [];
    }
  }

  public setSessionTimeout() {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(this._onTimeout, this._sessionTimeout);
  }

  public NextQuestion(response: any) {
    const item = this.createItem(response);
    this.setMessages(item);

    if (this.currentIndex !== 0) {
      this.status = "started";
      this.items[this.currentIndex - 1].anwser = this.items[this.currentIndex - 1].egu
        ? this.input + " " + this.items[this.currentIndex - 1].egu
        : this.input;
    }

    this.setInputvalues("");
    this.currentIndex++;
  }

  public finalise(response: any) {
    if (response.intentName === "SelectDesignTechnology") {
      const technologies = JSON.parse(response.sessionAttributes.Technologies);
      this.activeTechnologies[0] = this.slugify(technologies[0]);
    }
  }

  public slugify(slug: string) {
    return slug.toLowerCase().replace(/\s+/g, "-");
  }
}
