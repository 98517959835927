import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "airlift-skds",
  name: "AirLift MBR",
  image: "/images/technologies/AirLift-skds.jpg",
  oneliner: "Energy-efficient encased solution",
  techImage: ["/images/tech_images/AirLift/AirLift.png"],
  active: true,
  comparableTechnologies: ["crossflow", "airlift-skds", "airlift-mgb"],
  description:
    "The   technology   for   treating   industrial   and   municipal   wastewater   with   minimal   energyconsumption and is suitable for suspended solids levels of MLSS 8-15 g/l. This encased solutionoffers a small footprint, automated operation, and simple maintenance",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name", ["Partner5"]),
      setValue("opportunity_number", ["OP-5671234"]),
      setValue("project_name", ["Wastewater treatment"]),
      setValue("project_destination", ["Netherlands"]),
      setValue("designer", ["Bob van Es"]),
      setValue("approver", ["-"])
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameterss
      setValue("temperature_range", [15, 35])
    ]),
    ...createValuesFor("input.plant.capacity", [
      // Plant Capacity
      setValue("water_source", ["Wastewater"]),
      setValue("capacity", [400]),
      setValue("duration", [24])
    ]),

    ...createValuesFor("plant.configuration", [
      setValue("uf_type", ["AL30"]),
      setValue("total_units", [8]),
      setValue("membrane_elements", [30]),
      setValue("total_membrane_elements", [240]),
      setValue("spare_membrane_elements", [0]),
      setValue("membrane_element", ["COMPACT33V Helix"]),
      setValue("total_mebrane_area", [7920]),
      setValue("backwash_trains", [1])
    ]),
    ...createValuesFor("plant.configuration.image", [
      // setValue("airflush_flow"),
      setValue("avg_feed_flow"),
      setValue("feed_flow", [684]),
      setValue("airlift_flow", [300]),
      setValue("citric_acid", [2389]),
      setValue("net_permeate_flow", [53]),
      setValue("gross_permeate_flow", [59]),
      setValue("naocl_dosing_pump", [491]),

      setValue("drain_volume", [3.3]),
      setValue("permeate_pump", [75]),
      setValue("backwash_pumps", [297]),
      setValue("permeate_tank", [24]),
      setValue("drain_tank", [18]),
      setValue("drain_pump", [36]),
      setValue("concentrate_flow", [625])
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("gross_filtration_flux", [60]),
      setValue("filtration_time", [10]),
      setValue("ceb_frequency", [17]),
      setValue("drain_frequency", [6]),
      setValue("recovery", [89])
    ]),

    setValue("comment", ["true"]),
    setValue("nr_of_airlift_blowers", [3]),
    setValue("airlift_blower", [900]),
    setValue("nr_of_permeate_pumps", [8]),
    setValue("permeate_pump_flow", [75]),
    setValue("nr_of_backwash_pumps", [1]),
    setValue("backwash_pump_flow", [297]),
    setValue("nr_of_naocl_dosing", [1]),
    setValue("naocl_dosing_flow", [700]),
    setValue("nr_of_citric_acid_dosing", [1]),
    setValue("citric_acid_dosing_flow", [3000]),
    setValue("unit_type", ["AL30"]),
    setValue("nr_of_feed_pumps", [8])
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [18, -2],
      rotation: 0,
      width: 300,
      align: "left",
      values: [
        "uf_type",
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "membrane_element",
        "total_mebrane_area"
      ]
    },
    // {
    //   title: null,
    //   values: ["avg_feed_flow"],
    //   location: [4, 8],
    //   rotation: -5,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    {
      title: null,
      values: ["feed_flow"],
      location: [40, 42],
      rotation: 0,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["gross_permeate_flow"],
      location: [80, 3],
      rotation: 0,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "NaOCL CEB Flow",
      values: ["naocl_dosing_pump"],
      location: [74, 24],
      rotation: -45,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: "Citric acid CEB Flow",
      values: ["citric_acid"],
      location: [68, 25],
      rotation: -45,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: null,
      values: ["net_permeate_flow"],
      location: [93, 6],
      rotation: -9,
      width: 90,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["airlift_flow"],
      location: [58, 62],
      rotation: 0,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_volume"],
      location: [69, 56],
      rotation: 30,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["permeate_pump"],
      location: [75, 52],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["backwash_pumps"],
      location: [85, 49],
      rotation: 0,
      width: 160,

      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["permeate_tank"],
      location: [92.5, 25],
      rotation: 0,
      width: 90,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_tank"],
      location: [77, 66],
      rotation: 0,
      width: 160,

      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_pump"],
      location: [72, 79],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["concentrate_flow"],
      location: [37, 21],
      rotation: -6,
      width: 160,

      align: "left",
      titleVariant: "small"
    }
  ]
} as TechnologyData;
