import { observer } from "mobx-react";
import * as React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { routes, checkRedirect } from "../config/routes";
import ScrollToTop from "../helpers/scroll-to-top";
import AuthTheme from "./theme/auth";
import {
  withAuthenticator,
  SignIn,
  RequireNewPassword,
  VerifyContact,
  ConfirmSignIn,
  ForgotPassword
} from "aws-amplify-react"; // or 'aws-amplify-react-native';
import { Redirect } from "react-router";

const AuthRoute = withAuthenticator(
  Route,
  false,
  [<SignIn />, <ConfirmSignIn />, <RequireNewPassword />, <ForgotPassword />, <VerifyContact />],
  null,
  AuthTheme
);

export const Routes = observer(() => {
  const redirection = checkRedirect();
  return redirection ? (
    <Redirect to={redirection.to} />
  ) : (
    <ScrollToTop>
      <Switch>
        {routes.map(route =>
          route.public ? (
            <Route key={route.path} exact path={route.path} component={route.component} />
          ) : (
            <AuthRoute key={route.path} exact path={route.path} component={route.component} />
          )
        )}
      </Switch>
    </ScrollToTop>
  );
});

export default withRouter(Routes);
