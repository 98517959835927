import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { computed } from "mobx";
import { InterfaceInteractor } from "../../application/business/interactor/interface-interactor";

export class LayoutDefaultPresenter implements IPresenter {
  @computed public get user() {
    return this._userInteractor.currentUser;
  }

  @computed public get interface() {
    return this._interfaceInteractor;
  }

  public mount() {
    //
  }
  public unmount() {
    //
  }

  public logout = () => {
    if (this.user) {
      this._userInteractor.logout();
    }
  };

  constructor(private _userInteractor: UserInteractor, private _interfaceInteractor: InterfaceInteractor) {}
}
