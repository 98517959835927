import { BusinessModule, DefaultBusinessModule } from './business/business';
import { BaseModule } from './module';
import { DefaultNetworkModule, NetworkModule } from './network/network';

export interface PentairApp {
  network: NetworkModule;
  business: BusinessModule;
}

class DefaultApplication
  extends BaseModule<
    {},
    {},
    {
      network: NetworkModule;
      business: BusinessModule;
    }
  >
  implements PentairApp {
  public get network() {
    return this.loadModule("network", DefaultNetworkModule);
  }

  public get business() {
    return this.loadModule("business", DefaultBusinessModule);
  }
}

export const App = new DefaultApplication();
