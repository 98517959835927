import { TechnologyFragment } from "./fragments";

export const TechnologyCreated = `
subscription {
  designCreated {
    ${TechnologyFragment}
  }
}
`;

export const TechnologyUpdated = `
subscription {
  designUpdated {
    ${TechnologyFragment}
  }
}
`;

export const TechnologyDeleted = `
subscription {
  designDeleted {
    ${TechnologyFragment}
  }
}
`;
