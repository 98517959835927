import { createMuiTheme } from "@material-ui/core";
import { ThemeOptions, Theme } from "@material-ui/core/styles/createMuiTheme";

import {
  PentairBlue,
  PentairGreen,
  PentairImpactGreen,
  PentairWhite,
  PentairDarkGray,
  PentairSilverGray,
  PentairRed,
  PentairDarkGold,
  PentairSoftGray,
  PentairBackground,
  PentairSeafoamGreen,
  PentairBrightBlue,
  PentairKhaki
} from "./colors/_all";
import { themeConfig, typography, typographyMapping } from "./config";
import { MuiButton, MuiFab, MuiIconButton, MuiLink, MuiSwitch } from "./overrides/button";
import { MuiExpansionPanel, MuiExpansionPanelDetails, MuiExpansionPanelSummary } from "./overrides/expantion-panel";
import {
  MuiCheckbox,
  MuiFormLabel,
  MuiInput,
  MuiInputBase,
  MuiInputLabel,
  MuiRadio,
  MuiSelect
} from "./overrides/form";
import { MuiList, MuiListItem, MuiMenuItem } from "./overrides/list";
import { MuiTable, MuiTableCell, MuiTableHead, MuiTableRow } from "./overrides/table";
import { MuiTooltip } from "./overrides/tooltip";
import { MuiInputAdornment } from "./overrides/adornment";
import { MuiFormHelperText } from "./overrides/form-helper-text";

const defaultTheme = createMuiTheme();

function createPentairTheme(options: ThemeOptions): Theme {
  return createMuiTheme({
    spacing: themeConfig.spacing,
    typography: {
      fontFamily: "Barlow",
      allVariants: {
        "& strong": {
          color: "inherit"
        }
      },
      body1: {
        ...typography("body1")
      },
      body2: {
        ...typography("body2")
      },
      h1: {
        ...typography("h1")
      },
      h2: {
        ...typography("h2")
      },
      h3: {
        ...typography("h3")
      },
      h4: {
        ...typography("h4")
      },
      h5: {
        ...typography("h5")
      },
      h6: {
        ...typography("h6")
      },
      h1_condensed: {
        ...typography("h1_condensed")
      },
      h2_condensed: {
        ...typography("h2_condensed")
      },
      h3_condensed: {
        ...typography("h3_condensed")
      },
      h4_condensed: {
        ...typography("h4_condensed")
      },
      h5_condensed: {
        ...typography("h5_condensed")
      },
      h6_condensed: {
        ...typography("h6_condensed")
      },
      button: { ...typography("button") }
    },
    palette: {
      primary: {
        main: PentairBlue["500"]
      },
      secondary: {
        main: PentairGreen["500"]
      },
      white: {
        main: PentairWhite["500"]
      },
      background: {
        default: PentairBackground["500"]
      },
      darkGray: {
        main: PentairDarkGray["500"]
      },
      silverGray: {
        main: PentairSilverGray["500"]
      },
      softGray: {
        main: PentairSoftGray["500"]
      },
      darkGold: {
        main: PentairDarkGold["500"]
      },
      error: {
        main: PentairRed["500"]
      },
      impactGreen: {
        main: PentairImpactGreen["500"]
      },
      seafoamGreen: {
        main: PentairSeafoamGreen["500"]
      },
      green: {
        main: PentairGreen["500"]
      },
      brightBlue: {
        main: PentairBrightBlue["500"]
      },
      blue: {
        main: PentairBlue["500"]
      },
      khaki: {
        main: PentairKhaki["500"]
      }
    },
    shape: {
      borderRadius: 0
    },
    props: {
      MuiCard: {
        elevation: 0
      },
      MuiButton: {
        variant: "contained",
        color: "primary"
      },
      MuiExpansionPanel: {
        elevation: 0
      },
      MuiInputLabel: {
        shrink: true
      },
      MuiTypography: {
        variantMapping: { ...typographyMapping }
      }
    },
    overrides: {
      MuiTypography: {
        ["h1_condensed" as "h1"]: {
          ...typography("h1_condensed")
        },
        ["h2_condensed" as "h2"]: {
          ...typography("h2_condensed")
        },
        ["h3_condensed" as "h3"]: {
          ...typography("h3_condensed")
        },
        ["h4_condensed" as "h4"]: {
          ...typography("h4_condensed")
        },
        ["h5_condensed" as "h5"]: {
          ...typography("h5_condensed")
        },
        ["h6_condensed" as "h6"]: {
          ...typography("h6_condensed")
        },
        paragraph: {
          marginBottom: defaultTheme.spacing(2)
        }
      },
      MuiButton,
      MuiIconButton,
      MuiExpansionPanel,
      MuiExpansionPanelSummary,
      MuiExpansionPanelDetails,
      MuiFormLabel,
      MuiInput,
      MuiInputBase,
      MuiFormHelperText,
      MuiInputLabel,
      MuiSelect,
      MuiList,
      MuiListItem,
      MuiMenuItem,
      MuiRadio,
      MuiCheckbox,
      MuiLink,
      MuiTable,
      MuiTableHead,
      MuiTableCell,
      MuiTableRow,
      MuiFab,
      MuiTooltip,
      MuiInputAdornment,
      MuiSwitch
    },
    ...options
  });
}

export const theme = createPentairTheme({});
