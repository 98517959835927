import { makeExecutableSchema, mockServer, MockList } from "graphql-tools";

import { loader } from "graphql.macro";
import uuid from "uuid";
import { designResolver, QueryDesignResolver } from "./data/design";
import { QueryUserResolver, QueryUserCollectionResolver, QueryUserDesignResolver } from "./data/user";
import { technologyResolver, QueryTechnologyResolver } from "./data/technology";

export const schema = makeExecutableSchema({
  typeDefs: loader("./schema.graphql"),
  resolverValidationOptions: {
    requireResolversForResolveType: false
  }
});

export const server = mockServer(schema, {
  ID: () => uuid(),

  Design: () => {
    return {
      designs: designResolver
    };
  },

  User: () => {
    return {
      designs: QueryUserDesignResolver,
      notifications: () => new MockList([10, 20]),
      username: "Mathijs",
      lastname: "Jansen"
    };
  },

  CalculationValue: () => ({
    error: "test"
  }),

  Query: () => ({
    Design: QueryDesignResolver,
    DesignCollection: designResolver,
    Technology: QueryTechnologyResolver,
    TechnologyCollection: technologyResolver,
    User: QueryUserResolver,
    UserDesigns: QueryUserDesignResolver,
    UserCollection: QueryUserCollectionResolver
  })
});
