import React from "react";

import { Table, TableBody, TableCell, TableRow, Theme, Typography, TableHead, TextField } from "@material-ui/core";

import { spacing, typographyVariants } from "../../theme/config";
import { CalculationValue } from "../../../application/data/technology/technology";
import { Tooltip } from "../mui-extended/mui-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapEvent, nodeValue } from "../../../helpers/formatters";
import { observer } from "mobx-react";
import { withStyles, StyleRules, WithStyles } from "@material-ui/styles";

interface RowConfig extends CalculationValue {
  space?: number;
}

interface OwnProps extends WithStyles<"tableCell" | "error" | "name" | "value" | "range" | "unit" | "tableCellValue"> {
  title?: string;
  values: RowConfig[];
  hideSuggestedValue?: boolean;
  onValueChange?(calculationValue: string, valueIndex: number, value: any): void;
}

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    "&:last-child": {
      paddingRight: 0
    }
  },
  tableValueCell: { fontWeight: 400 },

  error: {
    color: theme.palette["error"].main,
    maxWidth: theme.spacing(4),
    textAlign: "center",
    fontSize: 18
  },
  name: { width: theme.spacing(15) },
  value: { width: theme.spacing(10) },
  range: { width: theme.spacing(21) },
  unit: {}
});

export const ValuesTableForm = withStyles(styles)(
  observer(({ classes, title, values, onValueChange }: OwnProps) => {
    return (
      <React.Fragment>
        {title && (
          <Typography variant='h5' paragraph>
            {title}
          </Typography>
        )}
        <Table style={{ fontSize: "0.825em" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Parameter</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>EGU/Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(values || []).map((row, index) =>
              row.space ? (
                <div key={index} style={{ height: spacing(row.space) }} />
              ) : (
                <TableRow key={index}>
                  <TableCell padding={"checkbox"} className={classes.error}>
                    {row.error && (
                      <Tooltip title={row.error || ""}>
                        <FontAwesomeIcon icon={["fal", "exclamation-circle"]} size={"1x"} />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className={classes.name}>
                    <Typography variant={typographyVariants.body2}>{row.name}</Typography>
                  </TableCell>

                  {row.values && (
                    <TableCell className={row.values.length < 2 ? classes.value : classes.range}>
                      {row.values.map((val, index) => (
                        <TextField
                          key={row.id}
                          onChange={mapEvent(onValueChange, row.id || "", index, nodeValue)}
                          value={val || ""}
                          style={{ width: spacing(8), marginRight: spacing(1) }}
                        />
                      ))}
                    </TableCell>
                  )}

                  <TableCell className={classes.unit}>
                    <Typography variant={typographyVariants.body2}>{row.unit}</Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  })
);
