import * as React from "react";
import TopBar from "./topbar";
import { ToolBar } from "./toolbar";
import { InterfacePresenter } from "./default-interface-presenter";
import { withStyles, WithStyles } from "@material-ui/core";
import { withPresenter, PresenterProps } from "../../../helpers/with-presenter";
import { observer } from "mobx-react";
import { DrawerWrap } from "../drawers/wrap";

interface OwnProps {}

const Component = withStyles({
  root: {
    position: "fixed",
    display: "flex",
    alignItems: "flex-start",
    left: 0,
    right: 0,
    top: 0,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "auto"
    }
  },
  drawerWrap: {
    background: "#fff"
  }
})(
  observer(({ presenter, classes }: OwnProps & WithStyles & PresenterProps<InterfacePresenter>) => {
    // console.log("render interface");
    return (
      <div className={classes.root}>
        <ToolBar onToolSelect={presenter.toggleTool} active={presenter.activeTool} />
        <div className={classes.drawerWrap}>
          <DrawerWrap {...presenter.config[presenter.activeTool]}>
            {presenter.config[presenter.activeTool].render(presenter.data, presenter.actions)}
          </DrawerWrap>
        </div>
        <TopBar user={presenter.user} onLogout={presenter.logout} />
      </div>
    );
  })
);

export const DefaultInterface = withPresenter<InterfacePresenter, OwnProps>(
  (props, { business }) => new InterfacePresenter(business.user, business.interface),
  Component
);
