import { PageCreate } from "../view/pages/design-create";
import { PageHome } from "../view/pages/home";
// import { Disclaimer } from "../view/pages/disclaimer";
import { PageDesignInputs } from "../view/pages/design-inputs";
import { Error404 } from "../view/pages/error-404";
// import { PageManage } from "../view/pages/manage";
import { PageResults } from "../view/pages/design-results";
// import { PageSettings } from "../view/pages/settings";
import { PageAssistant } from "../view/pages/design-assistant";
import { PageSelectTechnology } from "../view/pages/select-technology";
import { PageAssets } from "../view/pages/theme-assets";
import { PageLandingPage } from "../view/pages/landingpage";
import { generatePath, RouteComponentProps } from "react-router";
import { PageCompareTechnologies } from "../view/pages/compare-technologies";
import { PageDesignOverview } from "../view/pages/design-overview";
import { PageTechANMBR } from "../view/pages/tmp-technology-anmbr";
import { PageTechCrossflowUF } from "../view/pages/tmp-technology-crossflow-uf";
import { PageUserOverview } from "../view/pages/user-overview";

export interface IRoute {
  path: string;
  title: string;
  component: any;
  mainmenu?: boolean;
  disabled?: boolean;
  public?: boolean;
  redirect?: string;
}

export const redirections = [{ from: "/demo", to: "/demo/dashboard" }];

export const routeMap = {
  homepage: {
    path: "/",
    title: "Landingspage",
    component: PageLandingPage,
    public: true
  },

  "app.technology.crossflow-uf": {
    path: "/technology/crossflow-uf",
    title: "Xpert Assistant",
    component: PageTechCrossflowUF,
    mainmenu: false
  },
  "app.technology.anmbr": {
    path: "/technology/anmbr",
    title: "Xpert Assistant",
    component: PageTechANMBR,
    mainmenu: false
  },
  "app.user.dashboard": {
    path: "/demo/dashboard",
    title: "Xpert Assistant",
    component: PageHome,
    mainmenu: true
  },
  "app.user.overview": {
    path: "/demo/users",
    title: "Users overview",
    component: PageUserOverview,
    mainmenu: true
  },
  "app.design.assistant": {
    path: "/demo/design/assistant",
    title: "assistent",
    component: PageAssistant
  },
  "app.design.overview": {
    path: "/demo/design",
    title: "Design overview",
    component: PageDesignOverview,
    mainmenu: true
  },
  "app.design.create": {
    path: "/demo/design/create",
    title: "Create",
    component: PageCreate,
    disabled: true
  },
  "app.design.select.technology": {
    path: "/demo/design/create/select-technology",
    title: "Select Technology",
    component: PageSelectTechnology,
    disabled: true
  },
  "app.design.details": {
    path: "/demo/design/:design/details",
    title: "Details",
    component: Error404,
    disabled: true
  },
  "app.design.inputs": {
    path: "/demo/design/:design/inputs",
    title: "Edit",
    component: PageDesignInputs,
    disabled: true
  },
  "app.compare.technologies": {
    path: "/demo/compare/technologies",
    title: "Compare Technologies",
    component: PageCompareTechnologies,
    disabled: true
  },
  "app.design.results": {
    path: "/demo/design/:design/results",
    title: "Results",
    component: PageResults
  },
  "app.design.notifications": {
    path: "/demo/design/:design/notifications",
    title: "Notifications",
    component: Error404
  },
  "app.design.warranties": {
    path: "/demo/design/:design/warranties",
    title: "Warranties",
    component: Error404
  },
  "app.design.quotations": {
    path: "/demo/design/:design/quotations",
    title: "Quotations",
    component: Error404
  },
  "app.disclaimer": {
    path: "/app/disclaimer",
    title: "Disclaimer",
    component: Error404
  },
  "app.terms.service": {
    path: "/app/terms-of-service",
    title: "Terms of service",
    component: Error404
  },
  "app.privacy.policy": {
    path: "/app/privacy-policy",
    title: "Privacy policy",
    component: Error404
  },
  "docs.assets": {
    path: "/assets",
    title: "Theme Assets",
    component: PageAssets
  },
  refresh: {
    path: "/refresh",
    title: "404 - Resource not found",
    component: ({ history }: RouteComponentProps) => {
      history.goBack();

      return "";
    },
    public: true
  },
  "error.404": {
    path: "*",
    title: "404 - Resource not found",
    component: Error404,
    public: true
  }
};

export type RouteNames = keyof typeof routeMap;

export const route = (
  name: RouteNames,
  args?: Record<string, string | boolean | number>
) => {
  return generatePath(routeMap[name].path, args);
};

export const routes: IRoute[] = Object.keys(routeMap).map((name: string) => {
  return routeMap[name];
});

export const checkRedirect = () =>
  redirections.filter(
    ({ from }) => from === window.location.pathname.replace(/\/$/, "")
  )[0];
