import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "anmbr",
  name: "AnMBR",
  oneliner: "",
  image: "/images/technologies/AnMBR.jpg",
  techImage: ["/images/tech_images/Megablock/Megablock.png"],
  active: true,
  comparableTechnologies: [],
  description:
    "Ut sagittis convallis mi at dictum. In eleifend tempus lacus tempus aliquam. Aliquam vestibulum enim eu nibh placerat pretium. Sed convallis nisl tellus, ut molestie sapien sodales ultrices. Donec tellus magna, congue eu venenatis nec, sagittis ut justo.",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name"),
      setValue("opportunity_number"),
      setValue("project_name", ["ANMBR water solution"]),
      setValue("project_destination"),
      setValue("designer"),
      setValue("approver")
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameters
      setValue("temperature_range"),
      setValue("ph_range"),
      setValue("calcium"),
      setValue("ammonia"),
      setValue("phosphorus")
    ]),
    ...createValuesFor("input.water.main", [
      // Main water parameters
      setValue("design_turbidity"),
      setValue("temperature"),
      setValue("turbidity"),
      setValue("tss"),
      setValue("toc"),
      setValue("cod"),
      setValue("color"),
      setValue("alkanity"),
      setValue("ph"),
      setValue("tds"),
      setValue("hardness")
    ]),
    ...createValuesFor("input.plant.capacity", [
      // Plant Capacity
      setValue("water_source"),
      setValue("capacity_base"),
      setValue("capacity"),
      setValue("temperature", [20])
    ]),
    ...createValuesFor("input.plant.membrane", [
      //
      setValue("membrane")
    ]),
    ...createValuesFor("plant.configuration", [
      setValue("total_units"),
      setValue("membrane_elements"),
      setValue("total_membrane_elements"),
      setValue("spare_membrane_elements"),
      setValue("membrane_element"),
      setValue("total_mebrane_area"),
      setValue("backwash_trains")
    ]),
    ...createValuesFor("plant.configuration.image", [
      setValue("airflush_flow"),
      setValue("avg_feed_flow"),
      setValue("circulation_pump"),
      setValue("fecl3_dosing_pump"),
      setValue("feed_pumps"),
      setValue("backwash_pumps"),
      setValue("net_permeate_flow")
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("gross_filtration_flux"),
      setValue("filtration_time"),
      setValue("net_filtration_flux"),
      setValue("avg_ceb_1_counter"),
      setValue("avg_ceb_2_counter"),
      setValue("ceb_1_counter"),
      setValue("ceb_2_counter")
    ])
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [10, 5],
      rotation: 0,
      values: [
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "spare_membrane_elements",
        "membrane_element",
        "total_mebrane_area",
        "backwash_trains"
      ]
    },
    {
      values: ["airflush_flow"],
      titleVariant: "small",
      location: [10, 5],
      rotation: -18
    },
    {
      values: ["avg_feed_flow"],
      titleVariant: "small",
      location: [48, 87],
      rotation: -19
    },
    {
      values: ["circulation_pump"],
      titleVariant: "small",
      location: [48, 76],
      rotation: 0
    },
    {
      values: ["fecl3_dosing_pump"],
      titleVariant: "small",
      location: [62.5, 77],
      rotation: 0
    },
    {
      values: ["feed_pumps"],
      titleVariant: "small",
      location: [75, 70],
      rotation: 0
    },
    {
      values: ["backwash_pumps"],
      titleVariant: "small",
      location: [75, 36],
      rotation: 0
    },
    {
      values: ["net_permeate_flow"],
      titleVariant: "small",
      location: [95, 10],
      rotation: 10
    },
    {
      title: "Concentrate",
      values: [
        "total_concentrate_flow",
        "avg_forward_flush_flow",
        "avg_backwash_flow",
        "avg_ceb_1_flow",
        "avg_ceb_2_flow"
      ],
      titleVariant: "small",
      location: [0, 10],
      rotation: 0
    }
  ]
} as TechnologyData;
