import ConfirmationModalPresenter from "../../../view/content/modal/confirm/confirm-modal-presenter";

export interface ModalPresenter {
  active: boolean;
  open(...args: any): void;
  close(): void;
}

interface AppModals {
  confirm: ConfirmationModalPresenter;
}

export interface ModalInteractor extends AppModals {}

export class DefaultModalInteractor implements ModalInteractor {
  private _modals: AppModals = {
    confirm: new ConfirmationModalPresenter()
  };

  public get confirm(): ConfirmationModalPresenter {
    return this._modals.confirm;
  }
}
