import { observable } from "mobx";
import { BaseModule } from "../../module";
import DefaultNotificationProvider from "./notification-provider";
import { Notification } from "./notification";

export interface NotificationInteractor {
  provider: DefaultNotificationProvider;
}

export default class DefaultNotificationInteractor extends BaseModule<any, any, any> implements NotificationInteractor {
  @observable public _authNotification: Notification | undefined = undefined;

  public get provider() {
    return this.loadProvider("interactor", DefaultNotificationProvider);
  }
}
