import * as React from "react";
import { WithStyles, withStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { InterfaceData, InterfaceActions } from "../partials/default-interface-presenter";

interface OwnProps extends WithStyles {
  active: boolean;
  width: number;
  title: string;
  render(data: InterfaceData, actions: InterfaceActions): React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}

export const DrawerWrap = withStyles({
  root: {
    width: 0,
    overflowY: "auto",
    height: "100vh",
    transition: "width 0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 48
  },
  titleBar: {
    zIndex: 0,
    position: "fixed",
    top: 0,
    height: 48,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
    background: "#fff",
    transition: "width 0.3s"
  },
  inner: {
    padding: "18px 24px"
  }
})(
  observer(({ classes, active, title, width, children }: OwnProps) => {
    return (
      <div
        className={classes.root}
        style={{
          width: width || 0
        }}
      >
        <div
          className={classes.titleBar}
          style={{
            width: width || 0
          }}
        >
          <Typography variant="h3">{title}</Typography>
        </div>
        <div
          className={classes.inner}
          style={{
            width: width
          }}
        >
          {children}
        </div>
      </div>
    );
  })
);
