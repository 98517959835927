import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { AuthPagePresenter } from "./_base-auth-page-presenter";
import { computed, observable, toJS } from "mobx";
import { User, UserData, UserModel } from "../../application/data/user/user";

export class UserPresenter extends AuthPagePresenter implements IPresenter {
  @observable public selectedUser: User | null = null;
  @observable public createValues: UserData = {
    username: "",
    attributes: {}
  } as UserData;

  constructor(
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps<{ design?: string }>
  ) {
    super(_userInteractor, _router);

    this.load();
  }

  public load = async () => {
    // this.loading = true;
    // await this._designInteractor.load();
    // this._designInteractor.select(this._router.match.params.design || "");
    // this.loading = false;
  };

  public mount = () => {};

  @computed public get allUsers() {
    return this._userInteractor.allUsers;
  }

  public createUser = (data: UserData) => {
    this._userInteractor.create(toJS(data), true);
    this.resetForm();
  };

  public updateUser = (data: UserData) => {
    if (this.selectedUser) {
      this.selectedUser.updateProperty("attributes", data.attributes);
      this.selectedUser.update();
      this.resetForm();
    }
  };

  public unmount = () => {
    //
  };

  public selectUser = (id: string) => {
    this.selectedUser = UserModel.get(id);
  };

  public resetForm = () => {
    this.selectedUser = null;
    this.createValues = {
      username: "",
      attributes: {}
    } as UserData;
  };
}
