import * as React from "react";

import { Typography, Button, Grid } from "@material-ui/core";

import ContentSection from "../content/components/content-section";
import { typographyVariants } from "../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PagePresenter } from "./_base-page-presenter";
import { RouteComponentProps } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";

const Component = ({ presenter }: RouteComponentProps & PresenterProps<PagePresenter>) => (
  <Layout backgroundColor='#fff'>
    <ContentSection
      align='center'
      justify='center'
      style={{
        flex: 1
      }}
    >
      <Grid container spacing={2} style={{ maxWidth: 600, display: "flex" }}>
        <Grid item xs={12}>
          <Typography variant={typographyVariants.h1} gutterBottom align={"center"}>
            Crossflow UF
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src='/images/crossflow-uf.jpg' width='100%' />
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph variant={typographyVariants.body1}>
            Crossflow UF is a unique membrane concept to treat wastewater, such asproduced water, with oily content.
            With carefully selected partners, Pentairdevelops innovative and ready-to-use membrane installations that
            meet bothregulatory and financial KPIs. The Pentair scope consists of the modular skidconcept, X-Flow
            tubular membranes, engineering, commissioning and processoptimization
            <br />
          </Typography>
          <Typography paragraph variant={typographyVariants.body1}>
            Crossflow UF is used in applications such as water injection and re-injection,steam assisted gravity
            drainage (SAGD) and Enhanced Oil Recovery (EOR) orChemical Enhanced Oil Recovery (CEOR).The robustly
            designed Crossflow XL skids are available in four sizes: 1x8modules, 2x8 modules, 3x8 modules and 4x8
            modules, with performancesranging respectively from approximately 50 to 100, 150 and 250 m3/h.For more
            details, please contact your Pentair Account Manager.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={presenter.back}
            variant='text'
            startIcon={<FontAwesomeIcon icon={["fal", "arrow-left"]} size='sm' />}
          >
            Previous page
          </Button>
        </Grid>
      </Grid>
    </ContentSection>
  </Layout>
);

export const PageTechCrossflowUF = withPresenter<PagePresenter, RouteComponentProps>(
  props => new PagePresenter(props),
  Component
);
