import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";
import { Notification } from "../notification/notification";
import { User } from "../user/user";

import { App } from "../../App";
import { data } from "../../network/decorators/graphql-data";

import { computed } from "mobx";
import { Technology, CalculationValue } from "../technology/technology";
import { CalculationValueOptions } from "../__mock__/calculation-values";

export interface DesignData {
  id: string;
  version: string;
  status: string;
  type: string;
  code: string;
  country: string;
  company: string;
  pdf: string;
  project: string;
  proposalNr: string;
  case: string;
  units: string;
  notifications: Notification[];
  owner: User;
  approver: User;
  users: User[];
  values: Array<any>;
}

export interface Design extends GraphQLModel<DesignData>, DesignData {
  submit(): void;
  approve(): void;
  download(): void;
  addUser(user: User, store?: boolean): Promise<Design>;
  requestApproval(): void;
  technology: Technology | undefined;
  categorizedValues: Record<string, CalculationValue[]>;
  valueMap: Record<CalculationValueOptions, CalculationValue>;
  updateValue(id: string, valueIndex: number, value: string): void;
  value(id: CalculationValueOptions): any;
}

export interface DesignValues {}

export class DesignModel extends GraphQLBase<DesignData>
  implements GraphQLModel<DesignData> {
  public typename: string = "Design";
  public useMock: boolean = true;

  @data public values: CalculationValue[] = [];
  @data public version = "0.1";
  @data public status = "Concept";
  @data public type = "";
  @data public project = "";
  @data public proposalNr = "";
  @data public case = "";
  @data public company = "";
  @data public pdf = "";
  @data public units = "metric";
  @data public country = "NL";
  @data public owner = {} as User;
  @data public approver = {} as User;
  @data public notifications = [];
  @data public users = [];

  // @hasone(designOwnerOptions) public owner = {} as User;
  // @hasone(designApproverOptions) public approver = {} as User;
  // @hasmany(designNotificationOptions) public notifications = [];
  // @hasmany(designUsersOptions) public users: User[] = [];

  public get code() {
    return this.id.substr(0, 8).toUpperCase();
  }

  public submit = (): void => {
    this.update();
  };

  public serialize = (data: DesignData) => {
    data = data || this.provider.serialize(this);
    delete data.owner;
    delete data.approver;
    delete data.users;
    delete data.pdf;
    delete data.notifications;
    return data;
  };

  public addUser = async (user: User, store: boolean = true) => {
    // const result = await API.graphql(graphqlOperation(DesignAddUser, { id: this.id, userID: user.id }));
    // const data = result.data.designAddUser;
    // this.provider.updateRecord(data.id, data);
    // const usr: User = UserModel.get(data.id);

    // this.users = this.users.filter(item => {
    //   return item.id !== usr.id;
    // });

    return this;
  };

  public get technology() {
    return App.business.technology.find(this.id);
  }

  public requestApproval = async () => {
    // const result = await this.query(graphqlOperation(DesignRequestApproval, { id: this.id }), "designRequestApproval");
    this.update();
  };

  public approve = () => {};

  public download = () => {};

  public get provider() {
    return App.business.design.provider;
  }

  public updateValue = (id: string, valueIndex: number, value: string) => {
    const calcVal = this.values.find(val => val.id === id);

    if (calcVal) {
      const values = [...(calcVal.values || [])];
      values[valueIndex] = value;

      calcVal.values = values;
      this.valueMap[id].values = values;
    }
  };

  public value = (id: CalculationValueOptions) => {
    return {
      name: "",
      unit: "",
      values: [],
      recommendedValues: [],
      ...this.valueMap[id]
    };
  };

  @computed public get categorizedValues() {
    return this.values.reduce(
      (
        result: Record<string, [CalculationValue]>,
        valueData: CalculationValue
      ) => {
        if (valueData.category) {
          result[valueData.category] = result[valueData.category] || [];
          result[valueData.category].push(valueData);
        }
        return result;
      },
      {}
    );
  }

  @computed public get valueMap() {
    return this.values.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }
}
