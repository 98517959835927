import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { PagePresenter } from "./_base-page-presenter";
import ContentSection from "../content/components/content-section";
import { typographyVariants } from "../theme/config";
import { withStyles, WithStyles, StyleRules } from "@material-ui/styles";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { route } from "../../config/routes";

type OwnProps = RouteComponentProps &
  PresenterProps<PagePresenter> &
  WithStyles<"root" | "headline1" | "headline2" | "headline3" | "logo">;

const styles = (theme: Theme): StyleRules => ({
  root: {},
  logo: {
    position: "absolute",
    top: theme.spacing(2)
  },
  headline1: {
    color: "#fff",
    lineHeight: 1,
    textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
    [theme.breakpoints.up("md")]: {
      fontSize: "4vw"
    },
    [theme.breakpoints.down("sm")]: {
      "& br": {
        display: "none"
      }
    }
  },
  headline2: {
    color: "#fff",
    lineHeight: 1,
    textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
    [theme.breakpoints.up("md")]: {
      fontSize: "2vw"
    },
    [theme.breakpoints.down("sm")]: {
      "& br": {
        display: "none"
      }
    }
  },
  headline3: {
    color: "#fff",
    lineHeight: 1.25,
    marginTop: theme.spacing(2),
    textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2vw"
    },
    [theme.breakpoints.down("sm")]: {
      "& br": {
        display: "none"
      }
    }
  }
});

const Component = withStyles(
  styles,
  {}
)(
  observer(({ classes }: OwnProps) => (
    <ContentSection
      align="center"
      justify="center"
      style={{
        height: "100vh",
        background: "url('/images/landingpage.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <img src={"/images/logo.svg"} height={"42"} className={classes.logo} />

      <Typography
        variant={typographyVariants.h1}
        align="center"
        gutterBottom
        className={classes.headline1}
      >
        XPERT
      </Typography>

      <Typography
        variant={typographyVariants.h2}
        align="center"
        gutterBottom
        className={classes.headline2}
      >
        The all-new interactive web app for water and wastewater
      </Typography>

      <Typography
        variant={typographyVariants.h3}
        align="center"
        className={classes.headline3}
      >
        For more details: contact your Pentair account manager
      </Typography>
    </ContentSection>
  ))
);

export const PageLandingPage = withRouter(
  withPresenter<PagePresenter, RouteComponentProps>(
    (props, { business }) => new PagePresenter(props),
    Component
  )
);
