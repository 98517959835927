import classnames from "classnames";
import React from "react";

import { makeStyles, Theme } from "@material-ui/core";

import {
  PentairBlue,
  PentairBrightBlue,
  PentairDarkGray,
  PentairGreen,
  PentairLightGray
} from "../../theme/colors/_all";

interface ContainerSpacing {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface OwnProps {
  children: React.ReactElement | React.ReactElement[];
  spacing?: ContainerSpacing;
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
  variant?: "default" | "primary" | "secondary" | "lightgray" | "darkgray" | "brightblue";
  style?: React.CSSProperties;
}
const useStyles = (props: OwnProps) => {
  const spacing = props.spacing || {};
  const display = props.align || props.justify ? "flex" : "block";

  const containerStyles: React.CSSProperties = { display, flexDirection: "column" };

  if (props.align) {
    containerStyles.alignItems = props.align;
  }

  if (props.justify) {
    containerStyles.justifyContent = props.justify;
  }

  const padding: [number, number, number, number] = [
    spacing.top === undefined ? 2 : spacing.top,
    spacing.right === undefined ? 2 : spacing.right,
    spacing.bottom === undefined ? 2 : spacing.bottom,
    spacing.left === undefined ? 2 : spacing.left
  ];

  const mobilePadding: [number, number, number, number] = [
    padding[0] ? padding[0] * 0.75 : 0,
    padding[1] ? padding[1] * 0.5 : 0,
    padding[2] ? padding[2] * 0.75 : 0,
    padding[3] ? padding[3] * 0.5 : 0
  ];

  return makeStyles((theme: Theme) => ({
    section: {
      margin: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...props.style
    },
    container: {
      flex: "1",
      maxWidth: 1400,
      padding: `${theme.spacing(...padding)}`,
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(...mobilePadding)}`
      },
      ...containerStyles
    },
    default: {
      backgroundColor: "#fff",
      color: PentairDarkGray[500]
    },
    lightgray: {
      backgroundColor: PentairLightGray[500],
      color: PentairDarkGray[500]
    },
    darkgray: {
      backgroundColor: PentairDarkGray[500],
      color: PentairLightGray[500]
    },
    primary: {
      backgroundColor: PentairBlue[500],
      color: theme.palette.getContrastText(PentairBlue[500])
    },
    secondary: {
      backgroundColor: PentairGreen[500],
      color: theme.palette.getContrastText(PentairGreen[500])
    },
    brightblue: {
      backgroundColor: PentairBrightBlue[500],
      color: theme.palette.getContrastText(PentairBrightBlue[500])
    }
  }));
};

export default function ContentSection(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return (
    <section className={classnames(classes.section, classes[variant])}>
      <div className={classes.container}>{props.children}</div>
    </section>
  );
}
