import React from "react";
import { WithStyles, withStyles, Theme, Typography, Button, Slide, Fade } from "@material-ui/core";
import { ChatbotItem } from "./chatbot-item";
import { observer } from "mobx-react";
import { withPresenter, PresenterProps } from "../../../../helpers/with-presenter";
import { ChatbotPresenter } from "./chatbot-presenter";
import { StyleRules } from "@material-ui/core/styles";
import { TechnologySelector } from "../../technology/technology-selector";
import { typographyVariants } from "../../../theme/config";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { route } from "../../../../config/routes";

interface OwnProps
  extends WithStyles<
    | "root"
    | "close"
    | "chatbotContainer"
    | "chatArea"
    | "chatbotItemContainer"
    | "technologySelector"
    | "technology"
    | "failed"
    | "centerContent"
    | "technologyArea"
  > {
  onTimeout?(): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    position: "absolute",
    right: 0,
    bottom: theme.spacing(5),
    left: theme.spacing(4),
    top: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    "& > div": {
      transition: "flex 0.5s"
    }
  },
  chatArea: {
    position: "relative",
    flex: 1
  },
  chatbotItemContainer: {
    // backgroundColor: "blue",
    bottom: "33%",
    position: "absolute",
    left: 0,
    right: 0
  },
  chatbotContainer: {
    // backgroundColor: "red",
    position: "relative",
    bottom: 0,
    height: "50%",
    width: "100%"
  },
  failed: {
    padding: theme.spacing(4),
    display: "flex",
    backgroundColor: "#fff"
  },
  technologyArea: {
    borderTop: "1px solid #eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2, 0)
  },
  technologySelector: {
    display: "flex",
    textAlign: "center",
    padding: theme.spacing(1, 0)
  },
  technology: {
    width: theme.spacing(8.5),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(10)
    },
    [theme.breakpoints.up("xl")]: {
      width: theme.spacing(14)
    }
  },

  close: {
    position: "absolute",
    top: theme.spacing(8),
    right: theme.spacing(2),
    color: theme.palette.primary.main
  },

  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },

  doneStatus: {
    "& > $chatArea": { flex: 0 },
    "& > $technologyArea": { flex: 1 }
  },
  failedStatus: {}
});

const getCompleteMessage = (technologies: any[]) => {
  if (technologies.length == 1) return "I recommend using the following technology:";
  return "I recommend using one of the following technologies:";
};

@observer
class ChatBotComponent extends React.Component<OwnProps & PresenterProps<ChatbotPresenter>> {
  public render() {
    const { items, input, status, errorMessage } = this.props.presenter;
    const { classes } = this.props;

    return (
      <div className={classnames(classes.root, classes[status + "Status"])}>
        <div className={classes.chatArea}>
          {status === "failed" && (
            <div className={classes.centerContent}>
              <div style={{ maxWidth: 900 }}>
                <Typography align={"center"} variant={typographyVariants.condensed.h2} color={"error"}>
                  {errorMessage || "Something went wrong, please try again"}
                </Typography>
                <Button
                  component={Link}
                  fullWidth
                  variant={"text"}
                  to={route("refresh")}
                  startIcon={<FontAwesomeIcon icon={"redo"} />}
                >
                  Try again
                </Button>
              </div>
            </div>
          )}

          {status !== "done" && status !== "failed" && (
            <>
              {/* <ChatBotStepIndicator length={items.length} currentIndex={currentIndex}></ChatBotStepIndicator> */}
              <div className={classes.chatbotContainer}>
                <div className={classes.chatbotItemContainer}>
                  {items.map((message: any, index: number) => (
                    <ChatbotItem
                      input={input}
                      key={index}
                      errorMessage={this.props.presenter.errorMessage ? this.props.presenter.errorMessage : false}
                      active={items.length == index + 1}
                      message={message}
                      onSubmit={this.props.presenter.onSubmit}
                      onChange={this.props.presenter.onHandleChange}
                      onKeyPress={this.props.presenter.onHandleKeypress}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>

        <Slide in direction="up" appear timeout={500}>
          <div className={classes.technologyArea}>
            <div style={{ flex: 0 }}>
              {status === "done" && (
                <Fade in appear timeout={1000}>
                  <div>
                    <Typography variant={typographyVariants.condensed.h1}>
                      {getCompleteMessage(this.props.presenter.activeTechnologies)}
                    </Typography>
                  </div>
                </Fade>
              )}
              <TechnologySelector
                classes={{ root: classes.technologySelector, technology: classes.technology }}
                include={this.props.presenter.activeTechnologies}
              />
            </div>
          </div>
        </Slide>
      </div>
    );
  }
}

export const ChatBot = withStyles(
  styles,
  {}
)(
  withPresenter<ChatbotPresenter, OwnProps>(
    ({ onTimeout }, { business }) => new ChatbotPresenter(onTimeout),
    ChatBotComponent
  )
);
