import * as React from 'react';

import { App, PentairApp } from '../application/App';

export interface IPresenter {
  mount(): void;
  unmount(): void;
}

export interface PresenterProps<P extends IPresenter> {
  presenter: P;
}

export function withPresenter<P extends IPresenter, Props>(
  presenter: (props: Props, app: PentairApp) => P,
  Component:
    | React.ComponentClass<Props & PresenterProps<P>>
    | React.FunctionComponent<Props & PresenterProps<P>>
): React.ComponentClass<Props> {
  return class Contract extends React.Component<Props> {
    private _presenter: P | undefined;

    public getPresenter(): P {
      if (!this._presenter) {
        this._presenter = presenter(this.props, App);
      }

      return this._presenter;
    }

    public componentDidMount() {
      this.getPresenter().mount();
    }

    public componentWillUnmount() {
      this.getPresenter().unmount();
    }

    public render() {
      return <Component {...this.props} presenter={this.getPresenter()} />;
    }
  };
}
