import React from "react";
import { WithStyles, withStyles, Theme } from "@material-ui/core";
import { observer } from "mobx-react";
import {
  withPresenter,
  PresenterProps
} from "../../../../helpers/with-presenter";
import { CanViewPresenter } from "./can-view-presenter";
import { StyleRules } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";

interface OwnProps extends WithStyles<"root"> {
  notAuthorized?: React.ReactNode;
  roles?: string[];
}

const styles = (theme: Theme): StyleRules => ({
  root: {}
});

@observer
class CanViewComponent extends React.Component<
  RouteComponentProps & OwnProps & PresenterProps<CanViewPresenter>
> {
  public render() {
    const { children, presenter, notAuthorized, roles } = this.props;
    let allowed = presenter.isAdmin;

    if (roles && roles.includes("public")) {
      allowed = true;
    }

    return allowed ? (
      <React.Fragment> {children}</React.Fragment>
    ) : (
      notAuthorized || null
    );
  }
}

export const CanView = withStyles(
  styles,
  {}
)(
  withRouter(
    withPresenter<CanViewPresenter, OwnProps & RouteComponentProps>(
      ({ history, match }, { business }) => new CanViewPresenter(business.user),
      CanViewComponent
    )
  )
);
