import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "airlift-mgb",
  name: "AirLift Megablock MBR",
  oneliner: "Energy-efficient modular solution",
  image: "/images/technologies/AirLift-MGB.jpg",
  techImage: ["/images/tech_images/Megablock/Megablock.png"],
  active: true,
  comparableTechnologies: ["crossflow", "airlift-skds", "airlift-mgb"],
  description:
    "The modular, energy-efficient and fully automated technology delivers a high-quality effluentthat   is   suitable   for   discharge   into   the   environment   or   at   a   reusable   water   source.   TheMegablock uses 25 to 50 percent less energy than any submerged system.",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name", ["Partner5"]),
      setValue("opportunity_number", ["OP-5671234"]),
      setValue("project_name", ["Wastewater treatment"]),
      setValue("project_destination", ["Israel"]),
      setValue("designer", ["Bob van Es"]),
      setValue("approver", ["-"])
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameterss
      setValue("temperature_range", [15, 35])
    ]),
    ...createValuesFor("input.plant.capacity", [
      // Plant Capacity
      setValue("water_source", ["Wastewater"]),
      setValue("capacity", [400])
      // setValue("duration", [24])
    ]),

    ...createValuesFor("plant.configuration", [
      setValue("megablock_type", ["MGB436"]),
      setValue("total_units", [2]),
      setValue("total_membrane_elements", [288]),
      setValue("spare_membrane_elements", [0]),
      setValue("membrane_element", ["COMPACT32V"]),
      setValue("membrane_elements_bw_segement", [36]),
      setValue("membrane_elements_per_unit", [144]),
      setValue("total_mebrane_area", [9216]),
      setValue("backwash_trains", [1])
    ]),
    ...createValuesFor("plant.configuration.image", [
      // setValue("airflush_flow"),
      setValue("avg_feed_flow"),
      setValue("feed_flow", [1268]),
      setValue("airlift_flow", [1440], undefined, "Nm³/h/MGB"),
      setValue("citric_acid", [5516], undefined, "lph/MGB"),
      setValue("net_permeate_flow", [222], undefined, "m³/h/MGB"),
      setValue("gross_permeate_flow", [253], undefined, "m³/h/MGB"),
      setValue("naocl_dosing_pump", [1143], undefined, "lph/MGB"),

      setValue("drain_volume", [27.9]),
      setValue("permeate_pump", [346], undefined, "m³/h/MGB"),
      setValue("backwash_pumps", [297], undefined, "m³/h/unit"),
      setValue("permeate_tank", [106]),
      setValue("drain_tank", [85]),
      setValue("drain_pump", [44]),
      setValue("concentrate_flow", [1014])
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("gross_filtration_flux", [55]),
      setValue("filtration_time", [10]),
      setValue("ceb_frequency", [17]),
      setValue("drain_frequency", [6]),
      setValue("recovery", [89.0])
    ]),

    setValue("comment", ["true"]),
    setValue("nr_of_airlift_blowers", [1]),
    setValue("airlift_blower", [4320]),
    setValue("nr_of_circulation_pumps", [2]),
    setValue("circulation_pump_flow", [965]),
    setValue("nr_of_permeate_pumps"),
    setValue("permeate_pump_flow"),
    setValue("nr_of_backwash_pumps"),
    setValue("backwash_pump_flow"),
    setValue("nr_of_naocl_dosing"),
    setValue("naocl_dosing_flow"),
    setValue("nr_of_citric_acid_dosing"),
    setValue("citric_acid_dosing_flow"),
    setValue("nr_of_feed_pumps", [2])
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [12, 3],
      rotation: 0,
      width: 300,
      align: "left",
      values: [
        "megablock_type",
        "membrane_element",
        "membrane_elements_bw_segement",
        "membrane_elements_per_unit",
        "total_units",
        "total_membrane_elements",
        "total_mebrane_area"
      ]
    },
    // {
    //   title: null,
    //   values: ["avg_feed_flow"],
    //   location: [1, 19],
    //   rotation: -5,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["feed_flow"],
    //   location: [40, 42],
    //   rotation: 0,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    {
      title: null,
      values: ["gross_permeate_flow"],
      location: [82, 2],
      rotation: -6,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "NaOCL CEB Flow",
      values: ["naocl_dosing_pump"],
      location: [74.7, 17],
      rotation: -90,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: "Citric acid CEB Flow",
      values: ["citric_acid"],
      location: [70.5, 17],
      rotation: -90,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: null,
      values: ["net_permeate_flow"],
      location: [93, 3],
      rotation: -9,
      width: 90,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["airlift_flow"],
      location: [50, 60],
      rotation: -16,
      width: 150,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_volume"],
      location: [72, 49],
      rotation: -16,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["permeate_pump"],
      location: [79.7, 41],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["backwash_pumps"],
      location: [89.3, 38],
      rotation: 0,
      width: 160,

      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["permeate_tank"],
      location: [93, 22],
      rotation: 0,
      width: 90,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_tank"],
      location: [72, 62],
      rotation: 0,
      width: 160,

      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["drain_pump"],
      location: [66, 69],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["concentrate_flow"],
      location: [30, 26],
      rotation: -8,
      width: 160,

      align: "left",
      titleVariant: "small"
    }
  ]
} as TechnologyData;
