import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "crossflow",
  name: "Crossflow MBR",
  oneliner: "Robust for high-strength wastewater",
  image: "/images/technologies/Crossflow.jpg",
  techImage: ["/images/tech_images/Crossflow/Crossflow.png"],
  active: true,
  comparableTechnologies: ["crossflow", "airlift-skds", "airlift-mgb"],
  description:
    "The technology for treating (industrial) wastewater with high TSS (up to 20g/l), at high fluxrates of 80-150 l/m2h and suitable for capacities from 150-500m3/h (4-12 MLD) effortlessly.This   robust   technology   produces   directly   reusable   effluent   with   very   low   maintenancerequirements and automated cleaning.",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name", ["Partner7"]),
      setValue("opportunity_number", ["OP-7123457"]),
      setValue("project_name", ["Wastewater treatment"]),
      setValue("project_destination", ["United States"]),
      setValue("designer", ["Bob van Es"]),
      setValue("approver", ["-"])
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameterss
      setValue("temperature_range", [15, 35])
    ]),
    ...createValuesFor("input.plant.capacity", [
      // Plant Capacity
      setValue("water_source", ["Wastewater"]),
      setValue("capacity", [400])
      // setValue("duration", [24])
    ]),

    ...createValuesFor("plant.configuration", [
      setValue("skid_type", ["6 type"]),
      setValue("total_units", [16]),
      setValue("membrane_elements", [6]),
      setValue("total_membrane_elements", [96]),
      setValue("spare_membrane_elements", [0]),
      setValue("membrane_element", ["COMPACT55G"]),
      setValue("total_mebrane_area", [5280])
      // setValue("backwash_trains", [1])
    ]),
    ...createValuesFor("plant.configuration.image", [
      // setValue("airflush_flow"),
      setValue("avg_feed_flow"),
      setValue("feed_flow", [684]),
      setValue("airlift_flow", [300]),
      setValue("citric_acid", [2389]),
      setValue("net_permeate_flow", [53]),
      setValue("gross_permeate_flow", [59]),
      setValue("naocl_dosing_pump", [491]),

      setValue("drain_volume", [3.3]),
      setValue("permeate_pump", [75]),
      setValue("backwash_pumps", [297]),
      setValue("permeate_tank", [128]),
      setValue("drain_tank", [18]),
      setValue("drain_pump", [36]),
      setValue("concentrate_flow", [625]),

      setValue("nr_of_skids_per_feed_pump"),
      setValue("nr_of_feed_pumps"),
      setValue("feed_flow", [412], undefined, "m³/h"),
      setValue("nr_of_crossflow_pumps", [16]),
      setValue("flow_rate", [257]),
      setValue("nr_of_cip_pumps", [3]),
      setValue("cip_flow_rate", [103]),
      setValue("nr_of_cip_tanks", [3]),
      setValue("cip_tank_volume", [8])
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("minimum_design_flux", [80]),
      setValue("design_flux", [100]),
      setValue("maximum_design_flux", [125])
      // setValue("filtration_time", [10]),
      // setValue("ceb_frequency", [17]),
      // setValue("drain_frequency", [6]),
      // setValue("recovery", [89.8])
    ]),

    setValue("comment", ["true"]),
    setValue("unit_type", ["6-type"])
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [12, 2],
      rotation: 0,
      width: 300,
      align: "left",
      values: [
        "skid_type",
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "membrane_element",
        "total_mebrane_area"
      ]
    },

    {
      title: "Feed section",
      titleVariant: "small",
      location: [35, 74],
      rotation: 0,
      width: 300,
      align: "left",
      values: ["nr_of_skids_per_feed_pump", "nr_of_feed_pumps", "feed_flow"]
    },

    {
      title: "Crossflow pump",
      titleVariant: "small",
      location: [45, 67],
      rotation: 0,
      width: 300,
      align: "left",
      values: ["nr_of_crossflow_pumps", "flow_rate"]
    },
    {
      title: "CIP pump",
      titleVariant: "small",
      location: [74, 67],
      rotation: 0,
      width: 300,
      align: "left",
      values: ["nr_of_cip_pumps", "cip_flow_rate"]
    },
    {
      title: "CIP tanks",
      titleVariant: "small",
      location: [63.8, 51.8],
      rotation: -18,
      width: 300,
      align: "right",
      values: ["nr_of_cip_tanks", "cip_tank_volume"]
    },
    {
      title: "NaOCl & NaOH",
      titleVariant: "small",
      location: [76.5, 17],
      rotation: 0,
      width: 75,
      align: "left",
      values: []
    },
    {
      title: "Citric acid",
      titleVariant: "small",
      location: [71, 24],
      rotation: 0,
      width: 75,
      align: "right",
      values: []
    },

    // {
    //   title: null,
    //   values: ["feed_flow"],
    //   location: [40, 42],
    //   rotation: 0,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["gross_permeate_flow"],
    //   location: [80, 3],
    //   rotation: 0,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: "NaOCL CEB Flow",
    //   values: ["naocl_dosing_pump"],
    //   location: [74, 24],
    //   rotation: -45,
    //   width: 170,
    //   align: "left",
    //   titleVariant: "small"
    //   // line: "M 1 1 1 30 l50 30"
    // },
    // {
    //   title: "Citric acid CEB Flow",
    //   values: ["citric_acid"],
    //   location: [68, 25],
    //   rotation: -45,
    //   width: 170,
    //   align: "left",
    //   titleVariant: "small"
    //   // line: "M 1 1 1 30 l50 30"
    // },
    // {
    //   title: null,
    //   values: ["net_permeate_flow"],
    //   location: [93, 6],
    //   rotation: -9,
    //   width: 90,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["airlift_flow"],
    //   location: [58, 62],
    //   rotation: 0,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["drain_volume"],
    //   location: [69, 56],
    //   rotation: 30,
    //   width: 160,
    //   info: null,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["permeate_pump"],
    //   location: [75, 52],
    //   rotation: 0,
    //   width: 160,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["backwash_pumps"],
    //   location: [85, 49],
    //   rotation: 0,
    //   width: 160,

    //   align: "left",
    //   titleVariant: "small"
    // },
    {
      title: null,
      values: ["permeate_tank"],
      location: [91, 25],
      rotation: 0,
      width: 90,
      align: "left",
      titleVariant: "small"
    }
    // {
    //   title: null,
    //   values: ["drain_tank"],
    //   location: [77, 66],
    //   rotation: 0,
    //   width: 160,

    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["drain_pump"],
    //   location: [72, 79],
    //   rotation: 0,
    //   width: 160,
    //   align: "left",
    //   titleVariant: "small"
    // },
    // {
    //   title: null,
    //   values: ["concentrate_flow"],
    //   location: [35, 11],
    //   rotation: -6,
    //   width: 160,

    //   align: "left",
    //   titleVariant: "small"
    // }
  ]
} as TechnologyData;
