import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "aquaflex",
  name: "Aquaflex",
  image: "/images/technologies/Aquaflex.jpg",
  oneliner: "For tough-to-treat applications",
  techImage: [
    "/images/tech_images/Aquaflex/Aquaflex.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_01.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_02.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_03.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_04.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_05.png",
    "/images/tech_images/Aquaflex/Aquaflex-comp_06.png"
  ],
  active: true,
  comparableTechnologies: ["xline", "xiga", "aquaflex"],
  description:
    "The ultrafiltration solution that can treat water containing up to 1000 mg/l of total suspendedsolids, or 500 NTU turbidity. Specifically suited for tough-to-treat industrial wastewater polishingor direct drinking water production.",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name", ["Partner1"]),
      setValue("opportunity_number", ["OP-1234567"]),
      setValue("project_name", ["UF Water Treatment"]),
      setValue("project_destination", ["Saudi Arabia"]),
      setValue("designer", ["Karthi Pitchaikani"]),
      setValue("approver", ["-"])
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameters
      setValue("temperature_range", [15, 35]),
      setValue("ph_range", [7.8, 8.2]),
      setValue("calcium", [410]),
      setValue("ammonia", [1]),
      setValue("phosphorus", [1])
    ]),
    ...createValuesFor("input.water.main", [
      // Main water parameters
      setValue("design_turbidity", [10]),
      setValue("temperature", [20]),
      setValue("turbidity", [10]),
      setValue("tss", [10]),
      setValue("toc", [3]),
      setValue("cod", [5]),
      setValue("color", [10]),
      setValue("alkanity", [130]),
      setValue("ph", [7.2]),
      setValue("tds", [40000]),
      setValue("hardness", [6900])
    ]),
    ...createValuesFor("input.plant.capacity", [
      setValue("water_source", ["Sea water"]),
      setValue("capacity_base", ["Permeate production"]),
      setValue("capacity", [1000]),
      setValue("temperature", [20])
    ]),
    ...createValuesFor("input.plant.membrane", [
      //
      setValue("membrane")
    ]),
    ...createValuesFor("plant.configuration", [
      setValue("total_units", [4]),
      setValue("membrane_element", ["XF64"]),
      setValue("total_membrane_elements", [200]),
      setValue("spare_membrane_elements", [0]),
      setValue("total_mebrane_area", [12800]),
      setValue("backwash_trains", [1]),
      setValue("membrane_elements", [50])
    ]),
    ...createValuesFor("plant.configuration.image", [
      setValue("airflush_flow", [500]),
      setValue("avg_feed_flow", [1046.8]),
      setValue("circulation_pump", [48]),
      setValue("fecl3_dosing_pump", [5.5],undefined,'lph per unit'),
      setValue("feed_pumps", [1075.2]),
      setValue("backwash_pumps", [768]),
      setValue("net_permeate_flow", [1000]),
      setValue("total_concentrate_flow", [46.8]),
      setValue("avg_forward_flush_flow", [17.1]),
      setValue("avg_backwash_flow", [22.5]),
      setValue("avg_ceb_1_flow", [7.2])
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("gross_filtration_flux", [84]),
      setValue("filtration_time", [50]),
      setValue("net_filtration_flux", [78.1]),
      setValue("avg_ceb_1_counter", [24.4]),
      setValue("ceb_1_counter", [28]),
      setValue("recovery", [95.5])
    ]),
    ...createValuesFor("results.pump.naocl", [
      // Calculated results
      setValue("naocl_dosing_pump", [768]),
      setValue("naocl_consumption", [25.7])
    ]),
    ...createValuesFor("results.pump.naoh", [
      // Calculated results
      setValue("naoh_dosing_pump", [173.5]),
      setValue("naoh_consumption", [5.8])
    ]),
    ...createValuesFor("results.pump.h2so4", [
      // Calculated results
      setValue("h2so4_dosing_pump", [269.1]),
      setValue("h2so4_consumption", [9.0])
    ]),
    // net made values for demo
    setValue("uf_feed_pump",[1075.2]),
    setValue("fecl3_coagulant_pump",[5.5])
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [3, 0],
      rotation: 0,
      width: 250,
      align: "left",
      values: [
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "spare_membrane_elements",
        "membrane_element",
        "total_mebrane_area",
        "backwash_trains"
      ]
    },
    {
      title: null,
      values: ["airflush_flow"],
      location: [27, 85],
      rotation: -18,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["avg_feed_flow"],
      location: [45, 88],
      rotation: -19,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["circulation_pump"],
      location: [42, 72],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["fecl3_dosing_pump"],
      location: [56.2, 70],
      rotation: 0,
      width: 100,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["feed_pumps"],
      location: [68, 69],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "NaOCL CEB",
      values: ["naocl_dosing_pump", "naocl_consumption"],
      location: [40, 5],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: "NaOH CEB",
      values: ["naoh_dosing_pump", "naoh_consumption"],
      location: [52, 2.5],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "H₂SO₄ CEB",
      values: ["h2so4_dosing_pump", "h2so4_consumption"],
      location: [64, 0],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["backwash_pumps"],
      location: [70, 33],
      rotation: 0,
      width: 120,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["net_permeate_flow"],
      location: [91, 13],
      rotation: -10,
      width: 90,
      titleVariant: "small"
    },
    {
      title: "Concentrate",
      values: ["total_concentrate_flow", "avg_forward_flush_flow", "avg_backwash_flow", "avg_ceb_1_flow"],
      location: [83, 67],
      rotation: 0,
      width: 250,
      align: "right",
      info: null,
      titleVariant: "small"
      // line: "M250 1 20 30 250 -200"
    }
  ]
} as TechnologyData;
