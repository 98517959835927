import { UserFragment } from "./fragments";

export const QueryUser = `
query User($id:ID!) {
  User(id:$id) {
    ${UserFragment}
  }
}`;

export const QueryUserCollection = `
query UserCollection {
  UserCollection {
    ${UserFragment}
  }
}`;

export const QueryUserDesigns = `
query User($id:ID!) {
  User(id:$id) {
    designs{
      id
      version
      company
      status
      project
      pdf
    }
  }
}`;

export const QueryUserNotifications = `
query User($id:ID!) {
  User(id:$id) {
    notifications{
      id
    }
  }
}`;
