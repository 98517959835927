import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { TooltipClassKey } from "@material-ui/core/Tooltip";

import { PentairBrightBlue, PentairLightGray } from "../colors/_all";
import { typography } from "../config";

export const MuiTooltip: Partial<Record<TooltipClassKey, CSSProperties>> = {
  tooltip: {
    ...typography("body2"),
    fontSize: "12px",
    backgroundColor: PentairBrightBlue[500],
    color: PentairLightGray[400]
  }
};
