import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { AuthPagePresenter } from "./_base-auth-page-presenter";
import { DesignInteractor } from "../../application/data/design/design-interactor";
import { computed } from "mobx";

export class DesignPresenter extends AuthPagePresenter implements IPresenter {
  constructor(
    protected _designInteractor: DesignInteractor,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps<{ design?: string }>
  ) {
    super(_userInteractor, _router);

    this.load();
  }

  public load = async () => {
    this.loading = true;
    await this._designInteractor.load();

    this._designInteractor.select(this._router.match.params.design || "");
    this.loading = false;
  };

  public mount = () => {};

  @computed public get allDesigns() {
    return this._designInteractor.all;
  }

  @computed public get design() {
    return this._designInteractor.selected;
  }

  public unmount = () => {
    //
  };

  public updateDesign = (designID: String): void => {
    if (this.design) {
      this.design.update();
    }
  };
}
