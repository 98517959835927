import { observable, computed } from "mobx";
import { BaseModule } from "../../module";
import DefaultUserProvider from "./user-provider";
import { User, UserModel, UserData } from "./user";
import { BusinessModule } from "../../business/business";
import { Design } from "../design/design";
import { Auth } from "aws-amplify";

export interface UserInteractor {
  currentUser: User | undefined;
  isAdmin: any;
  userInteractions: any;
  provider: DefaultUserProvider;
  allUsers: User[];
  create: (data?: Partial<UserData>, persist?: boolean) => User;
  logout: () => void;
}

export default class DefaultUserInteractor extends BaseModule<any, any, any>
  implements UserInteractor {
  @observable public _authUser: User | undefined = undefined;
  @observable public _is_admin: boolean = false;
  private _fetchedAll: boolean = false;

  constructor(business: BusinessModule) {
    super();

    this.provider.fetchAuthUser().then((user: User | undefined) => {
      if (user) {
        this._authUser = user;
      }
    });

    business.design.provider.subscribe("designCreated", this.onDesignCreated);
    business.design.provider.subscribe("designDeleted", this.onDesignDeleted);
  }

  public create = (data: Partial<UserData> = {}, persist: boolean = false) => {
    const user = this.provider.create(data, persist);
    return user;
  };

  public logout = () => {
    Auth.signOut();
    window.location.reload();
  };

  @computed public get allUsers() {
    if (!this._fetchedAll) {
      this._fetchedAll = true;
      this.provider.fetchAllUsers();
    }
    return this.provider.allRecords.map(user => UserModel.get(user.id));
  }

  private onDesignCreated = (design: Design) => {
    // if (this.currentUser) {
    //   this.currentUser.addDesign(design);
    // }
  };

  private onDesignDeleted = (design: Design) => {
    // if (this.currentUser) {
    //   this.currentUser.removeDesign(design.id);
    // }
  };

  public get currentUser() {
    return this._authUser;
  }

  public get provider() {
    return this.loadProvider("interactor", DefaultUserProvider);
  }

  public registerUserInteractions(interaction: string, user: User | undefined) {
    console.log("log", interaction, user);

    // AWS storage implementation? to write log?
    // Storage.get("text.txt");
  }

  public get userInteractions() {
    return [
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Edgar Ravenhorst",
        email: "edgar@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Lesley Buur",
        email: "lesley@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Lesley Buur",
        email: "lesley@code.rehab",
        interaction: "logout",
        dateTime: "10-09-2019 18:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      },
      {
        username: "Mathijs Jansen",
        email: "mathijs@code.rehab",
        interaction: "login",
        dateTime: "10-12-2019 12:36"
      }
    ];
  }

  @computed
  public get isAdmin() {
    const admin_emails = new Array(
      "info@code.rehab",
      "karthi.pitchaikani@pentair.com"
    );

    if (this._authUser) {
      // console.log(this._authUser.email);
      return admin_emails.includes(this._authUser.attributes.email || "");
    }

    return false;
  }

  public get getRoles() {
    throw new Error("Method not implemented");
  }
}
