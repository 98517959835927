import LexRuntime from "aws-sdk/clients/lexruntime";
import { ConsoleLogger as Logger, AWS, Credentials } from "@aws-amplify/core";

const logger = new Logger("AWSLexProvider");
interface InteractionsOptions {
  name: string;
  alias: string;
  region: string;
}
type BotData = LexRuntime.PostTextResponse;
type BotSlotData = Pick<LexRuntime.PostTextResponse, "slots">;
type BotCompleteCallback = (err: string | null, slots?: BotSlotData) => any;
export interface MessageProps {
  inputText: string;
  requestAttributes?: { [s: string]: string };
  sessionAttributes?: { [s: string]: string };
}

export class AWSLexBot {
  private _options: InteractionsOptions;
  private aws_lex: LexRuntime;
  private _botCompleteCallback?: BotCompleteCallback;

  constructor(options: InteractionsOptions) {
    this._options = options;
    this.aws_lex = new LexRuntime({ region: options.region });
    this._botCompleteCallback = undefined;
  }

  responseCallback(err: any, data: BotData, res: (data: BotData) => void, rej: (err: any) => void) {
    if (err) {
      rej(err);
      return;
    } else {
      // Check if state is fulfilled to resolve onFullfilment promise
      logger.debug("postContent state", data.dialogState);
      if (data.dialogState === "ReadyForFulfillment" || data.dialogState === "Fulfilled") {
        if (typeof this._botCompleteCallback === "function") {
          setTimeout(() => this._botCompleteCallback && this._botCompleteCallback(null, { slots: data.slots }), 0);
        }
      }

      res(data);
      if (data.dialogState === "Failed") {
        if (typeof this._botCompleteCallback === "function") {
          setTimeout(() => this._botCompleteCallback && this._botCompleteCallback("Bot conversation failed"), 0);
        }
      }
    }
  }

  sendMessage(message: MessageProps): Promise<object> {
    return new Promise(async (res, rej) => {
      const credentials = await Credentials.get();
      if (!credentials) {
        return rej("No credentials");
      }
      AWS.config.update({
        credentials
      });

      this.aws_lex = new LexRuntime({
        region: this._options.region,
        credentials
      });

      let params: LexRuntime.PostTextRequest;
      let _requestAttributes = {
        "x-amz-lex:accept-content-types": "CustomPayload",
        ...message.requestAttributes
      };
      params = {
        botAlias: this._options.alias,
        botName: this._options.name,
        inputText: message.inputText,
        userId: credentials.identityId,
        requestAttributes: _requestAttributes
      };

      if (message.sessionAttributes) params.sessionAttributes = message.sessionAttributes;

      logger.debug("postText to lex", message);

      this.aws_lex.postText(params, (err, data) => {
        this.responseCallback(err, data, res, rej);
      });
    });
  }

  onComplete(callback: BotCompleteCallback): void {
    this._botCompleteCallback = callback;
  }
}
