import { TechnologyData } from "../technology";
import { createValuesFor, setValue } from "../../__mock__/calculation-values";

export default {
  id: "aquaflex-hs",
  name: "Aquaflex HS",
  oneliner: "",
  image: "/images/technologies/Aquaflex-HS.jpg",
  techImage: [
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS.png",
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS-comp_01.png",
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS-comp_02.png",
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS-comp_03.png",
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS-comp_04.png",
    "/images/tech_images/Aquaflex-HS/Aquaflex-HS-comp_05.png"
  ],
  active: true,
  comparableTechnologies: [],
  description:
    "Ut sagittis convallis mi at dictum. In eleifend tempus lacus tempus aliquam. Aliquam vestibulum enim eu nibh placerat pretium. Sed convallis nisl tellus, ut molestie sapien sodales ultrices. Donec tellus magna, congue eu venenatis nec, sagittis ut justo.",
  values: [
    ...createValuesFor("design.details", [
      // Detailed feed parameters
      setValue("account_name", ["Partner4"]),
      setValue("opportunity_number", ["OP-4567123"]),
      setValue("project_name", ["Wastewater polishing"]),
      setValue("project_destination", ["Hungary"]),
      setValue("designer", ["Karthi Pitchaikani"]),
      setValue("approver", ["-"])
    ]),
    ...createValuesFor("input.feed.detailed", [
      // Detailed feed parameters
      setValue("temperature_range", [15, 35]),
      setValue("ph_range", [7.8, 8.2]),
      setValue("calcium", [100]),
      setValue("ammonia", [1]),
      setValue("phosphorus", [1])
    ]),
    ...createValuesFor("input.water.main", [
      // Main water parameters
      setValue("design_turbidity", [300]),
      setValue("temperature", [20]),
      setValue("turbidity", [300]),
      setValue("tss", [60]),
      setValue("toc", [20]),
      setValue("cod", [1000]),
      setValue("color", [0]),
      setValue("alkanity", [150]),
      setValue("ph", [7.5]),
      setValue("tds", [2000]),
      setValue("hardness", [300])
    ]),
    ...createValuesFor("input.plant.capacity", [
      // Plant Capacity
      setValue("water_source", ["Treated effluent"]),
      setValue("capacity_base", ["Permeate production"]),
      setValue("capacity", [138]),
      setValue("temperature", [20])
    ]),
    ...createValuesFor("input.plant.membrane", [
      //
      setValue("membrane")
    ]),
    ...createValuesFor("plant.configuration", [
      setValue("total_units", [2]),
      setValue("membrane_elements", [40]),
      setValue("total_membrane_elements", [80]),
      setValue("spare_membrane_elements", [0]),
      setValue("membrane_element", ["XF33R"]),
      setValue("total_mebrane_area", [2640]),
      setValue("backwash_trains", [1])
    ]),
    ...createValuesFor("plant.configuration.image", [
      setValue("airflush_flow", [800]),
      setValue("avg_feed_flow", [154.6]),
      setValue("fecl3_dosing_pump", [27.3],undefined,'lph per unit'),
      setValue("feed_pumps", [158.4]),
      setValue("backwash_pumps", [316.8]),
      setValue("net_permeate_flow", [138]),
      setValue("total_concentrate_flow", [16.6]),
      setValue("avg_forward_flush_flow", [9.1]),
      setValue("avg_backwash_flow", [5.6]),
      setValue("avg_ceb_1_flow", [2])
    ]),
    ...createValuesFor("results", [
      // Calculated results
      setValue("gross_filtration_flux", [60]),
      setValue("filtration_time", [40]),
      setValue("net_filtration_flux", [52.3]),
      setValue("avg_ceb_1_counter", [18.2]),
      setValue("ceb_1_counter", [25]),
      setValue("recovery", [89.3])
    ]),
    ...createValuesFor("results.pump.naocl", [
      // Calculated results
      setValue("naocl_dosing_pump", [633.6]),
      setValue("naocl_consumption", [7.1])
    ]),
    ...createValuesFor("results.pump.naoh", [
      // Calculated results
      setValue("naoh_dosing_pump", [244.5]),
      setValue("naoh_consumption", [2.7])
    ]),
    ...createValuesFor("results.pump.h2so4", [
      // Calculated results
      setValue("h2so4_dosing_pump", [223.1]),
      setValue("h2so4_consumption", [2.5])
    ]),
    
  ],
  techImageMap: [
    {
      title: "Plant configuration",
      titleVariant: "big",
      location: [0, 0],
      rotation: 0,
      width: 250,
      align: "left",
      values: [
        "total_units",
        "membrane_elements",
        "total_membrane_elements",
        "spare_membrane_elements",
        "membrane_element",
        "total_mebrane_area",
        "backwash_trains"
      ]
    },
    {
      title: null,
      values: ["airflush_flow"],
      location: [33, 88],
      rotation: -18,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["avg_feed_flow"],
      location: [48, 87],
      rotation: -19,
      width: 160,
      info: null,
      align: "left",
      titleVariant: "small"
    },

    {
      title: null,
      values: ["fecl3_dosing_pump"],
      location: [53, 70],
      rotation: 0,
      width: 120,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["feed_pumps"],
      location: [68, 69],
      rotation: 0,
      width: 160,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "NaOCL CEB",
      values: ["naocl_dosing_pump", "naocl_consumption"],
      location: [40, 3],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
      // line: "M 1 1 1 30 l50 30"
    },
    {
      title: "NaOH CEB",
      values: ["naoh_dosing_pump", "naoh_consumption"],
      location: [52, 1],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
    },
    {
      title: "H₂SO₄ CEB",
      values: ["h2so4_dosing_pump", "h2so4_consumption"],
      location: [64, -1.5],
      rotation: 0,
      width: 170,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["backwash_pumps"],
      location: [70, 33],
      rotation: 0,
      width: 120,
      align: "left",
      titleVariant: "small"
    },
    {
      title: null,
      values: ["net_permeate_flow"],
      location: [91.5, 11],
      rotation: -10,
      width: 90,
      titleVariant: "small"
    },
    {
      title: "Concentrate",
      values: ["total_concentrate_flow", "avg_forward_flush_flow", "avg_backwash_flow", "avg_ceb_1_flow"],
      location: [84, 65],
      rotation: 0,
      width: 250,
      align: "right",
      info: null,
      titleVariant: "small"
      // line: "M250 1 20 30 250 -200"
    }
  ]
} as TechnologyData;
