import { IPresenter } from "../../../../helpers/with-presenter";
import { UserInteractor } from "../../../../application/data/user/user-interactor";
import { observable } from "mobx";

export class CanViewPresenter implements IPresenter {
  @observable public isAdmin: any = false;

  public mount = () => {
    this.getRole();
  };

  @observable
  public getRole() {
    this.isAdmin = this._userInteractor.isAdmin;
  }

  public unmount = () => {};

  constructor(private _userInteractor: UserInteractor) {}
}
