import { DesignFragment } from "./fragments";

export const QueryDesign = `
  query Design($id:ID!) {
    Design(id:$id){
    ${DesignFragment}
  }
  }
`;

export const QueryDesignOwner = `
  query Design($id:ID!) {
    Design(id:$id){
    owner{
      id
      username
      email
    }
  }
  }
`;

export const QueryDesignApprover = `
  query Design($id:ID!) {
    Design(id:$id){
    approver{
      id
      username
      email
    }
  }
  }
`;

export const QueryDesignUsers = `
  query DesignUsers($id:ID!) {
    Design(id:$id){
    users{
      id
      username
      email
    }
  }
  }
`;

export const QueryDesignNotifications = `
query DesignNotification($id:ID!) {
  Design(id:$id){
    notifications {
      id
      name
      description
      acknowledged
      annotations {
        id
        description
        user {
          id
          username
        }
      }
    }
  }
}
`;
