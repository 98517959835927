import classnames from "classnames";
import React from "react";

import { Table, TableBody, TableCell, TableRow, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";

import { spacing, typographyVariants } from "../../theme/config";
import { CalculationValue } from "../../../application/data/technology/technology";
import { observer } from "mobx-react";

interface RowConfig extends CalculationValue {
  space?: number;
}

interface OwnProps
  extends WithStyles<
    | "root"
    | "tableCell"
    | "tableValueCell"
    | "before"
    | "name"
    | "value"
    | "suggestedValue"
    | "unit"
    | "listname"
    | "bold"
  > {
  title?: string;
  values: Array<RowConfig | undefined>;
  variant?: "list";
  hideRecommendedValue?: boolean;
}

const styles = (theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  tableCell: {
    fontWeight: 600,
    padding: spacing(0.2, 0, 0.2, 0),
    border: 0,
    [theme.breakpoints.down("md")]: {
      minWidth: 150
    }
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: spacing(2)
  },
  name: {},
  value: {},
  suggestedValue: { opacity: 0.5 },
  unit: {},
  listname: { width: spacing(12), color: theme.palette.silverGray.main },
  bold: { fontWeight: 700 }
});

const emptyValue = (): CalculationValue => {
  return {
    id: "empty",
    name: "-",
    values: ["-"],
    unit: "-",
    recommendedValues: ["-"]
  };
};

export const ValuesTable = withStyles(styles)(
  observer(({ classes, variant, title, values, hideRecommendedValue }: OwnProps) => {
    return (
      <div className={classes.root}>
        {title && (
          <Typography variant='h4' paragraph>
            {title}
          </Typography>
        )}
        <Table style={{ fontSize: "0.825em" }}>
          <TableBody>
            {(values || [])
              .filter(exist => exist)
              .map((row, index) => {
                if (!row) {
                  row = emptyValue();
                }
                return row.space ? (
                  <div key={index} style={{ height: spacing(row.space) }} />
                ) : variant === "list" ? (
                  <TableRow key={index}>
                    <TableCell className={classnames(classes.tableCell, classes.listname)}>
                      <Typography className={classes.bold} variant={typographyVariants.body2} color={"inherit"}>
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell className={classnames(classes.tableCell, classes.value)}>
                      <Typography variant={typographyVariants.body2}>
                        : {(row.values || []).join(" - ")} {row.unit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    <TableCell className={classnames(classes.tableCell, classes.name)}>
                      <Typography variant={typographyVariants.body2}>{row.name}</Typography>
                    </TableCell>

                    <TableCell className={classnames(classes.tableCell, classes.value)}>
                      <Typography variant={typographyVariants.body2}>
                        {(row.values || []).join(" - ")} {row.unit}
                      </Typography>
                    </TableCell>

                    {!hideRecommendedValue && (
                      <TableCell className={classnames(classes.tableCell, classes.suggestedValue)}>
                        <Typography variant={typographyVariants.body2}>
                          {(row.recommendedValues || []).join(" - ")}
                        </Typography>
                      </TableCell>
                    )}

                    {/* <TableCell className={classnames(classes.tableCell, classes.unit)}>
                      <Typography variant={typographyVariants.body2}>{row.unit}</Typography>
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    );
  })
);
