import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { SelectTechnologyPresenter } from "./select-technology-presenter";
import { Typography, Grid, Button, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { typographyVariants } from "../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/styles";
import { IconButton } from "../content/mui-extended/mui-icon-button";
import { TechnologySelector } from "../content/technology/technology-selector";
import { route } from "../../config/routes";

type OwnProps = RouteComponentProps &
  WithStyles<
    | "root"
    | "inner"
    | "header"
    | "layout"
    | "media"
    | "backButton"
    | "technology"
    | "compareToggleButton"
    | "checkbox"
    | "imageContainer"
  > &
  PresenterProps<SelectTechnologyPresenter>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },

  inner: {
    maxWidth: "1200px",
    margin: "auto",
    paddingBottom: 200
  },

  header: {
    alignItems: "center",
    display: "flex"
  },

  media: {},
  backButton: {
    backgroundColor: "#f2f2f2",
    borderRadius: 0,
    marginRight: theme.spacing(2)
  },
  compareToggleButton: {
    marginLeft: theme.spacing(2)
  },
  layout: {
    backgroundColor: "#fff",
    backgroundSize: "100% auto",
    backgroundImage: "url('/images/water-bg.png')",
    backgroundPosition: "left calc(100vh - 37.5vw)",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  },
  technology: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  checkbox: {
    position: "absolute",
    right: 0,
    bottom: 0
  },
  imageContainer: {
    position: "relative",
    padding: theme.spacing(1)
  }
});

@observer
class Component extends React.Component<OwnProps> {
  public render() {
    const { classes, presenter } = this.props;

    return (
      <Layout noHeader classes={{ root: classes.layout }}>
        <div className={classes.root}>
          <div className={classes.inner}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.header}>
                <IconButton icon='arrow-circle-left' onClick={presenter.back} className={classes.backButton}>
                  Previous
                </IconButton>
                <Typography variant={typographyVariants.condensed.h2}>Select technology</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom variant={typographyVariants.h4}>
                  About the technology
                </Typography>

                <Typography paragraph variant={typographyVariants.body2}>
                  Proin eget purus mauris. Integer non maximus justo, lacinia congue libero. Fusce molestie tellus ac
                  nisl faucibus congue. Curabitur sem magna, luctus sit amet interdum non, ultricies ac.
                </Typography>

                <Typography gutterBottom variant={typographyVariants.h4}>
                  Design assistant
                </Typography>

                <Typography paragraph variant={typographyVariants.body2}>
                  Need help deciding which technology fit’s best? Our design assistant will help you find the best
                  match!
                </Typography>

                <Button
                  component={Link}
                  variant='contained'
                  color='primary'
                  to={route("app.design.assistant")}
                  endIcon={<FontAwesomeIcon icon={["fal", "comments"]} />}
                >
                  Start Design assistant
                </Button>
              </Grid>
              <Grid item xs={9}>
                <TechnologySelector />
              </Grid>
            </Grid>
          </div>
        </div>
      </Layout>
    );
  }
}

export const PageSelectTechnology = withRouter(
  withStyles(styles, {})(
    withPresenter<SelectTechnologyPresenter, OwnProps>(
      (props, { business }) =>
        new SelectTechnologyPresenter(business.design, business.technology, business.user, props),
      Component
    )
  )
);
