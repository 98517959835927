import React from "react";
import WidgetHeader from "./widget-header";
import WidgetContent from "./widget-content";
import { Card } from "@material-ui/core";
import { IconName } from "@fortawesome/pro-light-svg-icons";

interface OwnProps {
  title: string;
  icon?: IconName;
  children: any;
  noHeader?: boolean;
}

export default function Widget({ title, children, noHeader }: OwnProps) {
  return (
    <Card>
      {!noHeader && <WidgetHeader title={title}></WidgetHeader>}
      <WidgetContent>{children}</WidgetContent>
    </Card>
  );
}
