import createSpacing from "@material-ui/core/styles/createSpacing";
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

import { PentairSilverGray } from "./colors/_all";

export const spacing = createSpacing(12);

export const typographyVariants = {
  h1: "h1" as "h1",
  h2: "h2" as "h2",
  h3: "h3" as "h3",
  h4: "h4" as "h4",
  h5: "h5" as "h5",
  h6: "h6" as "h6",
  body1: "body1" as "body1",
  body2: "body2" as "body2",
  condensed: {
    h1: "h1_condensed" as "h1",
    h2: "h2_condensed" as "h2",
    h3: "h3_condensed" as "h3",
    h4: "h4_condensed" as "h4",
    h5: "h5_condensed" as "h5",
    h6: "h6_condensed" as "h6"
  }
};

export const typographyMapping = {
  [typographyVariants.condensed.h1]: "h1",
  [typographyVariants.condensed.h2]: "h2",
  [typographyVariants.condensed.h3]: "h3",
  [typographyVariants.condensed.h4]: "h4",
  [typographyVariants.condensed.h5]: "h5",
  [typographyVariants.condensed.h6]: "h6"
};

export const themeConfig = {
  spacing: 12,
  typography: {
    body1: { fontSize: "1rem" },
    body2: { fontSize: "0.875rem" },
    h1: {
      fontFamily: "Barlow",
      fontWeight: 700,
      color: PentairSilverGray["500"],
      fontSize: "2.125rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h2: {
      fontFamily: "Barlow",
      fontWeight: 600,
      textTransform: "uppercase",
      color: PentairSilverGray["500"],
      fontSize: "1.5625rem",
      lineHeight: "1.2"
    },
    h3: {
      fontFamily: "Barlow",
      fontWeight: 600,
      textTransform: "uppercase",
      color: PentairSilverGray["500"],
      fontSize: "1.25rem",
      lineHeight: "1.2"
    },
    h4: {
      fontFamily: "Barlow",
      fontWeight: 600,
      textTransform: "uppercase",
      color: PentairSilverGray["500"],
      fontSize: "1rem",
      lineHeight: "1.2"
    },
    h5: {
      fontWeight: 600,
      fontFamily: "Barlow",
      textTransform: "uppercase",
      color: PentairSilverGray["500"],
      fontSize: "0.875rem",
      lineHeight: "1.2"
    },
    h6: {
      fontFamily: "Barlow",
      fontWeight: 600,
      textTransform: "uppercase",
      color: PentairSilverGray["500"],
      fontSize: "0.75rem",
      lineHeight: "1.2"
    },
    h1_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "2.625rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h2_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "2.125rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h3_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "1.62rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h4_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "1.25rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h5_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "1rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    h6_condensed: {
      fontFamily: "Barlow Condensed",
      fontWeight: 300,
      // color: PentairSilverGray["500"],
      fontSize: "0.875rem",
      lineHeight: "1.2",
      textTransform: "uppercase"
    },
    subtitle1: {},
    subtitle2: {},
    caption: {},
    overline: {},
    button: {
      fontWeight: 600,
      lineHeight: 1,
      textTransform: "uppercase",
      textDecoration: "none"
    }
  }
};

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    h1_condensed: TypographyStyle;
    h2_condensed: TypographyStyle;
    h3_condensed: TypographyStyle;
    h4_condensed: TypographyStyle;
    h5_condensed: TypographyStyle;
    h6_condensed: TypographyStyle;
  }

  interface TypographyOptions {
    h1_condensed: TypographyStyleOptions;
    h2_condensed: TypographyStyleOptions;
    h3_condensed: TypographyStyleOptions;
    h4_condensed: TypographyStyleOptions;
    h5_condensed: TypographyStyleOptions;
    h6_condensed: TypographyStyleOptions;
  }
}

export const typography = (key: any) => {
  return themeConfig.typography[key] as TypographyStyleOptions;
};
