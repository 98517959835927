import { IPresenter } from "../../../helpers/with-presenter";
import { computed, observable } from "mobx";
import { UserInteractor } from "../../../application/data/user/user-interactor";
import { ToolConfig } from "../../../config/interface-tools";
import { User } from "../../../application/data/user/user";
import { Notification } from "../../../application/data/notification/notification";
import { routes } from "../../../config/routes";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";

export enum InterfaceTools {
  Menu = "menu",
  Homepage = "homepage",
  Notifications = "notifications",
  PageInfo = "page-info",
  Support = "support",
  None = "not-set"
}

export interface InterfaceData {
  user: User | undefined;
  notifications: Notification[];
  pages: any[];
}

export interface InterfaceActions {
  userLogout(): void;
  notificationSelect(): void;
  notificationDismiss(): void;
}

export class InterfacePresenter implements IPresenter {
  @observable public config: Record<InterfaceTools, ToolConfig>;
  public data: InterfaceData;
  public actions: InterfaceActions;

  @computed public get user() {
    return this._userInteractor.currentUser;
  }

  public get activeTool() {
    return this._interfaceInteractor.activeTool;
  }

  public set activeTool(tool: InterfaceTools) {
    this._interfaceInteractor.activeTool = tool;
  }

  constructor(private _userInteractor: UserInteractor, private _interfaceInteractor: InterfaceInteractor) {
    this.config = _interfaceInteractor.config;
    this.data = {
      user: this.user,
      notifications: [],
      pages: routes.filter(route => route.mainmenu)
    };
    this.actions = {
      userLogout: this.logout,
      notificationDismiss: () => {},
      notificationSelect: () => {}
    };

    this.activeTool = InterfaceTools.None;
  }

  public mount() {
    // None
  }

  public unmount() {
    // None
  }

  public toggleTool = (tool: InterfaceTools) => {
    this.activeTool = this.activeTool != tool ? tool : InterfaceTools.None;
  };

  public logout = () => {
    if (this.user) {
      this._userInteractor.logout();
      
    }
  };
}
