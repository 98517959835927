import technology from "../../technology/__mock__/aquaflex-hs";

export default {
  id: technology.id,
  version: "0.1",
  status: "Concept",
  company: "Code.Rehab",
  code: "AB-000-XS",
  country: "Netherlands",
  project: "Project name",
  proposalNr: "123-456-7890",
  values: technology.values,
  technology
};
