import * as React from "react";
import { withStyles } from "@material-ui/styles";
import { WithStyles } from "@material-ui/core";
import { IconButton } from "../../content/mui-extended/mui-icon-button";
import { InterfaceTools } from "./default-interface-presenter";
import { mapEvent } from "../../../helpers/formatters";
import { observer } from "mobx-react";
import { PentairBlue } from "../../theme/colors/_all";
import { route } from "../../../config/routes";

interface OwnProps extends WithStyles<"root"> {
  onToolSelect?(tool: InterfaceTools): void;
  active: InterfaceTools;
}

export const ToolBar = withStyles({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: PentairBlue[500],
    "& > nav": {
      display: "flex",
      flexDirection: "column"
    }
  }
})(
  observer(({ classes, onToolSelect, active }: OwnProps) => {
    return (
      <aside className={classes.root}>
        <nav>
          {/* <IconButton
            variant='primary'
            active={active === InterfaceTools.Menu}
            icon='bars'
            onClick={mapEvent(onToolSelect, InterfaceTools.Menu)}
          /> */}
          {route("app.user.dashboard") !== window.location.pathname && (
            <IconButton variant='primary' icon='home' to={route("app.user.dashboard")} />
          )}
          {/* <IconButton
            variant='primary'
            active={active === InterfaceTools.Notifications}
            icon='bell'
            onClick={mapEvent(onToolSelect, InterfaceTools.Notifications)}
          /> */}
        </nav>
        <nav>
          <IconButton
            variant='primary'
            active={active === InterfaceTools.PageInfo}
            icon='question-circle'
            onClick={mapEvent(onToolSelect, InterfaceTools.PageInfo)}
          />
        </nav>
      </aside>
    );
  })
);
