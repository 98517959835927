import * as React from "react";
import { DefaultLayout as Layout } from "../layout/layout-default";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import { ChatBot } from "../content/components/chatbot/chatbot";
import { DesignAssistantPresenter } from "./design-assistant-presenter";
import { withStyles } from "@material-ui/styles";
import { WithStyles, Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

type OwnProps = RouteComponentProps & WithStyles<"closeButton" | "layout">;

const styles = (theme: Theme): StyleRules => ({
  layout: {
    background: "white",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(4)
  }
});

const AssistantComponent = withStyles(styles)(
  observer(({ presenter, classes }: OwnProps & PresenterProps<DesignAssistantPresenter>) => (
    <>
      <Layout classes={{ root: classes.layout }}>
        <div>
          <ChatBot onTimeout={presenter.timeoutHandler}></ChatBot>
        </div>
      </Layout>
    </>
  ))
);

export const PageAssistant = withRouter(
  withPresenter<DesignAssistantPresenter, RouteComponentProps>(
    (props, { business }) => new DesignAssistantPresenter(business.design, business.user, props),
    AssistantComponent
  )
);
