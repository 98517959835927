import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { DesignInteractor } from "../../application/data/design/design-interactor";
import { TechnologyInteractor } from "../../application/data/technology/technology-interactor";
import { computed, observable } from "mobx";
import { DesignPresenter } from "./design-presenter";
import { Design } from "../../application/data/design/design";
import { route } from "../../config/routes";

export class CompareTechnologyPresenter extends DesignPresenter implements IPresenter {
  @observable public compare: boolean = true;
  @observable public selection: string[] = [];

  constructor(
    protected _designInteractor: DesignInteractor,
    protected _technologyInteractor: TechnologyInteractor,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps
  ) {
    super(_designInteractor, _userInteractor, _router);
  }

  @computed public get designs(): Design[] {
    const designs: Design[] = [];
    const selection = this._technologyInteractor.comparisonSelection || [];

    selection.forEach(id => {
      const design = this._designInteractor.find(id);
      if (design) {
        designs.push(design);
      }
    });

    return designs;
  }

  public mount = () => {};

  public unmount = () => {};

  public selectTechnology = (design: Design) => {
    this._technologyInteractor.comparisonSelection = [];
    this.history.push(route("app.design.results", { design: design.id }));
  };
}
