// import { TechnologyFragment } from "./fragments";

export const CreateTechnology = `
mutation CreateTechnology($input:inputTechnology!) {
createTechnology(input:$input){
  id
  company
  status
}
}
`;

export const UpdateTechnology = `
mutation updateTechnology($input:inputTechnology!) {
  updateTechnology(input:$input) {
    id
    company
    status
  }
}
`;

export const DeleteTechnology = `
mutation DeleteTechnology($id:ID!) {
  deleteTechnology(id:$id){
    id
    company
    status
  }
}
`;

export const TechnologyAddUser = `
mutation TechnologyAddUser($id:ID!, $userID:ID!) {
  technologyAddUser(id:$id, userID: $userID){
    id
    users{
      id
      username
      email
    }
  }
}
`;

export const TechnologyRequestApproval = `
mutation TechnologyRequestApproval($id:ID!) {
  technologyRequestApproval(id:$id){
    id
    version
    status
  }
}
`;
