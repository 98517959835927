import { ButtonClassKey } from "@material-ui/core/Button";
import { FabClassKey } from "@material-ui/core/Fab";
import { IconButtonClassKey } from "@material-ui/core/IconButton";
import { LinkClassKey } from "@material-ui/core/Link";
import { CSSProperties } from "@material-ui/styles";

import { PentairBrightBlue, PentairGreen, PentairSilverGray, PentairWhite } from "../colors/_all";
import { typography, spacing } from "../config";
import { SwitchClassKey } from "@material-ui/core/Switch";

export const MuiLink: Partial<Record<LinkClassKey, CSSProperties>> = {
  root: {
    ...typography("button"),
    color: PentairGreen[400],
    "&:hover": {}
  }
};

export const MuiFab: Partial<Record<FabClassKey, CSSProperties>> = {
  root: {
    ...typography("button"),
    color: PentairGreen[400],
    boxShadow: "none"
  },
  primary: {
    backgroundColor: PentairGreen[400],
    "&:hover": { backgroundColor: PentairGreen[400] }
  },
  secondary: {
    backgroundColor: PentairBrightBlue[500],
    "&:hover": { backgroundColor: PentairBrightBlue[500] }
  },
  sizeSmall: {
    width: 36,
    height: 36
  }
};

export const MuiSwitch: Partial<Record<SwitchClassKey, CSSProperties>> = {
  switchBase: {
    height: 38,
    width: 38
  }
};

export const MuiIconButton: Partial<Record<IconButtonClassKey, CSSProperties>> = {
  root: {
    height: 48,
    width: 48,
    backgroundColor: "transparent",
    "&:hover, $active": {
      color: PentairBrightBlue[400],
      backgroundColor: "transparent"
    }
  },
  colorSecondary: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  label: { width: 18, height: 18 }
};

export const MuiButton: Partial<Record<ButtonClassKey, CSSProperties>> = {
  root: {
    padding: spacing(0, 2),
    height: spacing(4),
    borderRadius: 0,
    position: "relative",
    textDecoration: "none !important",
    display: "inline-flex",
    overflow: "hidden",

    "&:before": {
      backgroundColor: PentairSilverGray[500],
      width: "125%",
      height: "100%",
      content: "''",
      display: "block",
      position: "absolute",
      transform: "rotate(-45deg)",
      transformOrigin: "top right",
      transition: "all 0.2s",
      top: "60%",
      right: 0,
      opacity: 0.25
    },

    "&:hover": {
      boxShadow: "none !important"
    },

    "&:hover:before": {
      transform: "rotate(0deg)",
      top: 0
    },

    "& $label": {
      position: "relative"
    }
  },

  sizeSmall: {
    padding: spacing(0, 1),
    height: spacing(3)
  },
  startIcon: {
    marginRight: spacing(1)
  },
  endIcon: {
    marginLeft: spacing(1)
  },
  iconSizeMedium: {
    "& > *:first-child": {
      fontSize: "18px"
    }
  },
  contained: {
    boxShadow: "none"
  },

  text: {
    "&:before": {
      content: "none"
    },
    color: PentairSilverGray[500],
    "&:hover": {
      backgroundColor: "transparent",
      color: PentairBrightBlue[500]
    }
  },

  textPrimary: {
    color: PentairSilverGray[500],
    "&:hover": {
      backgroundColor: "transparent",
      color: PentairBrightBlue[500]
    }
  },

  textSecondary: {
    color: PentairSilverGray[500],
    "&:hover": {
      backgroundColor: "transparent",
      color: PentairBrightBlue[500]
    }
  },

  containedPrimary: {
    backgroundColor: PentairGreen[500],
    "&:hover": {
      backgroundColor: PentairGreen[500]
    }
  },

  containedSecondary: {
    color: PentairWhite[500],
    backgroundColor: PentairBrightBlue[500],
    "&:hover": {
      backgroundColor: PentairBrightBlue[500]
    }
  },

  outlined: {
    padding: "calc(0.8rem - 2px) calc(2rem - 2px)",
    transition: "color 0.4s",
    color: PentairSilverGray[500],
    border: `2px solid ${PentairSilverGray[500]}`,

    "&:before": {
      opacity: 1
    },

    "&:hover": {
      color: PentairWhite[500]
    },

    "&$disabled": {
      color: `${PentairSilverGray[100]}`,
      borderColor: `${PentairSilverGray[100]}`
    },

    "&$disabled:before": {
      backgroundColor: `${PentairSilverGray[100]}`
    }
  },

  outlinedPrimary: {
    color: PentairGreen[500],
    border: `2px solid ${PentairGreen[500]}`,
    "&:hover": {
      color: "#fff",
      border: `2px solid ${PentairGreen[500]}`
    },
    "&:before": {
      backgroundColor: PentairGreen[500]
    }
  },
  outlinedSecondary: {
    color: PentairBrightBlue[500],
    border: `2px solid ${PentairBrightBlue[500]}`,
    "&:hover": {
      color: "#fff",
      border: `2px solid ${PentairBrightBlue[500]}`
    },
    "&:before": {
      backgroundColor: PentairBrightBlue[500]
    }
  },
  sizeLarge: {
    padding: "1.1rem 2rem",
    fontSize: "1.1rem"
  }
};
