import * as React from "react";
import Widget from "../../components/widget/widget";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { typographyVariants } from "../../../theme/config";
import { Design } from "../../../../application/data/design/design";
import { mapEvent, nodeValue } from "../../../../helpers/formatters";
import { observer } from "mobx-react";

interface OwnProps {
  design: Design;
}

export const DesignInputsCapacityWidget = observer(({ design }: OwnProps) => {
  const waterSource = design.value("water_source");
  const capacity = design.value("capacity");
  const duration = design.value("duration");
  const capacityBase = design.value("capacity_base");
  const membrane = design.valueMap["membrane"];

  return (
    <Widget title='Capacity'>
      <Grid container spacing={2}>
        {waterSource && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              placeholder='Surface water'
              id='design-company'
              label='Water source'
              onChange={mapEvent(design.updateValue, waterSource.id!, 0, nodeValue as any)}
              value={(waterSource.values && waterSource.values[0]) || ""}
            />
          </Grid>
        )}
        {duration && duration.id && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              placeholder='Duration'
              id='design-company'
              label='Duration'
              onChange={mapEvent(design.updateValue, duration.id!, 0, nodeValue as any)}
              value={(duration.values && duration.values[0]) || ""}
              InputProps={{ endAdornment: <InputAdornment position='end'>{duration.unit}</InputAdornment> }}
            />
          </Grid>
        )}
        {capacityBase && capacityBase.id && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin='normal'
              placeholder='Permeate production'
              id='design-company'
              label='Capacity based on'
              onChange={mapEvent(design.updateValue, capacityBase.id!, 0, nodeValue as any)}
              value={(capacityBase.values && capacityBase.values[0]) || ""}
            />
          </Grid>
        )}
        {capacity && (
          <>
            <Grid item xs={8}>
              <TextField
                required
                fullWidth
                margin='normal'
                placeholder='1000'
                id='design-company'
                label='Capacity'
                onChange={mapEvent(design.updateValue, capacity.id!, 0, nodeValue as any)}
                value={(capacity.values && capacity.values[0]) || ""}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                margin='normal'
                placeholder='m3/h'
                id='design-company'
                label='Units'
                onChange={mapEvent(design.updateValue, capacity.id!, 0, nodeValue as any)}
                value={capacity.unit || ""}
              />
            </Grid>
          </>
        )}
        {membrane && (
          <>
            <Grid item xs={12}>
              <Typography variant={typographyVariants.h4}>Membrane elements</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin='normal'
                placeholder=''
                id='design-company'
                label='Membrane'
                onChange={mapEvent(design.updateValue, membrane.id!, 0, nodeValue as any)}
                value={(membrane.values && membrane.values[0]) || ""}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Widget>
  );
});
