import * as React from "react";
import { withStyles, WithStyles, InputAdornment, TextField, Typography, Button, Zoom, Theme } from "@material-ui/core";
import { observer } from "mobx-react";
import { PentairBrightBlue } from "../../../theme/colors/_all";
import { typographyVariants } from "../../../theme/config";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapEvent } from "../../../../helpers/formatters";

export interface Message {
  question: string;
  id: string;
  anwser: any;
  egu: string;
  responseCard?: any;
  skipValue?: any;
  inputFormat: any;
}

interface OwnProps extends WithStyles {
  message: Message;
  errorMessage: any;
  input: string;
  active: boolean;
  onChange: any;
  onKeyPress: any;
  onSubmit: any;
  key: number;
}

const styles = withStyles(
  (theme: Theme) => ({
    item: {
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      transition: "all .5s",
      margin: "0 auto",
      transform: "scale(0.75)",
      transformOrigin: "center",
      marginTop: theme.spacing(2),
      maxHeight: 300,
      width: "75%",
      maxWidth: 960,
      opacity: 0.5
    },
    active: {
      color: PentairBrightBlue[500],
      filter: "grayscale(0)",
      transform: "translateY(calc(50% + 75px)) scale(1.25)",
      marginBottom: 0,
      marginTop: 0,
      maxHeight: 0,
      opacity: 1
    },
    input: {
      display: "flex",
      width: "20%",
      minWidth: theme.spacing(20),
      maxWidth: theme.spacing(25),
      margin: "auto",
      marginTop: theme.spacing(1),
      "-webkit-autofill": {
        backgroundColor: "red"
      }
    },

    button: {
      margin: theme.spacing(1, 0.5)
    },

    skip: {
      padding: 5,
      cursor: "pointer",
      fontSize: ".8em",
      top: "30px",
      right: "-177px",
      position: "relative",
      zIndex: 99,
      color: theme.palette.silverGray.main,
      transition: "color .3s",
      "&:hover": {
        color: PentairBrightBlue[500]
      }
    },
    submit: {
      backgroundColor: PentairBrightBlue[300],
      color: "#fff",
      cursor: "pointer",
      padding: theme.spacing(0.5, 1),
      transition: "all .3s",
      position: "relative",
      bottom: "3px",
      left: "13px",
      height: "33px",
      "&:hover": {
        backgroundColor: PentairBrightBlue[500]
      }
    },
    answerText: { fontSize: "1.5em" },
    inputAdornment: { width: "auto", whiteSpace: "nowrap" },
    textWrap: {
      fontSize: "2vw",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.5vw"
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.75rem"
      }
    }
  }),
  {}
);

@observer
class ChatBotItemComponent extends React.Component<OwnProps> {
  public render() {
    const { message, classes, input, active, errorMessage } = this.props;
    const questionText = (message && message.question) || "";

    return (
      <div className={classnames([classes.item, active && classes.active])}>
        <Zoom in timeout={400}>
          <div>
            <div className={classes.textWrap}>
              <Typography
                className={classes.question}
                variant={typographyVariants.condensed.h2}
                color={"inherit"}
                style={{
                  fontSize: `${Math.max(2, Math.min(3, 3 / (questionText.length / 150)))}em`
                }}
              >
                {questionText}
              </Typography>
            </div>

            {active ? (
              message.responseCard && message.responseCard.genericAttachments[0].buttons ? (
                message.responseCard.genericAttachments[0].buttons.map((button: any) => {
                  return (
                    <Button
                      onClick={this.props.onSubmit.bind(this, button.value, message.inputFormat)}
                      size="small"
                      color="primary"
                      className={classes.button}
                    >
                      {button.text}
                    </Button>
                  );
                })
              ) : (
                <>
                  <div className={classes.input}>
                    {message && message.skipValue ? (
                      <Typography
                        onClick={mapEvent(this.props.onSubmit, message.skipValue, message.inputFormat)}
                        variant={typographyVariants.body2}
                        className={classes.skip}
                      >
                        Skip
                      </Typography>
                    ) : (
                      ""
                    )}
                    <TextField
                      error
                      helperText={errorMessage}
                      fullWidth
                      id="filled-adornment-weight"
                      value={input}
                      placeholder={"Answer"}
                      onChange={this.props.onChange.bind(this)}
                      onKeyPress={this.props.onKeyPress.bind(this)}
                      autoFocus={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className={classes.inputAdornment} position="end">
                            {message.egu}
                          </InputAdornment>
                        )
                      }}
                    />
                    <Typography
                      variant={typographyVariants.body2}
                      className={classes.submit}
                      onClick={mapEvent(this.props.onSubmit, input, message.inputFormat)}
                    >
                      <FontAwesomeIcon icon="arrow-right" />
                    </Typography>
                  </div>
                </>
              )
            ) : (
              <Typography className={classes.answerText} variant={typographyVariants.body1}>
                {message.anwser}
              </Typography>
            )}
          </div>
        </Zoom>
      </div>
    );
  }
}

export const ChatbotItem = styles(ChatBotItemComponent);
