import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { TechnologyModel, Technology, TechnologyData } from "./technology";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, {
  GraphQLProviderProps
} from "../../network/graphql-provider";
import { UserModel } from "../user/user";
import { NotificationModel } from "../notification/notification";
import { subscribe } from "../../network/decorators/graphql-subscribe";
import {
  TechnologyCreated,
  TechnologyUpdated,
  TechnologyDeleted
} from "./graphql/subscriptions";
import {
  CreateTechnology,
  UpdateTechnology,
  DeleteTechnology
} from "./graphql/mutations";
import {
  QueryTechnology,
  QueryTechnologyNotifications,
  QueryTechnologyOwner,
  QueryTechnologyUsers,
  QueryTechnologyApprover,
  QueryAllTechnologies
} from "./graphql/queries";
import { App } from "../../App";

export interface TechnologyProvider
  extends GraphQLProviderProps<Technology, TechnologyData> {
  fetchAll(): Promise<TechnologyData[]>;
}

export interface TechnologyValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultTechnologyProvider
  extends GraphQLProvider<Technology, TechnologyData>
  implements TechnologyProvider {
  public model = TechnologyModel;

  protected serializers: RecordSerializers<TechnologyData> = {
    values: JSONSerializer
  };

  @subscribe(TechnologyCreated) technologyCreated = (
    technology: Technology
  ) => {
    return technology;
  };

  @subscribe(TechnologyUpdated) technologyUpdated = (
    technology: Technology
  ) => {
    return technology;
  };

  @subscribe(TechnologyDeleted) technologyDeleted = (
    technology: Technology
  ) => {
    return technology;
  };

  public createOperation(technology: TechnologyData) {
    return graphqlOperation(CreateTechnology, { input: technology });
  }

  public fetchOperation(technology: TechnologyData) {
    return graphqlOperation(QueryTechnology, { id: technology.id });
  }

  public updateOperation(technology: TechnologyData) {
    return graphqlOperation(UpdateTechnology, { input: technology });
  }

  public deleteOperation(technology: TechnologyData) {
    return graphqlOperation(DeleteTechnology, { id: technology.id });
  }

  public fetchAll = async () => {
    const response = await App.network.fetch(QueryAllTechnologies, null, true);

    if (response.data && response.data.TechnologyCollection) {
      response.data.TechnologyCollection.map(
        (technology: Partial<TechnologyData>) => {
          if (technology.id) {
            this.updateRecord(technology.id, technology);
          }
        }
      );
    }

    return response.data.TechnologyCollection;
  };
}

export function technologyNotificationOptions(technology: any) {
  return {
    model: NotificationModel,
    operation: graphqlOperation(QueryTechnologyNotifications, {
      id: technology.id
    })
  };
}

export function technologyOwnerOptions(technology: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryTechnologyOwner, { id: technology.id })
  };
}

export function technologyApproverOptions(technology: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryTechnologyApprover, { id: technology.id })
  };
}

export function technologyUsersOptions(technology: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryTechnologyUsers, { id: technology.id })
  };
}
