import { BaseModule } from "../module";

import { API, graphqlOperation } from "aws-amplify";
import { API as FakeAPI, graphqlOperation as FakeGraphqlOperation } from "../../__mocks__/aws-amplify";

export interface NetworkProviders {}

export interface NetworkModule extends NetworkProviders {}

export class DefaultNetworkModule extends BaseModule<NetworkProviders, {}, {}> implements NetworkModule {
  public init = async () => {};

  public fetch = async (querystring: string, variables?: any, useMock: boolean = false) => {
    let result;

    try {
      if (useMock) {
        result = await FakeAPI.graphql(FakeGraphqlOperation(querystring, variables));
        // console.log(
        //   {
        //     request: {
        //       querystring,
        //       variables
        //     }
        //   },
        //   result
        // );
      } else {
        result = await API.graphql(graphqlOperation(querystring, variables));
      }
    } catch (err) {
      result = err;
      console.error(err);
    }
    //console.log(result);
    return result;
  };
}
