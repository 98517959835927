import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./view/routes";

import Amplify from "aws-amplify";
// import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
import { amplify } from "./config/amplify";
// import AuthTheme from "./view/theme/auth";
import { observer } from "mobx-react";

Amplify.configure(amplify);
// createMockDesigns();

const disableEvent = (e: any) => {
  e.preventDefault();
};

@observer
class App extends Component {
  render() {
    return (
      <div className='App' onContextMenu={disableEvent}>
        <Router>
          <Routes />
        </Router>
      </div>
    );
  }
}

export default App;
