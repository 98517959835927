import * as React from "react";

import { PentairDarkGray } from "../theme/colors/_all";
import { withStyles, WithStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
// import { route } from "../../config/routes";

type OwnProps = WithStyles<"root">;

export default withStyles(
  {
    root: {
      textAlign: "right",
      color: "#C4BFB6",
      fontSize: "14px",
      paddingTop: 24,
      marginBottom: -12,
      "& a": {
        color: "#C4BFB6",
        padding: "6px",
        transition: "color 0.2s",
        "&:hover": {
          color: PentairDarkGray[500]
        }
      }
    }
  },
  {}
)(function DefaultPageFooter({ classes }: OwnProps) {
  return (
    <footer className={classes.root}>
      ©2019 Pentair plc. All rights reserved.
      <br />
      <Link
        // to={route("app.disclaimer")}
        to={window.location.pathname}
      >
        Disclaimer
      </Link>{" "}
      |{" "}
      <Link
        // to={route("app.terms.service")}
        to={window.location.pathname}
      >
        Terms of service
      </Link>{" "}
      |{" "}
      <a
        target="_blank"
        href={"https://www.pentair.com/content/pentair/en/privacy-notice.html"}
      >
        Privacy policy
      </a>
    </footer>
  );
});
