import { GraphQLResolveInfo } from "graphql";
import designAquaflex from "../../../data/design/__mock__/design-aquaflex";
import designAquaflexHS from "../../../data/design/__mock__/design-aquaflex-hs";
import designCrossFlow from "../../../data/design/__mock__/design-crossflow";
import designCrossFlowUF from "../../../data/design/__mock__/design-crossflow-uf";
import designAirliftMGB from "../../../data/design/__mock__/design-airlift-mgb";
import designAirliftMBR from "../../../data/design/__mock__/design-airlift-mbr";
import designXIGA from "../../../data/design/__mock__/design-xiga";
import designXLINE from "../../../data/design/__mock__/design-xline";
import designAMBRN from "../../../data/design/__mock__/design-anmbr";

export const mockDesigns = () => {
  return [
    designAquaflex,
    designAquaflexHS,
    designCrossFlow,
    designCrossFlowUF,
    designAirliftMGB,
    designAirliftMBR,
    designXIGA,
    designXLINE,
    designAMBRN
  ];
};

export const designResolver = () => {
  return mockDesigns();
};

export const QueryDesignResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  const result = mockDesigns().filter((design: any) => {
    return design.id === args.id;
  });

  return result[0] || null;
};
