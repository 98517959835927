import { GraphQLOptions } from "@aws-amplify/api/lib/types";
import { observable } from "mobx";

import uuid from "uuid/v4";
import { StorageRecord } from "../storage/store";
import { GraphQLProviderProps } from "./graphql-provider";

import { App } from "../App";

export interface Model<TData> extends StorageRecord {
  data: TData;
  updated: Partial<TData>;
  loading: boolean;
}

export interface GraphQLModel<TData extends StorageRecord>
  extends Model<TData> {
  id: string;
  typename: string;
  fetch(): void;
  update(): void;
  delete(): void;
  serialize(data?: TData): TData;
  provider: GraphQLProviderProps<GraphQLModel<TData>, TData>;
  updateProperty(key: keyof TData, value: any): void;
}

export class GraphQLBase<TData extends StorageRecord>
  implements GraphQLModel<TData> {
  @observable public updated: Partial<TData> = {};
  public data: TData = {} as TData;
  public loading: boolean = false;
  public useMock: boolean = false;

  public get provider(): GraphQLProviderProps<GraphQLModel<TData>, TData> {
    throw new Error("Provider is required");
  }

  public static get(id: string) {
    return new (this as any)(id);
  }

  public typename: string = "";

  public find(id: string) {
    this.provider;
  }

  constructor(public id: string = uuid()) {}

  public async query(
    operation: GraphQLOptions,
    queryName: string,
    propName?: string
  ) {
    const response = await App.network.fetch(
      operation.query as string,
      operation.variables,
      this.useMock
    );

    const result = response.data && response.data[queryName];
    this.provider.updateRecord(this.id, { ...result });

    if (response.errors) {
      console.error(response.errors);
    }

    return response.data && propName
      ? response.data[queryName][propName]
      : response.data && response.data[queryName];
  }

  public async fetch() {
    this.loading = true;
    const operation = this.provider.fetchOperation(
      this.provider.serialize(this)
    );
    if (operation) {
      await this.query(operation, this.typename);
    }
    this.loading = false;
  }

  public async update() {
    this.loading = true;
    this.data = this.provider.updateRecord(
      this.id,
      this.provider.serialize(this)
    );
    const operation = this.provider.updateOperation(
      this.provider.serialize(this)
    );
    if (operation) {
      await this.query(operation, this.typename);
    }
    this.loading = false;
  }

  public delete = async () => {
    this.loading = true;
    const operation = this.provider.deleteOperation(
      this.provider.serialize(this)
    );
    if (operation) {
      await this.query(operation, this.typename);
      this.provider.deleteRecord(this.id);
    }
    this.loading = false;
  };

  public serialize(data?: TData): TData {
    return data || this.provider.serialize(this);
  }

  public updateProperty = (key: keyof TData, value: any): void => {
    (this as any)[key] = value;
  };
}
