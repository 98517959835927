import uuid from "uuid/v4";

import { NotificationModel, Notification, NotificationData } from "./notification";
import { graphqlOperation } from "aws-amplify";
import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";
import { QueryNotification } from "./queries";

export interface NotificationProvider extends GraphQLProviderProps<Notification, NotificationData> {}

export interface NotificationValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultNotificationProvider extends GraphQLProvider<Notification, NotificationData>
  implements NotificationProvider {
  public model = NotificationModel;

  protected defaultValues: () => NotificationData = (data: Partial<NotificationData> = {}) => {
    return {
      id: uuid(),
      ...data
    };
  };

  public createOperation(notification: Notification) {
    return undefined;
  }

  public fetchOperation(notification: Notification) {
    return graphqlOperation(QueryNotification, { id: notification.id });
  }

  public updateOperation(notification: Notification) {
    return undefined;
  }

  public deleteOperation(notification: Notification) {
    return undefined;
  }
}
