import * as React from "react";
import { makeStyles, Theme, Card } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));
interface OwnProps {
  title?: string;
  children: any;
}

export default function PageHeader(props: OwnProps) {
  const classes = useStyles();
  const { children } = props;
  return <Card className={classes.root}>{children}</Card>;
}
