import { UserModel, User, UserData } from "./user";
import { Auth, graphqlOperation, API } from "aws-amplify";
import {
  QueryUser,
  QueryUserDesigns,
  QueryUserNotifications,
  QueryUserCollection
} from "./graphql/queries";
import GraphQLProvider, {
  GraphQLProviderProps
} from "../../network/graphql-provider";
import { DesignModel } from "../design/design";
import { NotificationModel } from "../notification/notification";
import { CreateUser, UpdateUser, DeleteUser } from "./graphql/mutations";
import { removeNullValues } from "../../helpers";

export interface UserProvider extends GraphQLProviderProps<User, UserData> {}

export interface UserValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultUserProvider extends GraphQLProvider<User, UserData>
  implements UserProvider {
  public model = UserModel;

  public async fetchAuthUser() {
    const auth = await Auth.currentAuthenticatedUser();
    const id = auth.attributes.sub;
    const user: User = UserModel.get(id);

    user.attributes = { ...auth.attributes };
    return user;
  }

  public async fetchAllUsers() {
    const response = await API.graphql(
      graphqlOperation(QueryUserCollection, {})
    );
    response.data.UserCollection.forEach((d: UserData) =>
      this.updateRecord(d.id, d)
    );
  }

  public createOperation(user: User) {
    return graphqlOperation(CreateUser, { input: user });
  }

  public fetchOperation(user: User) {
    return graphqlOperation(QueryUser, { id: user.id });
  }

  public updateOperation(user: User) {
    return graphqlOperation(UpdateUser, {
      input: {
        id: user.id,
        username: user.username,
        attributes: removeNullValues(user.attributes)
      }
    });
  }

  public deleteOperation(user: User) {
    return graphqlOperation(DeleteUser, { id: user.id });
  }
}

export function notificationOptions(user: any) {
  return {
    model: NotificationModel,
    operation: graphqlOperation(QueryUserNotifications, { id: user.id })
  };
}

export function designOptions(user: any) {
  console.log("Trying to fetch design...", user);
  return {
    model: DesignModel,
    operation: graphqlOperation(QueryUserDesigns, { id: user.id })
  };
}
