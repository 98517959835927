export const TechnologyFragment = `
  id
  name
  oneliner
  active
  description
  image
  techImage
  comparableTechnologies
  techImageMap {
    title
    titleVariant
    location
    rotation
    width
    values
    align
    line
    info
  }
`;
