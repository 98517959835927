import * as React from "react";

import { Typography, Button } from "@material-ui/core";

import ContentSection from "../content/components/content-section";
import { typographyVariants } from "../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PagePresenter } from "./_base-page-presenter";
import { RouteComponentProps } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";

const Component = ({ presenter }: RouteComponentProps & PresenterProps<PagePresenter>) => (
  <ContentSection
    align='center'
    justify='center'
    style={{
      height: "100vh"
    }}
  >
    <img src='/images/logo.svg' height='36' />
    <br />
    <Typography variant={typographyVariants.h2} align='center' gutterBottom>
      We can't find the page you're looking for
    </Typography>
    <Typography variant={typographyVariants.body2} align='center'>
      It may have moved or the link could be incorrect.
    </Typography>
    <Button
      onClick={presenter.back}
      variant='text'
      startIcon={<FontAwesomeIcon icon={["fal", "arrow-left"]} size='sm' />}
    >
      Previous page
    </Button>
  </ContentSection>
);

export const Error404 = withPresenter<PagePresenter, RouteComponentProps>(props => new PagePresenter(props), Component);
