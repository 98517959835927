import { GraphQLResolveInfo } from "graphql";
import { user1 } from "../../../data/user/__mock__/user1";
import designAquaFlex from "../../../data/design/__mock__/design-aquaflex";

export const mockUser = () => {
  return user1;
};

export const mockDesigns = () => {
  return [designAquaFlex];
};

export const userResolver = () => {
  return mockUser();
};

export const QueryUserResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  return mockUser();
};

export const QueryUserCollectionResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  return mockUser();
};

export const QueryUserDesignResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  console.log("i query the designs");
  return mockDesigns();
};
