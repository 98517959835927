import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";
import BigButton from "../content/components/big-button";
import { Grid, Typography, WithStyles, withStyles, Theme, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyleRules } from "@material-ui/core/styles";
import { typographyVariants } from "../theme/config";
import { AuthPagePresenter } from "./_base-auth-page-presenter";

type OwnProps = RouteComponentProps & WithStyles<"root" | "inner" | "layout">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1
  },
  inner: {
    maxWidth: "600px",
    margin: "auto"
  },
  layout: {
    backgroundColor: "#fff",
    backgroundSize: "100% auto",
    backgroundImage: "url('/images/water-bg.png')",
    backgroundPosition: "left calc(100vh - 37.5vw)",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  }
});

const Component = observer(({ classes, presenter }: OwnProps & PresenterProps<AuthPagePresenter>) => (
  <Layout noHeader classes={{ root: classes.layout }}>
    <div className={classes.root}>
      <div className={classes.inner}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography gutterBottom align={"center"} variant={typographyVariants.condensed.h2}>
              CREATE DESIGN
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} alignItems='stretch' style={{ display: "flex" }}>
            <BigButton
              icon={"hdd"}
              title={"start assistant"}
              subtitle={"Design assistant"}
              linkText={"Start assistant"}
              to={"/demo/design/assistant"}
              color={"primary"}
              description={"Create a design with the XPERT Personal Assistant"}
            />
          </Grid>

          <Grid item xs={12} sm={6} alignItems='stretch' style={{ display: "flex" }}>
            <BigButton
              icon={"hdd"}
              title={"create new design"}
              subtitle={"From stratch"}
              linkText={"Create new design"}
              to={"/demo/design/create/select-technology"}
              color={"secondary"}
              description={"Create a design from scratch like a boss without the help of our amazing assistant."}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography align='center'>
              <Button
                variant='text'
                onClick={presenter.back}
                startIcon={<FontAwesomeIcon icon={["fal", "arrow-circle-left"]} />}
              >
                Previous
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  </Layout>
));

export const PageCreate = withRouter(
  withStyles(styles, {})(
    withPresenter<AuthPagePresenter, OwnProps>(
      (props, { business }) => new AuthPagePresenter(business.user, props),
      Component
    )
  )
);
