import { ExpansionPanelClassKey } from "@material-ui/core/ExpansionPanel";
import { ExpansionPanelDetailsClassKey } from "@material-ui/core/ExpansionPanelDetails";
import { ExpansionPanelSummaryClassKey } from "@material-ui/core/ExpansionPanelSummary";
import { CSSProperties } from "@material-ui/styles";

import { PentairBrightBlue, PentairDarkGray } from "../colors/_all";
import { spacing, typography } from "../config";

export const MuiExpansionPanel: Partial<Record<ExpansionPanelClassKey, CSSProperties>> = {
  root: {
    position: "relative",
    counterIncrement: "expansionpanels",

    "&$expanded": {
      margin: 0,
      "&::before": {
        background: "transparent",
        content: "counters(expansionpanels, '', decimal-leading-zero) !important"
      },

      "&.numbered:after": {
        color: PentairBrightBlue[500]
      }
    },

    "&:before": {
      content: ""
    },

    "&.numbered": {
      marginLeft: spacing(5)
    },

    "&.numbered > *": {
      paddingLeft: spacing(5),
      marginLeft: spacing(-5)
    },

    "&.numbered:after": {
      ...typography("h5"),
      fontSize: typography("body2").fontSize,
      content: "counters(expansionpanels, '', decimal-leading-zero) !important",

      opacity: 1,
      position: "absolute",
      right: "100%",
      top: spacing(4),
      width: spacing(5),
      color: PentairDarkGray[50]
    }
  }
};

export const MuiExpansionPanelSummary: Partial<Record<ExpansionPanelSummaryClassKey, CSSProperties>> = {
  root: {
    borderBottom: "1px solid " + PentairDarkGray[50],
    padding: 0
  },
  content: {
    margin: spacing(3, 0),
    display: "flex",
    alignItems: "baseline",
    fontSize: "1.2em",
    justifyContent: "space-between",

    "& > .grayed-out": {
      color: PentairDarkGray[50]
    },

    "&$expanded": {
      "& > p": {
        color: PentairBrightBlue[500],
        fontWeight: 500
      },
      margin: spacing(3, 0)
    }
  },
  expandIcon: {
    color: PentairDarkGray[50],
    "&$expanded": {
      color: PentairBrightBlue[500]
    }
  }
};

export const MuiExpansionPanelDetails: Partial<Record<ExpansionPanelDetailsClassKey, CSSProperties>> = {
  root: {
    padding: spacing(3, 0),
    display: "flex",
    justifyContent: "space-between"
  }
};
