import { DesignFragment } from "./fragments";

export const DesignCreated = `
subscription {
  designCreated {
    ${DesignFragment}
  }
}
`;

export const DesignUpdated = `
subscription {
  designUpdated {
    ${DesignFragment}
  }
}
`;

export const DesignDeleted = `
subscription {
  designDeleted {
    ${DesignFragment}
  }
}
`;
