import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { RefObject } from "react";

import { Tooltip as MuiTooltip, withStyles, WithStyles } from "@material-ui/core";
import { TooltipProps } from "@material-ui/core/Tooltip";

import { PentairBrightBlue } from "../../theme/colors/_all";

interface OwnProps extends WithStyles<"popper" | "arrow"> {
  title: React.ReactNode;
  children: React.ReactElement;
  muiprops?: Partial<TooltipProps>;
}

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const styles: any = {
  popper: arrowGenerator(PentairBrightBlue[500]),
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  }
};

@observer
class Component extends React.Component<OwnProps> {
  @observable private _arrowRef: RefObject<HTMLSpanElement>;

  constructor(props: OwnProps) {
    super(props);
    this._arrowRef = React.createRef();
  }

  public render() {
    const { title, classes, muiprops, children } = this.props;

    return (
      <MuiTooltip
        {...(muiprops || {})}
        classes={{
          popper: classes.popper
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: true,
                element: this._arrowRef.current
              }
            }
          }
        }}
        title={
          <React.Fragment>
            {title}
            <span className={classes.arrow} ref={this._arrowRef} />
          </React.Fragment>
        }
      >
        {children}
      </MuiTooltip>
    );
  }
}

export const Tooltip = withStyles(styles)(Component);
