import { IPresenter } from "../../helpers/with-presenter";
import { UserInteractor } from "../../application/data/user/user-interactor";
import { RouteComponentProps } from "react-router";
import { AuthPagePresenter } from "./_base-auth-page-presenter";
import { DesignInteractor } from "../../application/data/design/design-interactor";
import { TechnologyInteractor } from "../../application/data/technology/technology-interactor";
import { computed, observable } from "mobx";
import { route } from "../../config/routes";

export class SelectTechnologyPresenter extends AuthPagePresenter implements IPresenter {
  @observable public compare: boolean = true;
  @observable public selection: string[] = [];

  constructor(
    protected _designInteractor: DesignInteractor,
    protected _technologyInteractor: TechnologyInteractor,
    protected _userInteractor: UserInteractor,
    protected _router: RouteComponentProps
  ) {
    super(_userInteractor, _router);
  }

  @computed public get technologies() {
    return this._technologyInteractor.all;
  }

  public mount = () => {
    //
  };

  public unmount = () => {
    //
  };

  public selectTechnology = (technologyID: string) => {
    if (this.compare) {
      if (this.selection.includes(technologyID)) {
        this.selection.splice(this.selection.indexOf(technologyID), 1);
      } else {
        this.selection.push(technologyID);
      }
      return;
    }

    this._designInteractor.select(technologyID);
    this.history.push("/demo/design/" + technologyID + "/inputs");
  };

  public toggleComparison = () => {
    this.compare = !this.compare;
  };

  public startComparison = () => {
    this.history.push(route("app.compare.technologies"));
  };
}
