import * as React from "react";

import { Typography, Button, Grid } from "@material-ui/core";

import ContentSection from "../content/components/content-section";
import { typographyVariants } from "../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PagePresenter } from "./_base-page-presenter";
import { RouteComponentProps } from "react-router";
import { PresenterProps, withPresenter } from "../../helpers/with-presenter";
import { DefaultLayout as Layout } from "../layout/layout-default";

const Component = ({ presenter }: RouteComponentProps & PresenterProps<PagePresenter>) => (
  <Layout backgroundColor='#fff'>
    <ContentSection
      align='center'
      justify='center'
      style={{
        flex: 1
      }}
    >
      <Grid container spacing={2} style={{ maxWidth: 600, display: "flex" }}>
        <Grid item xs={12}>
          <Typography variant={typographyVariants.h1} gutterBottom align={"center"}>
            ANMBR
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img src='/images/anmbr.gif' width='100%' />
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph variant={typographyVariants.body1}>
            Industries worldwide are facing increasing challenges in water and wastewater treatment to reduce their
            impact on the environment. Anaerobic membrane bioreactors (anMBRs) are the most recent technology being used
            to address these issues. <br />
          </Typography>
          <Typography paragraph variant={typographyVariants.body1}>
            AnMBRs offer a simple process capable of treating difficult wastewater conditions by producing high quality
            effluent and providing a source of energy. For more details, please contact your Pentair Account Manager.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={presenter.back}
            variant='text'
            startIcon={<FontAwesomeIcon icon={["fal", "arrow-left"]} size='sm' />}
          >
            Previous page
          </Button>
        </Grid>
      </Grid>
    </ContentSection>
  </Layout>
);

export const PageTechANMBR = withPresenter<PagePresenter, RouteComponentProps>(
  props => new PagePresenter(props),
  Component
);
