import { IPresenter } from "../../helpers/with-presenter";
import { DesignPresenter } from "./design-presenter";
import { route } from "../../config/routes";

export class DesignAssistantPresenter extends DesignPresenter implements IPresenter {
  public mount = () => {};
  public unmount = () => {};

  public timeoutHandler = () => {
    this._router.history.push(route("app.user.dashboard"));
  };
}
