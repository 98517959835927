export const QueryNotification = `
  query Notification($id:ID!) {
    Notification(id:$id) {
      username
      email
    }
  }
`;

export const QueryNotificationDesigns = `
query Notification($id:ID!) {
  Notification(id:$id) {
    designs{
      id
      version
      company
      status
      project
    }
  }
}
`;
