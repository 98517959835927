import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { Typography, Grid, Button, Theme, Checkbox } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/styles";

import { PresenterProps, withPresenter } from "../../../helpers/with-presenter";
import { mapEvent } from "../../../helpers/formatters";
import { typographyVariants } from "../../theme/config";
import { TechnologySelectorPresenter } from "./technology-selector-presenter";
import classnames from "classnames";
interface componentInterface {
  include?: string[] | undefined;
}

type OwnProps = componentInterface &
  RouteComponentProps &
  WithStyles<
    | "root"
    | "done"
    | "inner"
    | "header"
    | "layout"
    | "media"
    | "backButton"
    | "technology"
    | "compareToggleButton"
    | "checkbox"
    | "imageContainer"
    | "container"
    | "disabled"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    height: "auto",
    transition: "all .45s"
  },
  // when a final technology is selected
  done: {
    // marginBottom: "45vh",
    border: "0 !important",
    top: theme.spacing(4),
    height: "calc(100vh - " + theme.spacing(4) + "px)",
    paddingTop: "0 !important",
    bottom: 0
    // backgroundColor: "red"
  },
  compareToggleButton: {},
  technology: {
    width: theme.spacing(18),
    display: "inline-block",
    transition: "opacity 0.3s",
    opacity: 1,

    "&:hover": {
      cursor: "pointer"
    }
  },
  imageContainer: {
    position: "relative",
    padding: theme.spacing(1)
  },
  checkbox: {
    position: "absolute",
    right: 0,
    bottom: 0
  },
  container: {},
  disabled: {
    opacity: 0.3
  }
});

@observer
class Component extends React.Component<OwnProps & PresenterProps<TechnologySelectorPresenter>> {
  public componentWillReceiveProps() {
    this.props.presenter.include = this.props.include || [];
  }

  public render() {
    const { classes, presenter } = this.props;
    const technologies = presenter.technologies;
    const compare = presenter.compare && technologies.length > 1;

    return (
      <div className={classes.root}>
        <Grid className={classes.container} container spacing={2}>
          {technologies.length > 1 && (
            <Grid item xs={12}>
              <Button
                variant={compare ? "contained" : "outlined"}
                color={"secondary"}
                size={"small"}
                disabled={!presenter.isAdmin}
                className={classes.compareToggleButton}
                onClick={presenter.toggleComparison}
              >
                {compare ? <FontAwesomeIcon icon="times" /> : "compare technologies"}
              </Button>

              {compare && (
                <Button
                  size={"small"}
                  onClick={presenter.isAdmin ? presenter.startComparison : e => e.preventDefault()}
                  disabled={presenter.selection.length < 2}
                  style={{ marginLeft: 12 }}
                  endIcon={<FontAwesomeIcon icon={["fal", "arrow-circle-right"]} />}
                >
                  Start comparison
                </Button>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {technologies.map(technology => {
              const comparable = presenter.isComparable(technology);

              return (
                <div
                  className={classnames(classes.technology, !comparable && classes.disabled)}
                  onClick={
                    presenter.isAdmin && comparable ? mapEvent(presenter.selectTechnology, technology.id) : undefined
                  }
                >
                  <div className={classes.imageContainer}>
                    <img src={technology.image} width="100%" />
                    {compare && (
                      <Checkbox className={classes.checkbox} checked={presenter.selection.includes(technology.id)} />
                    )}
                  </div>
                  <Typography align={"center"} variant={typographyVariants.h5}>
                    {technology.name}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const TechnologySelector = withRouter(
  withStyles(
    styles,
    {}
  )(
    withPresenter<TechnologySelectorPresenter, OwnProps>(
      (props, { business }) =>
        new TechnologySelectorPresenter(business.design, business.technology, business.user, props),
      Component
    )
  )
);
