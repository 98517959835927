import * as React from "react";
import { CalculationValue } from "../../../application/data/technology/technology";
import { typographyVariants } from "../../theme/config";
import { Typography, Theme } from "@material-ui/core";
import { ValuesTable } from "./table-values";
import { observer } from "mobx-react";
import { withStyles, WithStyles } from "@material-ui/styles";
import { StyleRules } from "@material-ui/core/styles";

interface DesignValuesSectionProps extends WithStyles<"root"> {
  title: string;
  values: Array<CalculationValue | undefined>;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: theme.spacing(1, 0, -2, 0)
  }
});

export const DesignValuesSection = withStyles(styles, {})(
  observer(({ classes, title, values }: DesignValuesSectionProps) => {
    return (
      <div className={classes.root}>
        <Typography gutterBottom variant={typographyVariants.body1} style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <ValuesTable hideRecommendedValue values={values} />
      </div>
    );
  })
);
