import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { DesignModel, Design, DesignData } from "./design";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";
import { UserModel } from "../user/user";
import { NotificationModel } from "../notification/notification";
import { subscribe } from "../../network/decorators/graphql-subscribe";
import { DesignCreated, DesignUpdated, DesignDeleted } from "./graphql/subscriptions";
import { CreateDesign, UpdateDesign, DeleteDesign } from "./graphql/mutations";
import {
  QueryDesign,
  QueryDesignNotifications,
  QueryDesignOwner,
  QueryDesignUsers,
  QueryDesignApprover
} from "./graphql/queries";

export interface DesignProvider extends GraphQLProviderProps<Design, DesignData> {}

export interface DesignValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultDesignProvider extends GraphQLProvider<Design, DesignData> implements DesignProvider {
  public model = DesignModel;

  protected serializers: RecordSerializers<DesignData> = {
    values: JSONSerializer
  };

  @subscribe(DesignCreated) designCreated = (design: Design) => {
    return design;
  };

  @subscribe(DesignUpdated) designUpdated = (design: Design) => {
    return design;
  };

  @subscribe(DesignDeleted) designDeleted = (design: Design) => {
    return design;
  };

  public createOperation(design: DesignData) {
    return graphqlOperation(CreateDesign, { input: design });
  }

  public fetchOperation(design: DesignData) {
    return graphqlOperation(QueryDesign, { id: design.id });
  }

  public updateOperation(design: DesignData) {
    return graphqlOperation(UpdateDesign, { input: design });
  }

  public deleteOperation(design: DesignData) {
    return graphqlOperation(DeleteDesign, { id: design.id });
  }
}

export function designNotificationOptions(design: any) {
  return {
    model: NotificationModel,
    operation: graphqlOperation(QueryDesignNotifications, { id: design.id })
  };
}

export function designOwnerOptions(design: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryDesignOwner, { id: design.id })
  };
}

export function designApproverOptions(design: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryDesignApprover, { id: design.id })
  };
}

export function designUsersOptions(design: any) {
  return {
    model: UserModel,
    operation: graphqlOperation(QueryDesignUsers, { id: design.id })
  };
}
