import { observable, computed } from "mobx";

import { BaseModule } from "../../module";
import { TechnologyModel, Technology, TechnologyData } from "./technology";
import DefaultTechnologyProvider, { TechnologyProvider } from "./technology-provider";
import { App } from "../../App";

export interface TechnologyInteractor {
  created: Technology | undefined;
  selected: Technology | undefined;
  comparisonSelection: string[] | null;
  select(id: string): Technology | undefined;
  create: (data?: Partial<TechnologyData>, persist?: boolean) => Technology;
  store(Technology: Technology): Promise<void>;
  find(id: string): Technology | undefined;
  provider: TechnologyProvider;
  all: Technology[];
}

export default class DefaultTechnologyInteractor extends BaseModule<any, any, any> implements TechnologyInteractor {
  @observable public created: Technology | undefined = undefined;
  @observable public selected: Technology | undefined = undefined;
  @observable public comparisonSelection: string[] | null = ["aquaflex", "xiga", "xline"];

  constructor() {
    super();
    this.provider.fetchAll();
  }

  public get user() {
    return App.business.user.currentUser!;
  }

  public find = this.provider.find;
  public store = this.provider.store;

  public create = (data: Partial<TechnologyData> = {}) => {
    const technology: Technology = new TechnologyModel();
    this.created = technology;
    return technology;
  };

  public select(id: string): Technology | undefined {
    const technology = (this.selected = this.provider.find(id));
    if (technology) {
      technology.fetch();
    }
    return technology;
  }

  public get provider(): TechnologyProvider {
    return this.loadProvider("provider", DefaultTechnologyProvider);
  }

  @computed public get all(): Technology[] {
    return this.provider.allRecords.map(technology => {
      return this.provider.find(technology.id);
    });
  }
}
