import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";

import { App } from "../../App";
import { data } from "../../network/decorators/graphql-data";

export interface TechnologyData {
  id: string;
  image: string;
  oneliner: string;
  techImage: Array<string>;
  techImageMap: Array<any>;
  name: string;
  active: boolean;
  description: string;
  values: CalculationValue[];
  comparableTechnologies: string[];
}

export interface Technology
  extends GraphQLModel<TechnologyData>,
    TechnologyData {}

export interface CalculationValue {
  id: string;
  name: string;
  values: Array<number | string | boolean>;
  recommendedValues: Array<number | string | boolean>;
  unit: string;
  unitOptions?: string[];
  valueOptions?: Array<number | string | boolean>;
  disabled?: boolean;
  error?: string;
  category?: string;
  decimals?: number;
}

export class TechnologyModel extends GraphQLBase<TechnologyData>
  implements GraphQLModel<TechnologyData> {
  public typename: string = "Technology";
  public useMock: boolean = true;

  @data public name = "";
  @data public active = false;
  @data public image = "";
  @data public oneliner = "";
  @data public techImage = [];
  @data public description = "";
  @data public values: CalculationValue[] = [];
  @data public techImageMap = [];
  @data public comparableTechnologies = [];

  public get provider() {
    return App.business.technology.provider;
  }
}
