export const UserFragment = `
id
username
created_at
updated_at
login_history
status
attributes {
  email
  username
  phone_number
  address
  birthdate
  name
  middle_name
  family_name
  gender
  given_name
  locale
  groups
  nickname
  picture
  preferred_username
  profile
  timezone
  updated_at
  website
}
`;
