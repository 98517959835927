import designAquaFlex from "../../design/__mock__/design-aquaflex";
import designAirliftMGB from "../../design/__mock__/design-airlift-mgb";

export const user1: any = {
  id: "333f06cc-17a9-42ed-9c39-da359f75ae99",
  initial: "K",
  firstname: "Karthi",
  lastname: "Pitchaikani",
  username: "Karthi",
  email: "karthi.pitchaikani@pentair.com",
  designs: [designAquaFlex, designAirliftMGB],
  notifications: [],
  data: {}
};
